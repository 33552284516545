import {dataTestId} from '@hconnect/uikit'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, BoxProps, Tooltip, Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import React from 'react'

interface LegendProps extends Pick<BoxProps, 'sx'> {
  testId?: string
  children: React.ReactNode
}

/**
 * Simple wrapper around the MUI Box component that lays out the legend items
 */
export const Legend = React.memo(({children, sx: customSx, testId = 'legend'}: LegendProps) => {
  return (
    <Box
      {...dataTestId(testId)}
      sx={mergeSx(
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2
        },
        customSx
      )}
    >
      {children}
    </Box>
  )
})
Legend.displayName = 'Legend'

interface LegendItemType {
  label: string
  labelVariant?: TypographyProps['variant']
  isVisible?: boolean
  indicator?: {
    style?: React.CSSProperties
    height?: React.CSSProperties['width']
    variant?: 'square' | 'circle'
  }
  id?: string
  testId?: string
  children?: React.ReactNode
}

/**
 * Legend item, intended to be used inside a Legend wrapper. Has a completely configurable indicator.
 */
export const LegendItem = React.memo(
  ({
    label,
    labelVariant = 'caption',
    isVisible = true,
    indicator,
    testId = 'legend_item',
    id,
    children
  }: LegendItemType) => {
    if (!isVisible) return null
    const variant = indicator?.variant || 'square'
    const indicatorHeight = indicator?.height ?? 10
    const isCircle = variant === 'circle'
    return (
      <Tooltip
        key={label}
        placement="top"
        arrow
        title={<Typography variant="body1">{label}</Typography>}
      >
        <div {...dataTestId(testId)}>
          <Box
            id={id}
            sx={{
              display: 'grid',
              placeContent: 'center',
              alignItems: 'center',
              gridAutoFlow: 'column',
              gap: 1
            }}
          >
            {children || (
              <Box
                sx={[
                  {
                    justifySelf: 'flex-end',
                    width: indicatorHeight,
                    height: indicatorHeight
                  },
                  isCircle && {borderRadius: 10},
                  !!indicator?.style && {...indicator.style}
                ]}
              />
            )}
            <Typography variant={labelVariant}>{label}</Typography>
          </Box>
        </div>
      </Tooltip>
    )
  }
)

LegendItem.displayName = 'LegendItem'

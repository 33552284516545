import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {Dates} from '@hconnect/uikit'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {DateTimeStepper} from '@hconnect/uikit/src/lib2/components/inputs'
import {Calendar} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {Box, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

interface DatetimeRangePickerProps {
  timezoneId: string
  range: Dates
  onChange: (dates: Dates) => void
  validator?: (date: Moment) => boolean
  'data-test-id'?: string
}

export const DatetimeRangePicker: React.FC<DatetimeRangePickerProps> = ({
  timezoneId,
  validator,
  range,
  onChange,
  ['data-test-id']: testId
}) => {
  const {t} = useTranslation()
  const {startDate, endDate} = range
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(startDate ?? null)
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(endDate)

  const handleDateRangeChange = (dates: Dates) => {
    const {startDate, endDate} = dates

    setSelectedStartDate(startDate)
    setSelectedEndDate(endDate)

    if (endDate && startDate) {
      onChange(dates)
    }
  }

  const validateStartDate = (date: Moment) => {
    if (validator) {
      return validator(date) && date.isBefore(selectedEndDate)
    }
    return date.isBefore(selectedEndDate)
  }

  const validateEndDate = (date: Moment) => {
    if (validator) {
      return validator(date) && date.isAfter(selectedStartDate)
    }
    return date.isAfter(selectedStartDate)
  }

  return (
    <Stack spacing={2} alignItems="center" {...dataTestId(testId ?? 'datetime_range_select')}>
      {/* width in px to prevent Calendar from breaking */}
      <Box sx={{width: 308}}>
        <Calendar
          timezone={timezoneId}
          isOutsideRange={validator ? (date) => !validator?.(date) : undefined}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onRangeChange={handleDateRangeChange}
        />
      </Box>
      <DateTimeStepper
        dateLabel={t('datePicker.startDate')}
        timeLabel={t('datePicker.startTime')}
        value={selectedStartDate}
        onChange={(startDate) => handleDateRangeChange({startDate, endDate: selectedEndDate})}
        timezoneId={timezoneId}
        validator={validateStartDate}
        timeRoundingFn={roundTo15MinIntervalStart}
        {...dataTestId('start_datetime_stepper')}
      />
      <DateTimeStepper
        dateLabel={t('datePicker.endDate')}
        timeLabel={t('datePicker.endTime')}
        value={selectedEndDate}
        onChange={(endDate) => handleDateRangeChange({startDate: selectedStartDate, endDate})}
        timezoneId={timezoneId}
        validator={validateEndDate}
        timeRoundingFn={roundTo15MinIntervalStart}
        {...dataTestId('end_datetime_stepper')}
      />
    </Stack>
  )
}

import {IconButton, Popover, PopoverOrigin, SxProps, Theme} from '@mui/material'
import React, {useRef, useState} from 'react'

import {mergeSx} from '../../utils'

type Props = {
  anchorOrigin?: PopoverOrigin
  children: React.ReactNode
  'data-test-id'?: string
  closeOnClick?: boolean
  icon: React.ReactNode
  iconSize?: 'small' | 'medium'
  transformOrigin?: PopoverOrigin
  isDisabled?: boolean
  popoverPaperSx?: SxProps<Theme>
}

export const IconWithPopover: React.FC<Props> = ({
  anchorOrigin,
  children,
  'data-test-id': dataTestId,
  closeOnClick,
  icon,
  iconSize,
  transformOrigin,
  isDisabled = false,
  popoverPaperSx
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const refContainer = useRef(null)

  return (
    <>
      <IconButton
        sx={{border: 'none', boxShadow: 'none', color: 'primary.main'}}
        data-test-id={dataTestId}
        ref={refContainer}
        onClick={() => !isDisabled && setOpen(!isOpen)}
        size={iconSize ?? 'small'}
        disabled={isDisabled}
      >
        {icon}
      </IconButton>
      <Popover
        PaperProps={{
          sx: mergeSx(
            {
              mt: 1,
              maxWidth: '60%',
              background: ({palette}) => palette.background.paper
            },
            popoverPaperSx
          )
        }}
        anchorEl={refContainer?.current}
        anchorOrigin={anchorOrigin}
        data-test-id="popover"
        onClick={() => closeOnClick && setOpen(false)}
        onClose={() => setOpen(false)}
        open={isOpen}
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </>
  )
}

import {HistoryMaterialStorage, OperationModeType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Alert} from '@hconnect/uikit/src/lib2'
import {ArrowForward} from '@mui/icons-material'
import {Box, Button, Grid} from '@mui/material'
import {useIsMutating} from '@tanstack/react-query'
import type {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedScheduleItemId} from '../../../../../../shared/components/providers/SelectedScheduleItemProvider'
import {SavingIndicator} from '../../../../../../shared/components/SavingIndicator'
import {
  AssetOperationTimeSource,
  CommentsCategory,
  PlannerFeature
} from '../../../../../../shared/enums'
import {useAvailableStoragesForOperationMode} from '../../../../../../shared/hooks/api/schedules/useAvailableStoragesForOperationMode'
import {useScheduleQuery} from '../../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUpdateScheduleItem} from '../../../../../../shared/hooks/api/schedules/useUpdateScheduleItem'
import {useAssetHistoryDataByScheduleItemId} from '../../../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {useCommentsPermissions} from '../../../../../../shared/hooks/permissions'
import {useConfirmDialog} from '../../../../../../shared/hooks/useConfirmDialog'
import {usePlannerFeature} from '../../../../../../shared/hooks/usePlannerFeature'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {Comment, ScheduleItem} from '../../../../../../shared/interfaces/api'
import {AssetWithOperationMode} from '../../../../../../shared/selectors/assets'
import {SelectStorageSection} from '../selectStorage/SelectStorageSection'

import {DeleteScheduleItemButton} from './DeleteScheduleItemButton'
import {ScheduleItemCommentSection} from './ScheduleItemCommentSection'
import {ScheduleItemFixedForOptimizerSwitch} from './ScheduleItemFixedForOptimizerSwitch'
import {ScheduleItemOperationModeSelect} from './ScheduleItemOperationModeSelect'
import {ScheduleItemShutdownAvailabilitySwitch} from './ScheduleItemShutdownAvailablilitySwitch'
import {ScheduleItemStandardOperationTimeInfo} from './ScheduleItemStandardOperationTimeInfo'
import {ScheduleItemStartEndSelect} from './ScheduleItemStartEndSelect'
import {ScheduleItemTransitionTimeSwitch} from './ScheduleItemTransitionTimeSwitch'
import {ScheduleItemUpdatedByInfo} from './ScheduleItemUpdatedByInfo'

export interface EditScheduleItemFormProps {
  startOfPlan: Moment
  endOfPlan: Moment
  scheduleItem: ScheduleItem
  comment?: Comment
  storagesById: Record<string, HistoryMaterialStorage>
  assetData: AssetWithOperationMode
}

export const EditScheduleItemForm: React.FC<EditScheduleItemFormProps> = React.memo(
  ({startOfPlan, endOfPlan, comment, scheduleItem, storagesById, assetData}) => {
    const {t} = useTranslation()
    const {timezone_id: timezoneId} = usePlantConfig()
    const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
    const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
      timeFrame: [startOfPlan, endOfPlan],
      schedule
    })

    const {setScheduleItemId} = useSelectedScheduleItemId()
    const isShutdownAvailabilityFeatureAvailable = usePlannerFeature(
      PlannerFeature.ShutdownAvailability
    )
    const areCommentsEnabled = usePlannerFeature(PlannerFeature.PlanningComments)
    const {asset, operationMode} = assetData

    const {updateScheduleItem, isUpdateScheduleItemLoading} = useUpdateScheduleItem({
      startOfPlan,
      endOfPlan,
      assetDataByScheduleItemId
    })

    // checking if comment is saving right now
    const addCommentMutations = useIsMutating({
      mutationKey: ['addScheduleComment']
    })
    const updateCommentMutations = useIsMutating({
      mutationKey: ['updateScheduleComment']
    })

    const isAddCommentLoading = Boolean(addCommentMutations)
    const isUpdateCommentLoading = Boolean(updateCommentMutations)

    const {canViewComments} = useCommentsPermissions(CommentsCategory.AssetOperationTimes)

    const isSaving = isAddCommentLoading || isUpdateCommentLoading || isUpdateScheduleItemLoading

    const isMaintenance = operationMode.type === OperationModeType.Maintenance

    const shouldShowCommentInput = areCommentsEnabled && canViewComments
    const shouldShowShutdownAvailability = isShutdownAvailabilityFeatureAvailable && !isMaintenance
    const shouldShowIsFixed = !isMaintenance
    const shouldShowStandardOperationTimeInfo =
      scheduleItem.source === AssetOperationTimeSource.StandardOperationTimes

    const availableStorages = useAvailableStoragesForOperationMode(operationMode, [
      startOfPlan,
      endOfPlan
    ])
    // if there is only one available storage, silo selection should be hidden
    const shouldShowSelectedSilosForm = !isMaintenance && availableStorages.length > 1

    const operationModesForCurrentAsset = asset.operationModes

    // Updates the scheduleItem with new values
    const handleOperationModeChange = (operationModeId: number) => {
      const updatedScheduleItem: ScheduleItem = {
        ...scheduleItem,
        assetOperationModeId: operationModeId
      }
      updateScheduleItem(updatedScheduleItem)
    }

    const handleShutdownAvailabilityChange = (isShutdownAvailable: boolean) => {
      const updatedScheduleItem: ScheduleItem = {
        ...scheduleItem,
        isShutdownAvailable
      }
      updateScheduleItem(updatedScheduleItem)
    }

    const handleIsFixedChange = (isFixed: boolean) => {
      const updatedScheduleItem: ScheduleItem = {
        ...scheduleItem,
        isFixed
      }
      updateScheduleItem(updatedScheduleItem)
    }

    const handleTransitionTimeChange = (isTransitionTime: boolean) => {
      const updatedScheduleItem: ScheduleItem = {
        ...scheduleItem,
        isTransitionTime: isTransitionTime
      }
      updateScheduleItem(updatedScheduleItem)
    }

    const {openDialog} = useConfirmDialog()

    const updateWithSplitWarning =
      <T extends number | boolean>(updateFn: (value: T) => void) =>
      (value: T, shouldSplit: boolean) => {
        if (!shouldSplit) {
          return updateFn(value)
        }
        openDialog({
          title: operationMode.name,
          description: t('planning.scheduleItemSplitInfo'),
          testId: 'split_warning_dialog',
          additionalContent: (
            <Alert accentColor="warning" content={t('planning.scheduleItemSplitWarning')} />
          ),
          mainAction: {
            text: t('common.proceed'),
            icon: <ArrowForward />,
            onAction: () => updateFn(value)
          }
        })
      }

    const onOperationModeChange = updateWithSplitWarning(handleOperationModeChange)
    const onTransitionTimeChange = updateWithSplitWarning(handleTransitionTimeChange)

    return (
      <>
        <Grid container spacing={1.5} {...dataTestId('edit_schedule_item_form')}>
          {operationModesForCurrentAsset.length > 1 && (
            <Grid xs={12} item>
              <ScheduleItemOperationModeSelect
                scheduleItem={scheduleItem}
                operationModes={operationModesForCurrentAsset}
                handleOperationModeChange={onOperationModeChange}
              />
            </Grid>
          )}
          <Grid xs={12} item>
            <ScheduleItemStartEndSelect
              scheduleItem={scheduleItem}
              startOfPlan={startOfPlan}
              endOfPlan={endOfPlan}
              updateScheduleItem={updateScheduleItem}
            />
          </Grid>
          <Grid xs={12} item>
            <ScheduleItemUpdatedByInfo scheduleItem={scheduleItem} />
          </Grid>
          {shouldShowStandardOperationTimeInfo && (
            <Grid xs={12} item>
              <ScheduleItemStandardOperationTimeInfo scheduleItem={scheduleItem} />
            </Grid>
          )}
          <Grid xs={12} item>
            <ScheduleItemTransitionTimeSwitch
              scheduleItem={scheduleItem}
              handleTransitionTimeChange={onTransitionTimeChange}
            />
          </Grid>
          {shouldShowShutdownAvailability && (
            <Grid xs={12} item>
              <ScheduleItemShutdownAvailabilitySwitch
                scheduleItem={scheduleItem}
                handleShutdownAvailabilityChange={handleShutdownAvailabilityChange}
              />
            </Grid>
          )}
          {shouldShowIsFixed && (
            <Grid xs={12} item>
              <ScheduleItemFixedForOptimizerSwitch
                scheduleItem={scheduleItem}
                handleShutdownAvailabilityChange={handleIsFixedChange}
              />
            </Grid>
          )}
          {shouldShowSelectedSilosForm && (
            <Grid xs={12} item>
              <SelectStorageSection
                scheduleItem={scheduleItem}
                startOfPlan={startOfPlan}
                endOfPlan={endOfPlan}
                storagesById={storagesById}
                availableStorages={availableStorages}
              />
            </Grid>
          )}
          {shouldShowCommentInput ? (
            <Grid xs={12} item>
              <ScheduleItemCommentSection
                comment={comment}
                timezoneId={timezoneId}
                scheduleItem={scheduleItem}
              />
            </Grid>
          ) : null}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 1,
            my: 2
          }}
        >
          <DeleteScheduleItemButton
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            scheduleItem={scheduleItem}
            comment={areCommentsEnabled ? comment : undefined}
            isReadOnly={isSaving}
          />
          <Box display="flex" alignItems="center">
            <SavingIndicator mr={2} {...dataTestId('saving_indicator')} isSaving={isSaving} />
            <Button
              {...dataTestId('schedule_edit_save')}
              color="primary"
              variant="contained"
              startIcon={<ArrowForward />}
              disabled={isSaving}
              onClick={() => setScheduleItemId(undefined)}
            >
              {t('common.done')}
            </Button>
          </Box>
        </Box>
      </>
    )
  }
)

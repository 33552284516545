import {HPTextFieldProps} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerDaysSelect} from './PlannerDaysSelect'

interface PastFutureTimeframeSelectProps extends Pick<HPTextFieldProps, 'backgroundMode'> {
  pastDurationDays: number[]
  futureDurationDays: number[]
  startOfToday: Moment
  timeframe: [Moment, Moment]
  onTimeframeChange: (timeframe: [Moment, Moment]) => void
}

export const PastFutureTimeframeSelect: React.FC<PastFutureTimeframeSelectProps> = ({
  pastDurationDays,
  futureDurationDays,
  startOfToday,
  timeframe,
  onTimeframeChange,
  backgroundMode = 'onDark'
}) => {
  const {t} = useTranslation()
  const convertTimeFrameToDays = (timeframe: [Moment, Moment]) => {
    const [start, end] = timeframe
    return {
      past: startOfToday.clone().diff(start, 'days'),
      future: end.diff(startOfToday, 'days')
    }
  }

  const handleTimeFrameChange = (days: number, type: 'past' | 'future') => {
    const [start, end] = timeframe
    const newTimeframe: [Moment, Moment] =
      type === 'past'
        ? [startOfToday.clone().subtract(days, 'days'), end]
        : [start, startOfToday.clone().add(days, 'days')]
    onTimeframeChange(newTimeframe)
  }

  const valueInDays = convertTimeFrameToDays(timeframe)

  return (
    <Stack direction="row" spacing={1.5}>
      <PlannerDaysSelect
        days={valueInDays.past}
        dayOptions={pastDurationDays}
        onChange={(days) => handleTimeFrameChange(days, 'past')}
        testId="past_days_select"
        label={t('common.pastInterval')}
        backgroundMode={backgroundMode}
        isHumanized
      />
      <PlannerDaysSelect
        days={valueInDays.future}
        dayOptions={futureDurationDays}
        onChange={(days) => handleTimeFrameChange(days, 'future')}
        testId="future_days_select"
        label={t('common.futureInterval')}
        backgroundMode={backgroundMode}
        isHumanized
      />
    </Stack>
  )
}

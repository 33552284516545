import {Dates} from '@hconnect/uikit'
import {Moment} from 'moment-timezone'
import React, {useState, useCallback} from 'react'

import {useCurrentTimeRounded} from '../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'

import {AutofillRemovalSelectRangeStep} from './AutofillRemovalSelectRangeStep'
import {AutofillRemovalWarningStep} from './AutofillRemovalWarningStep'

type AutofillRemovalStep = 'selectRange' | 'warning'

interface AutofillRemovalDialogContentProps {
  endOfPlanning: Moment
}

export const AutofillRemovalDialogContent: React.FC<AutofillRemovalDialogContentProps> = ({
  endOfPlanning
}) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const startOfTommorow = useCurrentTimeRounded({
    roundingFn: (datetime) => datetime.clone().add(1, 'day').startOf('day'),
    timezoneId
  })

  const [selectedRange, setSelectedRange] = useState<Dates>({
    startDate: startOfTommorow,
    endDate: null
  })
  const [step, setStep] = useState<AutofillRemovalStep>('selectRange')

  const validateDate = useCallback(
    (date: Moment) => {
      return date.isBetween(startOfTommorow, endOfPlanning, 'minute', '[)')
    },
    [startOfTommorow, endOfPlanning]
  )

  const onNext = useCallback(() => setStep('warning'), [])

  return (
    <>
      {step === 'selectRange' && (
        <AutofillRemovalSelectRangeStep
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          validateDate={validateDate}
          onNext={onNext}
        />
      )}
      {step === 'warning' && selectedRange.startDate && selectedRange.endDate && (
        <AutofillRemovalWarningStep
          selectedRange={[selectedRange.startDate, selectedRange.endDate]}
        />
      )}
    </>
  )
}

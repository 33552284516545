import {StockLevelOverwrite} from '@hconnect/common/types'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {useTheme} from '@mui/material'
import * as d3 from 'd3'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback, useMemo} from 'react'

import {useDateScale} from '../../helpers/scale'
import {toPixel} from '../../helpers/utils'
import {useTargetLevelsDaily} from '../../hooks/minTargetLevelsDaily'
import {useCurrentTimeRounded} from '../../hooks/useCurrentTimeRounded'
import {DatetimeValue} from '../../interfaces/common'
import type {IncomingMaterial} from '../../selectors/materialOrders'
import {StockDevelopmentData} from '../../selectors/stockDevelopment'
import {
  ChartGrid,
  ChartPath,
  ChartNowLine,
  ChartLine,
  DatetimeValueChartTooltip,
  ChartFill
} from '../d3chart'

import {IncomingMaterialIndicator} from './incomingMaterials/IncomingMaterialIndicator'
import {StockOverwriteIndicator} from './overwrites/StockOverwriteIndicator'
import {useStockDevelopmentChartConfig} from './StockDevelopmentChartConfigProvider'
import {StockDevelopmentTooltipContent} from './StockDevelopmentTooltipContent'

interface StockDevelopmentChartProps {
  chartRef: React.RefObject<HTMLDivElement>
  listOfDays: Moment[]
  stockData: StockDevelopmentData & {
    stockOverwrites?: StockLevelOverwrite[]
  }
  minTargetLevels?: {weekend: number; week: number}
  deadStockLevel?: number
  storageCapacity?: number
  height?: number
  width: number
  openStockOverwriteDialog?: (
    datetimeValue: DatetimeValue<string, number>,
    currentOverwrite: StockLevelOverwrite | undefined
  ) => void
  incomingMaterialHourly?: Record<string, IncomingMaterial[]>
}

export const StockDevelopmentChartData: React.FC<StockDevelopmentChartProps> = ({
  listOfDays,
  stockData,
  minTargetLevels,
  deadStockLevel,
  openStockOverwriteDialog,
  storageCapacity,
  height = 200,
  chartRef,
  width,
  incomingMaterialHourly
}) => {
  const {palette} = useTheme()
  const {
    // differentiate between actual and calculated stock levels when required
    stockLevel: {combined: combinedStockLevel},
    stockLevelHourly
  } = stockData

  const {yScale, verticalSteps, minStockValue, timezoneId} = useStockDevelopmentChartConfig()

  const currentDateTime = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  const xScale = useDateScale({
    domain: [listOfDays[0], listOfDays[listOfDays.length - 1]],
    range: [0, width]
  })

  const minTargetLevelDatetimeValues = useTargetLevelsDaily({
    minTargetLevels,
    listOfDays,
    timezoneId
  })

  const maxMinTargetLevelDatetimeValues = Math.max(
    ...(minTargetLevelDatetimeValues ?? []).map(({value}) => value)
  )
  const minTargetLevelBaseValue = Math.min(
    minStockValue,
    ...(minTargetLevelDatetimeValues ?? []).map(({value}) => value)
  )

  const canCreateStockOverwrite = !!openStockOverwriteDialog

  const {stockOverwrites} = stockData

  const formatTooltipContent = useCallback(
    (datetimeValue: DatetimeValue) => (
      <StockDevelopmentTooltipContent
        canCreateStockOverwrite={canCreateStockOverwrite}
        datetimeValue={datetimeValue}
        stockOverwrites={stockOverwrites ?? []}
      />
    ),
    [canCreateStockOverwrite, stockOverwrites]
  )

  const onCreateStockOverwrite = useMemo(
    () =>
      canCreateStockOverwrite
        ? (event: React.MouseEvent<HTMLDivElement>) => {
            const [mouseX]: [number, number] = d3.pointer(event)
            const hoveredDate = xScale.invert(mouseX)
            const positionX = d3.bisect(
              combinedStockLevel.map((entry) => new Date(entry.datetime)),
              hoveredDate
            )

            const clickedDatetimeValue = combinedStockLevel[positionX]
            const clickedDatetimeValueMoment = moment(clickedDatetimeValue.datetime).tz(timezoneId)

            if (clickedDatetimeValueMoment.isSameOrBefore(currentDateTime)) {
              const overwrite = stockData.stockOverwrites?.find((o) =>
                moment.utc(o.measuredAt).tz(timezoneId).isSame(clickedDatetimeValueMoment)
              )

              openStockOverwriteDialog(clickedDatetimeValue, overwrite)
            }
          }
        : undefined,
    [
      canCreateStockOverwrite,
      xScale,
      combinedStockLevel,
      timezoneId,
      currentDateTime,
      stockData.stockOverwrites,
      openStockOverwriteDialog
    ]
  )

  return (
    <div style={{position: 'relative'}} ref={chartRef}>
      {canCreateStockOverwrite && (
        <div
          style={{
            left: '0px',
            top: '0px',
            zIndex: 1,
            position: 'absolute',
            height: height,
            cursor: 'pointer',
            width: toPixel(xScale(currentDateTime.toDate()))
          }}
          onClick={onCreateStockOverwrite}
        />
      )}
      <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
        {/* This element is to create shadow for area under minimum target values */}
        {maxMinTargetLevelDatetimeValues > minStockValue && (
          <>
            <ChartFill
              datetimeValues={minTargetLevelDatetimeValues || []}
              gradient="bottom"
              xScale={xScale}
              yScale={yScale}
              alpha={0.2}
              startFillYValue={minTargetLevelBaseValue}
              overridePathAttributes={{fill: palette.error.light}}
            />
            {/* Hide previous shadow under stock levels by overlaying previous element with white one*/}
            <ChartFill
              datetimeValues={combinedStockLevel}
              xScale={xScale}
              yScale={yScale}
              startFillYValue={minTargetLevelBaseValue}
              overridePathAttributes={{fill: palette.background.paper}}
            />
          </>
        )}
        <ChartGrid xSteps={listOfDays} ySteps={verticalSteps} xScale={xScale} yScale={yScale} />
        <ChartNowLine xScale={xScale} timezoneId={timezoneId} maxY={0} minY={height} />
        {deadStockLevel !== undefined && (
          <ChartLine
            variant="horizontal"
            xScale={xScale}
            yScale={yScale}
            xDomain={[listOfDays[0], listOfDays[listOfDays.length - 1]]}
            yValue={deadStockLevel}
            overrideLineAttributes={{
              stroke: palette.error.light,
              opacity: 1,
              strokeWidth: '1px'
            }}
            testId={'dead_stock_line'}
          />
        )}
        {storageCapacity !== undefined && (
          <ChartLine
            variant="horizontal"
            xScale={xScale}
            yScale={yScale}
            xDomain={[listOfDays[0], listOfDays[listOfDays.length - 1]]}
            yValue={storageCapacity}
            overrideLineAttributes={{
              stroke: palette.error.light,
              opacity: 1,
              strokeWidth: '1px'
            }}
          />
        )}
        <ChartPath datetimeValues={combinedStockLevel} xScale={xScale} yScale={yScale} fill />
        {minTargetLevelDatetimeValues && (
          <ChartPath
            datetimeValues={minTargetLevelDatetimeValues}
            xScale={xScale}
            yScale={yScale}
            overridePathAttributes={{
              stroke: palette.error.light,
              opacity: 1,
              shapeRendering: 'geometricPrecision',
              strokeDasharray: '4,4',
              strokeWidth: '1px'
            }}
            testId="min_target_level_line"
          />
        )}
        {Object.values(incomingMaterialHourly ?? {}).flatMap((incomingMaterials) => {
          return incomingMaterials.map((incomingMaterial, index) => (
            <IncomingMaterialIndicator
              key={`${incomingMaterial.datetime.toISOString()}_${index}`}
              x={xScale(incomingMaterial.datetime)}
              y={yScale(
                stockLevelHourly[incomingMaterial.datetime.toISOString()] +
                  incomingMaterials.slice(0, index).reduce((acc, current) => acc + current.value, 0)
              )}
              incomingMaterial={incomingMaterial}
            />
          ))
        })}
        {(stockOverwrites ?? []).map((overwrite, index) => {
          const time = moment(overwrite.measuredAt).tz(timezoneId)
          return (
            <StockOverwriteIndicator
              key={`${time.toISOString()}_${index}`}
              x={xScale(time.toDate())}
              y={yScale(overwrite.level)}
              {...dataTestId('stock_overwrite_indicator')}
            />
          )
        })}
      </svg>
      <DatetimeValueChartTooltip
        chartRef={chartRef}
        xScale={xScale}
        yScale={yScale}
        datetimeValues={combinedStockLevel}
        formatTooltip={formatTooltipContent}
        variant="light"
      />
    </div>
  )
}

import {dataTestId, formatCurrency} from '@hconnect/uikit'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {AmpelPriceLevels} from '../ampel'
import {usePriceColors} from '../usePriceColors'

import {Legend, LegendItem} from './Legend'

interface ElectricityPriceLegendItemsProps {
  priceLevels: AmpelPriceLevels
  currency: string
}
export const ElectricityPriceLegendItems: FC<ElectricityPriceLegendItemsProps> = React.memo(
  ({priceLevels, currency}) => {
    const {
      t,
      i18n: {language}
    } = useTranslation()

    const {veryLow, mid, veryHigh, unknown} = usePriceColors()

    return (
      <Legend {...dataTestId('electricity_price_legend')}>
        {priceLevels ? (
          <>
            <LegendItem
              label={t('electricity.priceRange', {
                min: formatCurrency(priceLevels.minPrice, 0, language),
                max: formatCurrency(priceLevels.lowPriceMax, 0, language),
                currency
              })}
              indicator={{variant: 'circle', style: {backgroundColor: veryLow}}}
            />
            <LegendItem
              label={t('electricity.priceRange', {
                min: formatCurrency(priceLevels.lowPriceMax, 0, language),
                max: formatCurrency(priceLevels.midPriceMax, 0, language),
                currency
              })}
              indicator={{variant: 'circle', style: {backgroundColor: mid}}}
            />
            <LegendItem
              label={t('electricity.priceRange', {
                min: formatCurrency(priceLevels.midPriceMax, 0, language),
                max: formatCurrency(priceLevels.maxPrice, 0, language),
                currency
              })}
              indicator={{variant: 'circle', style: {backgroundColor: veryHigh}}}
            />
          </>
        ) : (
          <LegendItem
            label={t('common.noForecast')}
            indicator={{variant: 'circle', style: {backgroundColor: unknown}}}
          />
        )}
      </Legend>
    )
  }
)

import {dataTestId} from '@hconnect/uikit'
import {Stack} from '@mui/material'
import React from 'react'

import {CommentsCategory, PlannerFeature} from '../../../../../shared/enums'
import {useCommentsPermissions} from '../../../../../shared/hooks/permissions'
import {usePlannerFeature} from '../../../../../shared/hooks/usePlannerFeature'

import {CalculateCostAvoidanceButton} from './CalculateCostAvoidanceButton'
import {CommentSidebarButton} from './CommentSidebarButton'
import {ExcelDownload} from './ExcelDownload'
import {RemoveAutofillItemsButton} from './RemoveAutofillItemsButton'
import {SendBackupButton} from './SendBackupButton'
import {StartCancelRecalculation} from './StartCancelRecalculation'

interface PlanningActionsContentProps {
  toggleCommentsSidebar?: () => void
  isPlanningCardCollapsed: boolean
}

export const PlanningActionsContent: React.FC<PlanningActionsContentProps> = ({
  toggleCommentsSidebar,
  isPlanningCardCollapsed
}) => {
  const isCostAvoidanceAvailable = usePlannerFeature(PlannerFeature.CostAvoidance)
  const isSubmitAvailable = usePlannerFeature(PlannerFeature.ElectricityPurchasing)
  const areCommentsEnabled = usePlannerFeature(PlannerFeature.PlanningComments)
  const {canViewComments: canViewAssetComments} = useCommentsPermissions(CommentsCategory.Assets)
  const {canViewComments: canViewAssetOperationTimesComments} = useCommentsPermissions(
    CommentsCategory.AssetOperationTimes
  )

  const canViewComments = canViewAssetComments || canViewAssetOperationTimesComments
  const shouldShowSendBackupButton = !isSubmitAvailable
  const shouldShowCalculateCostAvoidanceButton = !isSubmitAvailable && isCostAvoidanceAvailable

  const shouldShowCommentSidebarButton =
    areCommentsEnabled && canViewComments && toggleCommentsSidebar && !isPlanningCardCollapsed
  return (
    <>
      <Stack
        spacing={1.5}
        sx={{display: 'flex', alignItems: 'center'}}
        direction="row"
        {...dataTestId('planning_actions')}
      >
        <RemoveAutofillItemsButton />
        <ExcelDownload />
        {shouldShowSendBackupButton && <SendBackupButton />}
        {shouldShowCalculateCostAvoidanceButton && <CalculateCostAvoidanceButton />}
        <StartCancelRecalculation />
        {shouldShowCommentSidebarButton && (
          <CommentSidebarButton toggleCommentSidebar={toggleCommentsSidebar} />
        )}
      </Stack>
    </>
  )
}

import {useTheme, styled, darken} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ElectricityPriceLegendItems} from '../../../../shared/components/ElectricityPriceLegendItems'
import {Legend, LegendItem} from '../../../../shared/components/Legend'
import {useElectricityPricesDataQuery} from '../../../../shared/hooks/api/dataScience/useElectricityPricesDataQuery'
import {useMediaQueryBreakpoints} from '../../../../shared/hooks/useMediaQueryBreakpoints'
import {usePriceColors} from '../../../../shared/usePriceColors'

const StyledLegendLine = styled('line')({
  pointerEvents: 'none',
  fill: 'none',
  strokeWidth: '2px'
})

interface PlanningChartLegendProps {
  shouldShowActualPricesLegend?: boolean
  startOfPlan: Moment
  endOfPlan: Moment
}

const _PlanningChartLegend: React.FC<PlanningChartLegendProps> = ({
  shouldShowActualPricesLegend = false,
  startOfPlan,
  endOfPlan
}) => {
  const {t} = useTranslation()
  const {palette} = useTheme()
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const lineLegendWidth = isMobile ? 20 : 40
  const {data: electricityPrice} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])
  const {unknown: unknowPriceColor} = usePriceColors()
  const isActualPricesVisible = !!electricityPrice?.actual.length && shouldShowActualPricesLegend

  return (
    <Legend testId="price_legend" sx={{justifyContent: 'flex-end'}}>
      {electricityPrice?.priceLevels && (
        <ElectricityPriceLegendItems
          priceLevels={electricityPrice.priceLevels}
          currency={electricityPrice.currency}
        />
      )}
      <LegendItem
        label={t('common.noForecast')}
        indicator={{
          style: {backgroundColor: unknowPriceColor},
          variant: 'circle'
        }}
        testId="final-price-legend"
      />
      {isActualPricesVisible && (
        <>
          <LegendItem
            label={t('common.finalPrice')}
            indicator={{
              style: {
                border: `1px solid ${palette.common.white}`,
                backgroundColor: palette.grey[500]
              }
            }}
            testId="final-price-legend"
          />
          <LegendItem
            label={t('common.priceForecast')}
            indicator={{
              style: {
                border: `1px solid ${palette.common.white}`,
                backgroundColor: darken(palette.primary.dark, 0.4)
              }
            }}
            testId="forecasted-price-legend"
          />
        </>
      )}
      <LegendItem label={t('common.now')}>
        <svg height={5} width={lineLegendWidth}>
          <StyledLegendLine
            x1={0}
            y1={3}
            x2={lineLegendWidth}
            y2={3}
            stroke={palette.text.primary}
          />
        </svg>
      </LegendItem>
    </Legend>
  )
}
export const PlanningChartLegend = React.memo(_PlanningChartLegend)

import {dataTestId, formatCurrency, formatLocalizedDateWithShortYear} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Stack, useTheme} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {ReactComponent as Illustration} from '../../../../assets/images/savings.svg'
import {ScheduleCostAvoidance} from '../../../../shared/interfaces/api'

interface PositiveCostAvoidanceInfoProps {
  scheduleCostAvoidance: ScheduleCostAvoidance
}

export const PositiveCostAvoidanceInfo: React.FC<PositiveCostAvoidanceInfoProps> = ({
  scheduleCostAvoidance
}) => {
  const {palette} = useTheme()
  const {
    i18n: {language}
  } = useTranslation()
  const {costAvoidance, currency, date} = scheduleCostAvoidance
  return (
    <Stack spacing={4} alignItems="center" {...dataTestId('positive_cost_avoidance_info')}>
      <Illustration />
      <CardTitle sx={{mb: 0, textAlign: 'center'}} variant="h3">
        <Trans
          i18nKey="planning.savingsTitle"
          values={{
            amount: formatCurrency(costAvoidance, 0, language),
            currency: currency,
            date: formatLocalizedDateWithShortYear(moment(date), language)
          }}
          components={{1: <br />, 2: <span style={{color: palette.success.light}} />, 3: <br />}}
        />
      </CardTitle>
    </Stack>
  )
}

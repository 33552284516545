import {MaterialSource, type Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Stack} from '@mui/material'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES, RouteName, ROUTES, RoutingState} from '../../../routing'
import {PlannerBackButton} from '../../../shared/components/buttons'
import {PlannerPageLayout} from '../../../shared/components/PlannerPageLayout'
import {SelectedMaterialOrderProvider} from '../../../shared/components/providers/SelectedMaterialOrderProvider'
import {SelectedTimeframeProvider} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerPageSkeleton} from '../../../shared/components/skeletons/PlannerPageSkeleton'
import {pageDataTestId} from '../../../shared/formatutils'
import {useMaterialsById} from '../../../shared/hooks/api'
import {useIsMaterialUnmeasured} from '../../../shared/hooks/api/materials/useIsMaterialUnmeasured'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useStartOfToday} from '../../../shared/hooks/useStartOfToday'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import type {MomentRange} from '../../../shared/selectors/time'

import {CreateEditMaterialOrderDialog} from './createEditMaterialOrder'
import {DetailedStockDevelopmentCard} from './DetailedStockDevelopmentCard'
import {ManageOrdersSection} from './ManageOrdersSection'
import {MaterialOrdersTitleActions} from './MaterialOrdersTitleActions'

export const PageMaterialOrders: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {created_at: createdAt, timezone_id: timezoneId} = usePlantConfig()
  const startOfToday = useStartOfToday({createdAt, timezoneId})

  // TODO: refactor this using custom hook like useUrlParam
  const {state} = useLocation()
  const routingState = state as RoutingState

  const plantCode = useUrlParam('plantCode')
  const materialId = useUrlParam('materialId')
  const {data: materialsById} = useMaterialsById()

  if (!materialsById) {
    throw new Error('BUG: materialsById should be loaded before rendering this component')
  }

  const currentMaterial: Material = materialsById[materialId]

  const {data: isMaterialUnmeasured, isLoading: materialIsUnmeasuredIsLoading} =
    useIsMaterialUnmeasured(currentMaterial.id)

  const defaultTimeframe: MomentRange = useMemo(() => {
    const start = startOfToday.clone().subtract(1, 'day').startOf('day')
    const end = startOfToday.clone().add(9, 'days')
    return [start, end]
  }, [startOfToday])

  if (materialIsUnmeasuredIsLoading) {
    return <PlannerPageSkeleton />
  }

  const isBoughtFromVendor = currentMaterial.source === MaterialSource.BoughtFromVendor

  if (!(isMaterialUnmeasured || isBoughtFromVendor)) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  const canShowOrders = currentMaterial.products.length > 0

  if (!isMaterialUnmeasured && !canShowOrders) {
    return (
      <Navigate
        to={getUrl(PLANT_ROUTES.STOCK.MATERIALS.path, {
          plantCode
        })}
        replace
      />
    )
  }

  const handleGoBack = () => {
    if (routingState?.materialsVisitedFrom === 'storage') {
      navigate(
        `${getUrl(PLANT_ROUTES.STOCK.SILOS.path, {plantCode})}?materialType=${currentMaterial.type}`
      )
    } else {
      // materialsVisitedFrom default is material
      navigate(
        `${getUrl(PLANT_ROUTES.STOCK.MATERIALS.path, {plantCode})}?materialType=${
          currentMaterial.type
        }`
      )
    }
  }

  return (
    <SelectedTimeframeProvider defaultTimeframe={defaultTimeframe}>
      <SelectedMaterialOrderProvider>
        <PlannerPageLayout
          isTourBannerEnabled={false}
          aboveTitle={
            <PlannerBackButton
              text={
                routingState?.materialsVisitedFrom === 'storage'
                  ? t('navItems.backToSilos')
                  : t('navItems.backToMaterials')
              }
              onGoBack={handleGoBack}
              {...dataTestId(
                routingState?.materialsVisitedFrom === 'storage'
                  ? 'back_to_silos_button'
                  : 'back_to_materials_button'
              )}
            />
          }
          headerActionContent={<MaterialOrdersTitleActions canAddMaterialOrder={canShowOrders} />}
          title={currentMaterial.name}
          {...pageDataTestId(RouteName.MATERIAL_ORDERS)}
        >
          <Stack spacing={2}>
            <DetailedStockDevelopmentCard />
            {canShowOrders && <ManageOrdersSection />}
          </Stack>
          {canShowOrders && <CreateEditMaterialOrderDialog />}
        </PlannerPageLayout>
      </SelectedMaterialOrderProvider>
    </SelectedTimeframeProvider>
  )
}

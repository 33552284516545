import {roundTo15MinIntervalStart} from '@hconnect/common/utils/roundTo15MinIntervalStart.utils'
import {FormControl, FormHelperText, MenuItem, Stack, StackProps} from '@mui/material'
import {DatePickerProps, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import moment, {Moment} from 'moment-timezone'
import {RefObject} from 'react'

import {dataTestId} from '../../../common/utils/testingUtils'
import {HPTextField} from '../HPTextField'

type DateTimePickerProps = {
  value: Moment
  error?: {message?: string}
  onChange: (value: Moment | null, keyboardInputValue?: string) => void
  minDate?: moment.Moment | undefined
  maxDate?: moment.Moment | undefined
  dateLabel: string
  timeLabel: string
  disabled?: boolean
  timezoneId: string
  ref?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null
} & Pick<DatePickerProps<Moment, Moment>, 'shouldDisableDate'>

const getStringToMomentTransformer =
  (timezoneId: string, onChange: (...event: any[]) => void) =>
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const momentValue = moment(e.target.value).tz(timezoneId) // Parse ISO string to moment
    onChange(momentValue)
  }

const generateTimeSteps = (start: Moment, end: Moment, rangeMin = 15) => {
  const values: Moment[] = []
  while (start.isBefore(end)) {
    values.push(start)
    start = start.clone().add(rangeMin, 'minutes')
  }
  return values
}

export const DateTimePicker = ({
  value,
  error,
  ref,
  minDate,
  maxDate,
  disabled,
  onChange,
  dateLabel,
  timeLabel,
  shouldDisableDate,
  timezoneId,
  ...props
}: Omit<StackProps, 'onChange'> & DateTimePickerProps) => {
  const timeSteps = disabled
    ? generateTimeSteps(value.clone(), value.clone().add(15, 'minutes'))
    : generateTimeSteps(
        moment.max(
          [
            value.clone().startOf('day'),
            minDate ? roundTo15MinIntervalStart(minDate) : undefined
          ].filter(Boolean) as Moment[]
        ),
        moment.min(
          [
            value.clone().startOf('day').add(1, 'day'),
            maxDate ? roundTo15MinIntervalStart(maxDate) : undefined
          ].filter(Boolean) as Moment[]
        )
      )

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={value.locale()}>
      <FormControl ref={ref} error={!!error}>
        <Stack {...props} direction="row" spacing={1.5}>
          <DatePicker
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            shouldDisableDate={shouldDisableDate}
            renderInput={(props) => (
              <HPTextField
                {...dataTestId('date_select')}
                {...props}
                error={!!error}
                inputProps={{...props.inputProps, readOnly: true}}
              />
            )}
            label={dateLabel}
          />
          <HPTextField
            sx={{width: ({spacing}) => spacing(30)}}
            select
            error={!!error}
            disabled={disabled}
            value={value.toISOString()}
            label={timeLabel}
            variant="outlined"
            onChange={getStringToMomentTransformer(timezoneId, onChange)}
            InputLabelProps={{shrink: true}}
            {...dataTestId('time_select')}
          >
            {timeSteps.map((value) => (
              <MenuItem
                key={value.toISOString()}
                value={value.toISOString()}
                {...dataTestId('time_select_item')}
              >
                {value.format('HH:mm')}
              </MenuItem>
            ))}
          </HPTextField>
        </Stack>
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    </LocalizationProvider>
  )
}

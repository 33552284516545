import {dataTestId, datetimeFormatter, formatTons} from '@hconnect/uikit'
import {Column, CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {
  SvgIconComponent,
  AccessTime,
  Check,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material'
import {Typography, Stack, useTheme, Collapse} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerDataTable} from '../../../../shared/components/PlannerDataTable'
import {DeliveryStatus} from '../../../../shared/enums'
import {DeliveryDetail} from '../../../../shared/selectors/materialDeliveryDetails'

const DeliveryTableIcon: React.FC<{status: DeliveryStatus}> = ({status}) => {
  const {palette} = useTheme()
  const deliveryStatusToIconMap: Record<DeliveryStatus, SvgIconComponent> = {
    [DeliveryStatus.Delivered]: Check,
    [DeliveryStatus.Overdue]: AccessTime,
    [DeliveryStatus.Unknown]: Check
  }
  const deliveryStatusToStyleMap: Record<DeliveryStatus, {color: string}> = {
    [DeliveryStatus.Delivered]: {color: palette.success.light},
    [DeliveryStatus.Overdue]: {color: palette.warning.light},
    [DeliveryStatus.Unknown]: {color: palette.success.light}
  }

  const Icon = deliveryStatusToIconMap[status]
  return <Icon sx={{...deliveryStatusToStyleMap[status]}} />
}

interface MaterialDeliveriesTableProps {
  deliveries: DeliveryDetail[]
}

export const MaterialDeliveriesTable: React.FC<MaterialDeliveriesTableProps> = ({deliveries}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const [isTableOpen, setIsTableOpen] = useState(false)

  const tableColumns: Column<DeliveryDetail>[] = [
    {
      key: 'time',
      label: t('common.time'),
      customTemplate: (deliveryDetail) => (
        <Typography variant="h5">
          {deliveryDetail.status !== DeliveryStatus.Overdue
            ? datetimeFormatter(deliveryDetail.time, language)
            : '-'}
        </Typography>
      )
    },
    {
      key: 'amount',
      label: t('common.amount'),
      customTemplate: ({amount}) => (
        <Typography variant="h5">{formatTons(amount, language)}</Typography>
      )
    },
    {
      key: 'status',
      label: t('common.status'),
      customTemplate: ({status}) => {
        const shouldShowAsDelivered =
          status === DeliveryStatus.Delivered || status === DeliveryStatus.Unknown
        return (
          <Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
            <DeliveryTableIcon status={status} />
            <Typography
              variant="h5"
              sx={{
                color: ({palette}) =>
                  shouldShowAsDelivered ? palette.success.light : palette.warning.light
              }}
            >
              {t(
                shouldShowAsDelivered
                  ? 'stock.materialOrders.Delivered'
                  : 'stock.materialOrders.Overdue'
              )}
            </Typography>
          </Stack>
        )
      }
    }
  ]

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{cursor: 'pointer'}}
        onClick={() => setIsTableOpen((prevState) => !prevState)}
      >
        {isTableOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        <CardSectionTitle variant="h4">
          {t('stock.materialOrders.deliveriesAmount', {
            amount: deliveries.filter((entry) => entry.status !== DeliveryStatus.Overdue).length
          })}
        </CardSectionTitle>
      </Stack>
      <Collapse in={isTableOpen}>
        <PlannerDataTable
          columns={tableColumns}
          data={deliveries}
          {...dataTestId('deliveries_table')}
          keyExtractor={(deliveryDetail) =>
            deliveryDetail.status === DeliveryStatus.Overdue ? 'overdue' : deliveryDetail.time
          }
        />
      </Collapse>
    </Stack>
  )
}

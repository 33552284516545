import {useNotification} from '@hconnect/uikit'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {ErrorResponse} from '../../../interfaces/api'

export const useRemoveAutofillItems = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.removeAutofillItems>[0]
  >({
    mutationFn: mutations.removeAutofillItems,
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.removeAutofillItems'))
    },
    onSettled: (data, error, {plantCode}) => {
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode})
      invalidateHcemQuery('costAvoidanceForRange')
      invalidateHcemQuery('stockDevelopment')
    }
  })
}

import {dataTestId} from '@hconnect/uikit'
import {formatLocalizedDateMonth, dayNameFormatter} from '@hconnect/uikit/src/common'
import {Box, Stack, Typography, useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlanningChartStartEnd} from '../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {toPixel} from '../../helpers/utils'
import {isStartOfDay} from '../../selectors/time'

const isMiddleOfDay = (date: Moment) => isStartOfDay(date.clone().add(12, 'hours'))

export interface ChartHeaderDatetimeRowsProps {
  isTextVisible: boolean
  cellWidth: number
  dateLabelHeight: number
}

const HEADER_DATE_EL_WIDTH = 60
const HEADER_HOUR_EL_WIDTH = 18

// hour element + padding around it for calculating skipping hours ( showHourEvery )
const HOURS_VISIBLE_MIN_WIDTH = HEADER_HOUR_EL_WIDTH + 2

export const _ChartHeaderDatetimeRows: React.FC<ChartHeaderDatetimeRowsProps> = ({
  cellWidth,
  dateLabelHeight
}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const {palette} = useTheme()
  const {visibleHoursList} = usePlanningChartStartEnd()

  const hourLabelStyles: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column'
  }

  // skip hours if cellWidth is too small ( if showHourEvery is 2 then we would show every second hour )
  const showHourEvery = Math.ceil(HOURS_VISIBLE_MIN_WIDTH / cellWidth)

  return (
    <Stack direction="column">
      {/* Date row */}
      <Box
        sx={{
          height: toPixel(dateLabelHeight),
          width: toPixel(visibleHoursList.length * cellWidth),
          display: 'block',
          zIndex: 1,
          position: 'absolute',
          top: 0
        }}
      >
        {visibleHoursList.map((hour, hourIndex) => {
          if (isStartOfDay(hour) && hourIndex !== 0 && hourIndex < visibleHoursList.length - 1) {
            return (
              <div
                key={`date-label-row-divider-${hourIndex}`}
                style={{
                  width: 1,
                  left: toPixel(hourIndex * cellWidth - 0.5),
                  height: toPixel(dateLabelHeight),
                  backgroundColor: palette.text.secondary,
                  position: 'absolute'
                }}
                {...dataTestId('gantt-date-label-divider')}
              />
            )
          }

          if (isMiddleOfDay(hour)) {
            const displayDate = `${dayNameFormatter(hour, language)} ${formatLocalizedDateMonth(
              hour,
              language
            )}`

            return (
              <div
                key={`date-label-row-${hourIndex}`}
                style={{
                  ...hourLabelStyles,
                  width: toPixel(HEADER_DATE_EL_WIDTH),
                  left: toPixel(hourIndex * cellWidth - HEADER_DATE_EL_WIDTH / 2),
                  position: 'absolute',
                  alignItems: 'center',
                  bottom: 0,
                  textAlign: 'center'
                }}
                {...dataTestId('gantt-date-label')}
              >
                <Typography variant="body2" sx={{width: 'max-content', color: 'text.secondary'}}>
                  {displayDate}
                </Typography>
              </div>
            )
          }
        })}
      </Box>
      {/* Hour row */}
      <Box
        sx={{
          height: toPixel(dateLabelHeight),
          width: toPixel(visibleHoursList.length * cellWidth),
          display: 'block',
          zIndex: 1,
          position: 'absolute',
          top: toPixel(dateLabelHeight)
        }}
      >
        {visibleHoursList.map((hour, hourIndex) => {
          const visibleHour = hour.clone().add(1, 'hour').hour()
          if (visibleHour % showHourEvery !== 0) {
            return null
          }
          return (
            <div
              key={`hour-label-row-${hourIndex}`}
              style={{
                ...hourLabelStyles,
                width: toPixel(HEADER_HOUR_EL_WIDTH),
                left: toPixel((hourIndex + 1) * cellWidth - HEADER_HOUR_EL_WIDTH / 2),
                height: toPixel(dateLabelHeight),
                position: 'absolute',
                justifyContent: 'center',
                bottom: 0,
                textAlign: 'center'
              }}
              {...dataTestId('gantt-hour-label')}
            >
              <Typography variant="body2" sx={{width: 'max-content', color: 'text.secondary'}}>
                {visibleHour.toString().padStart(2, '0')}
              </Typography>
            </div>
          )
        })}
      </Box>
    </Stack>
  )
}

export const ChartHeaderDatetimeRows = React.memo(_ChartHeaderDatetimeRows)

import {Dates} from '@hconnect/uikit'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Close, Check} from '@mui/icons-material'
import {Button, Stack, Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useDialog} from '../../../../../shared/components/providers/DialogProvider'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import {DatetimeRangePicker} from '../scheduleCardActions/DatetimeRangePicker'

export const AutofillRemovalSelectRangeStep: React.FC<{
  selectedRange: Dates
  setSelectedRange: (range: Dates) => void
  validateDate: (date: Moment) => boolean
  onNext: () => void
}> = ({selectedRange, setSelectedRange, validateDate, onNext}) => {
  const {t} = useTranslation()
  const {closeDialog} = useDialog()
  const {timezone_id: timezoneId} = usePlantConfig()

  const onCancel = () => closeDialog()

  return (
    <Stack spacing={2} {...dataTestId('autofill_removal_select_range_step')}>
      <Typography variant="body1">{t('planning.removeAutofillItemsDescription')}</Typography>
      <DatetimeRangePicker
        timezoneId={timezoneId}
        range={selectedRange}
        onChange={setSelectedRange}
        validator={validateDate}
        {...dataTestId('autofill_removal_datetime_range_picker')}
      />
      <Stack direction="row" spacing={1.5} justifyContent="flex-end">
        <Button
          color="secondary"
          {...dataTestId('confirm_dialog_cancel_button')}
          onClick={onCancel}
          startIcon={<Close />}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!selectedRange.endDate}
          {...dataTestId('next_button')}
          color="primary"
          onClick={onNext}
          startIcon={<Check />}
        >
          {t('common.confirm')}
        </Button>
      </Stack>
    </Stack>
  )
}

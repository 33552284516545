import {AssetType} from '@hconnect/common/types'
import React from 'react'

import {ReactComponent as RotaryKilnIcon} from './icons/KilnRotary.svg'
import {ReactComponent as CementMillIcon} from './icons/MillCement.svg'
import {ReactComponent as RawMillIcon} from './icons/MillRaw.svg'
import {ReactComponent as OtherAssetsIcon} from './icons/OtherAssetsIcon.svg'
export const assetIconMap: Record<AssetType, React.ReactNode> = {
  [AssetType.CementMill]: <CementMillIcon style={{fill: 'inherit'}} />,
  [AssetType.RawMill]: <RawMillIcon style={{fill: 'inherit'}} />,
  [AssetType.RotaryKiln]: <RotaryKilnIcon style={{fill: 'inherit'}} />,
  [AssetType.Other]: <OtherAssetsIcon style={{fill: 'inherit'}} />,
  [AssetType.Crusher]: <OtherAssetsIcon style={{fill: 'inherit'}} />,
  // TODO find icons for coal mill and crusher
  [AssetType.CoalMill]: <></>,
  [AssetType.BaseLoad]: <></>
}

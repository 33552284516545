import {AssetType, OperationModeType, AssetSnapshot} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {formatCurrency, formatTimeZoneDate, formatTons} from '@hconnect/uikit/src/common'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Typography, Box, Divider, Grid, Stack} from '@mui/material'
import type {PopperProps} from '@mui/material'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {assetIconMap} from '../../../../../../assets/AssetIcon'
import {PlannerIcon} from '../../../../../../shared/components/PlannerIcon'
import {PlannerFeature, PriceLevel} from '../../../../../../shared/enums'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {Comment, ScheduleItem} from '../../../../../../shared/interfaces/api'
import {AssetWithOperationMode} from '../../../../../../shared/selectors/assets'
import {usePriceColors} from '../../../../../../shared/usePriceColors'
import {usePlannerFeature} from '../../../../../../shared/hooks/usePlannerFeature'
import {AomAssetIcons} from '../../../AomAssetIcons'
import {ScheduleItemUpdatedByInfo} from '../editDialog/ScheduleItemUpdatedByInfo'

import {FixedForOptimizerRow} from './FixedForOptimizerRow'
import {OperationTimeStartEndRow} from './OperationTimeStartEndRow'
import {SelectedSilosRow} from './SelectedSilosRow'
import {SettingsInputComponent} from '@mui/icons-material'

const getCommentUpdatedCreatedInfo = (comment: Comment, timezoneId: string, language: string) =>
  `${comment.updatedBy || comment.createdBy}, ${formatTimeZoneDate(
    comment.updatedOn || comment.createdOn,
    timezoneId,
    'DD MMM LT',
    language
  )}`

export interface ScheduleItemPopperState {
  anchorEl: PopperProps['anchorEl']
  scheduleItem: ScheduleItem
  costPerMWh: number
  produced: number
  consumption: number | null
  priceLevel: PriceLevel
  comment?: Comment
}

interface ScheduleItemPopperContentProps {
  scheduleItemData: ScheduleItemPopperState
  asset: AssetSnapshot
  operationMode: AssetWithOperationMode['operationMode']
  shouldShowFixedForOptimizerInfo: boolean
  shouldShowColorCodedElectricityPrices: boolean
  shouldShowExactElectricityPrices: boolean
}
export const ScheduleItemPopperContent: React.FC<ScheduleItemPopperContentProps> = ({
  scheduleItemData,
  asset,
  operationMode,
  shouldShowFixedForOptimizerInfo,
  shouldShowColorCodedElectricityPrices,
  shouldShowExactElectricityPrices
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId, currency} = usePlantConfig()
  const {scheduleItem, produced, priceLevel, consumption, costPerMWh, comment} = scheduleItemData

  const isShutdownAvailabilityFeatureAvailable = usePlannerFeature(
    PlannerFeature.ShutdownAvailability
  )

  const shouldShowProduced =
    [AssetType.CementMill, AssetType.RawMill, AssetType.RotaryKiln].includes(asset.type) &&
    produced !== 0

  const shouldShowExactPrice = shouldShowExactElectricityPrices && consumption !== 0
  const shouldShowColorCodedPrice = shouldShowColorCodedElectricityPrices && consumption !== 0

  const shouldShowSelectedSilos = operationMode.type === OperationModeType.Production
  const shouldShowShutdownAvailability =
    isShutdownAvailabilityFeatureAvailable && operationMode.type !== OperationModeType.Maintenance

  const shouldShowFixedForOptimizer =
    operationMode.type !== OperationModeType.Maintenance &&
    shouldShowFixedForOptimizerInfo &&
    scheduleItem.isFixed

  const priceColors = usePriceColors()
  return (
    <CardBox {...dataTestId('schedule_item_popper_content')}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Box display="flex" alignItems="center">
            <AomAssetIcons
              assetType={asset.type}
              aomType={operationMode.type}
              isShutdownAvailable={scheduleItem.isShutdownAvailable}
              isTransition={scheduleItem.isTransitionTime}
              pl={0}
            />
            <Typography variant="h3" {...dataTestId('operation_mode_name')}>
              {operationMode.name}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <OperationTimeStartEndRow scheduleItem={scheduleItem} />
        </Grid>
        <Grid xs={12} item>
          <ScheduleItemUpdatedByInfo scheduleItem={scheduleItem} />
        </Grid>
        {/* Price per MWh */}
        {shouldShowExactPrice && (
          <Grid xs={6} item>
            <Box display="flex" flexDirection="column" {...dataTestId('operation_cost')}>
              <Typography variant="caption">{t('electricity.cost')}</Typography>
              <Typography
                variant="body1"
                sx={shouldShowColorCodedPrice ? {color: priceColors[priceLevel]} : undefined}
              >
                {`${t('common.amountInCurrencyPerMWh', {
                  amount: formatCurrency(costPerMWh, 2, language),
                  currency: currency
                })}`}
              </Typography>
            </Box>
          </Grid>
        )}
        {/* Produced by schedule item */}
        {shouldShowProduced && (
          <Grid xs={6} item>
            <Box display="flex" flexDirection="column" {...dataTestId('produced_by_schedule_item')}>
              <Typography variant="caption">{t('planning.production')}</Typography>
              <Typography variant="body1">{`+${formatTons(produced, language)}`}</Typography>
            </Box>
          </Grid>
        )}
        <Grid xs={12} item>
          <Box display="flex" alignItems="center" {...dataTestId('asset_name')}>
            <PlannerIcon
              icon={assetIconMap[asset.type]}
              sx={{
                pl: 0
              }}
            />
            <Typography variant="body1">{asset.name}</Typography>
          </Box>
        </Grid>
        {shouldShowShutdownAvailability && (
          <Grid xs={12} item>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              {...dataTestId('asset_shutdown_availability')}
            >
              <SettingsInputComponent />
              <Typography variant="body1">
                {t(
                  scheduleItem.isShutdownAvailable
                    ? 'planning.assetShutdownAvailable'
                    : 'planning.assetShutdownNotAvailable'
                )}
              </Typography>
            </Stack>
          </Grid>
        )}
        {/* Selected silos if production operation mode*/}
        {shouldShowSelectedSilos && (
          <Grid xs={12} item>
            <SelectedSilosRow
              selectedSilos={scheduleItem.selectedSilos}
              scheduleItemEnd={scheduleItem.end}
            />
          </Grid>
        )}
        {shouldShowFixedForOptimizer && (
          <Grid xs={12} item>
            <FixedForOptimizerRow />
          </Grid>
        )}

        {/* Showing comment data if comment exists */}
        {comment && (
          <Grid xs={12} item>
            <Box mb={2}>
              <Divider />
            </Box>
            <Box>
              <Markdown>{comment.value}</Markdown>
            </Box>
            <Typography variant="caption">
              {getCommentUpdatedCreatedInfo(comment, timezoneId, language)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardBox>
  )
}

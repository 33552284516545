import {dataTestId} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {Box, Button, ButtonProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface ConfirmDialogActionsProps {
  showCancelButton?: boolean
  onCancel?: () => void | Promise<void>
  mainAction: {
    color?: ButtonProps['color']
    text: string
    icon?: React.ReactNode
    onAction?: () => void | Promise<void>
  }
}

export const ConfirmDialogActions: React.FC<ConfirmDialogActionsProps> = ({
  showCancelButton = true,
  onCancel,
  mainAction: {color = 'primary', icon, text, onAction}
}) => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      {showCancelButton && (
        <Button
          color="secondary"
          {...dataTestId('confirm_dialog_cancel_button')}
          onClick={() => void onCancel?.()}
          startIcon={<Close />}
        >
          {t('common.cancel')}
        </Button>
      )}
      <Button
        variant="contained"
        {...dataTestId('confirm_dialog_main_action_button')}
        onClick={() => void onAction?.()}
        startIcon={icon}
        color={color}
        sx={{ml: 1.5}}
      >
        {text}
      </Button>
    </Box>
  )
}

import {
  ConfirmDialogActions,
  type ConfirmDialogActionsProps
} from '../components/dialogs/ConfirmDialogActions'
import {
  ConfirmDialogContent,
  type ConfirmDialogContentProps
} from '../components/dialogs/ConfirmDialogContent'
import {useDialog} from '../components/providers/DialogProvider'

export interface ConfirmDialogDetails extends ConfirmDialogActionsProps, ConfirmDialogContentProps {
  title: string
  subtitle?: string
  testId?: string
}

export const useConfirmDialog = () => {
  const {openDialog, closeDialog} = useDialog()

  const openConfirmDialog = ({
    title,
    subtitle,
    description,
    additionalContent,
    mainAction,
    onCancel,
    testId = 'confirm_dialog',
    showCancelButton
  }: ConfirmDialogDetails) => {
    openDialog({
      header: {title, subtitle},
      content: (
        <ConfirmDialogContent description={description} additionalContent={additionalContent} />
      ),
      actions: (
        <ConfirmDialogActions
          mainAction={{
            ...mainAction,
            onAction: () => {
              void mainAction.onAction?.()
              closeDialog()
            }
          }}
          onCancel={() => {
            void onCancel?.()
            closeDialog()
          }}
          showCancelButton={showCancelButton}
        />
      ),
      dataTestId: testId
    })
  }

  return {openDialog: openConfirmDialog, closeDialog}
}

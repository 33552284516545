import {dataTestId} from '@hconnect/uikit'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, Typography, styled} from '@mui/material'
import {BoxProps} from '@mui/system'
import React from 'react'

import {useChartStyles} from '../../hooks/useChartStyles'

const StyledLabel = styled(Typography)({
  lineHeight: '14px',
  display: 'block'
})

type ChartLabelProps = Pick<BoxProps, 'sx'> & {label: React.ReactNode; 'data-test-id'?: string}

export const ChartLabel: React.FC<ChartLabelProps> = ({sx, label, 'data-test-id': testId}) => {
  const {tickColor} = useChartStyles()
  const baseSx: typeof sx = {
    position: 'absolute',
    textAlign: 'center'
  }
  return (
    <Box sx={mergeSx(baseSx, sx)} {...dataTestId(testId ?? 'chart_label')}>
      <StyledLabel variant="caption" sx={{color: tickColor}}>
        {label}
      </StyledLabel>
    </Box>
  )
}

import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Dialog, DialogContent, DialogActions, Stack, Typography} from '@mui/material'
import React, {useState, useContext} from 'react'

interface DialogState {
  header: {title: string; subtitle?: string}
  content: React.ReactNode
  actions?: React.ReactNode
  dataTestId?: string
}

interface DialogProviderState {
  openDialog: (details: DialogState) => void
  closeDialog: () => void
}
const DialogContext = React.createContext<DialogProviderState | undefined>(undefined)

export const useDialog = (): DialogProviderState => {
  const context = useContext(DialogContext)
  if (!context)
    throw new Error(
      'Cannot use the dialog without having DialogProvider upper in the component tree'
    )
  return context
}

/**
 * This provider is intended to have a simple interface for having a pop up dialog that forces the user to confirm an action.
 */
export const DialogProvider = ({children}: {children: React.ReactNode}) => {
  const [dialogState, setDialogState] = useState<DialogState | null>(null)

  const closeDialog = () => setDialogState(null)

  return (
    <DialogContext.Provider value={{openDialog: setDialogState, closeDialog}}>
      <Dialog
        open={!!dialogState}
        maxWidth="xs"
        fullWidth
        aria-labelledby="dialog"
        {...dataTestId(dialogState?.dataTestId ?? 'dialog')}
      >
        {dialogState && (
          <Stack spacing={2} sx={{p: 3}}>
            <Stack sx={{p: 0}}>
              <CardTitle {...dataTestId('confirm_dialog_title')} variant="h3" mb={0}>
                {dialogState.header.title}
              </CardTitle>
              {dialogState.header.subtitle && (
                <Typography variant="caption">{dialogState.header.subtitle}</Typography>
              )}
            </Stack>
            <DialogContent sx={{p: 0}}>{dialogState.content}</DialogContent>
            {dialogState.actions && (
              <DialogActions sx={{p: 0}}>{dialogState.actions}</DialogActions>
            )}
          </Stack>
        )}
      </Dialog>
      {children}
    </DialogContext.Provider>
  )
}

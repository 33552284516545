import {dataTestId} from '@hconnect/uikit'
import {HPTextField} from '@hconnect/uikit/src/lib2'
import {Stack, Typography, MenuItem} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerForecastType} from '../../../shared/enums'

import {AutoFillButton} from './autoFill/AutoFillButton'
import {useSelectedForecastType} from './SelectedForecastTypeProvider'

export const ProductionSummaryCardHeader: React.FC = () => {
  const {t} = useTranslation()
  const {selectedForecastType, setSelectedForecastType} = useSelectedForecastType()
  return (
    <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between'}}>
      <Typography variant="h3">{t('planning.production')}</Typography>
      <Stack direction="row" alignItems="start">
        <AutoFillButton />
        <HPTextField
          label={t('planning.compareWithForecast')}
          value={selectedForecastType}
          onChange={(e) => setSelectedForecastType(e.target.value as PlannerForecastType)}
          select
          {...dataTestId('compare_with_forecast_select')}
        >
          {Object.values(PlannerForecastType).map((forecastType) => (
            <MenuItem
              key={forecastType}
              value={forecastType}
              {...dataTestId('compare_with_forecast_select_item')}
            >
              {t(`planning.forecastType.${forecastType}`)}
            </MenuItem>
          ))}
        </HPTextField>
      </Stack>
    </Stack>
  )
}

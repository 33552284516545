import {Material, MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Box, SvgIconProps, Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CementIcon} from '../../assets/CementIcon'
import {ClinkerIcon} from '../../assets/ClinkerIcon'
import {Legend, LegendItem} from '../../shared/components/Legend'
import {useHistoryStoragesWithMaterialQuery} from '../../shared/hooks/api'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {HCEMPalette} from '../../shared/pallette'

const iconStyle: SvgIconProps = {
  style: {
    fontSize: '12px',
    marginRight: '8px'
  }
}

interface SiloLevelsCardHeaderProps {
  material: Material
}
export const SiloLevelsCardHeader: React.FC<SiloLevelsCardHeaderProps> = ({material}) => {
  const {t} = useTranslation()
  const {spacing, palette} = useTheme()
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const {data: storagesWithMaterials} = useHistoryStoragesWithMaterialQuery({
    timeFrame: planTimeFrame
  })
  if (!storagesWithMaterials) {
    throw new Error('Storages should be loaded before rendering this component')
  }
  const storagesWithMaterial = storagesWithMaterials.filter(
    (storage) => storage.materialId === material.id
  )
  const siloNames = storagesWithMaterial.map(({name}) => name)

  const icon =
    material.type === MaterialType.Cement ? (
      <CementIcon {...iconStyle} />
    ) : (
      <ClinkerIcon {...iconStyle} />
    )

  return (
    <Box mb={3} display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          {icon}
          <Typography variant="h3">{material.name}</Typography>
        </Box>
        <Typography variant="body2" {...dataTestId('silo_levels_silos')}>
          {`${t('optimizer.silos')}: ${siloNames.join(', ')}`}
        </Typography>
      </Box>
      <Legend testId="silo_levels_legend">
        <LegendItem
          label={t('optimizer.lastSavedMillPlan')}
          indicator={{
            style: {
              backgroundColor: HCEMPalette.brightPink
            },
            variant: 'circle'
          }}
        />
        <LegendItem
          label={t('optimizer.optimizedMillPlan')}
          indicator={{
            style: {backgroundColor: palette.primary.light},
            variant: 'circle'
          }}
        />
        <LegendItem label={t('optimizer.minLevel')}>
          <svg width={spacing(4)} height="3px">
            <line
              x1={0}
              x2={30}
              strokeWidth={1}
              y1={1}
              y2={1}
              stroke={palette.error.light}
              strokeDasharray="8,5"
            />
          </svg>
        </LegendItem>
      </Legend>
    </Box>
  )
}

import {useQuery, UseQueryOptions} from '@tanstack/react-query'

import {queries} from '../../api/queries'
import {Queries, QueryReturn} from '../../api/queryType'

/**
 * Wrapper around useQuery from react-query.  Should be used as it allows a type-safe interface into the available queries that we have defined as well as provides a consistent interface.
 * @param queryKey
 * @param params
 * @param options
 */
export const usePlannerQuery = <K extends keyof Queries, TReturn = QueryReturn<K>>(
  queryKey: K,
  params: Parameters<Queries[K]>[0]['params'],
  options?: Omit<UseQueryOptions<QueryReturn<K>, unknown, TReturn>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<QueryReturn<K>, unknown, TReturn>({
    queryKey: [queryKey, ...params],
    queryFn: async ({signal}) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queries[queryKey]({signal, params}) as Promise<QueryReturn<K>>
    },
    // by default we want to use the error boundary
    useErrorBoundary: true,
    ...options,
    // preventing disabled queries from firing during invalidation by default
    ...(options?.enabled !== false ? {} : {cacheTime: 0})
  })
}

import {Material} from '@hconnect/common/types'
import {dataTestId, formatTons} from '@hconnect/uikit'
import {Column} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Typography, Stack, Tooltip} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialTypeIcon} from '../../../shared/components/MaterialTypeIcon'
import {PlannerDataTable} from '../../../shared/components/PlannerDataTable'
import {PlannerForecastType} from '../../../shared/enums'
import {calculateDeltaPercentage} from '../../../shared/helpers/utils'
import {ProducedAndForecastedMaterials} from '../../../shared/hooks/api/producedAndForecastedMaterials/useProducedAndForecastedMaterials'

import {ColorCodedPercentChip} from './ColorCodedPercentChip'
import {useSelectedForecastType} from './SelectedForecastTypeProvider'

interface ProductionSummaryDataEntry {
  materialId: string
  uploadedForecast?: number
  demandForecast?: number
  produced?: number
}

interface ProductionSummaryTableProps {
  data: ProducedAndForecastedMaterials
  materialsById: Record<string, Material> | undefined
}

const MaterialDoesNotHaveForecast = () => {
  const {t} = useTranslation()

  return (
    <Tooltip
      title={<Typography variant="body1">{t('planning.materialDoesNotHaveForecast')}</Typography>}
    >
      <InfoOutlined
        sx={{ml: 0.5, fontSize: 'inherit'}}
        {...dataTestId('material_does_not_have_forecast_icon')}
      />
    </Tooltip>
  )
}

export const ProductionSummaryTable: React.FC<ProductionSummaryTableProps> = ({
  data,
  materialsById
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {selectedForecastType} = useSelectedForecastType()

  const getValue = useCallback(
    (row: ProductionSummaryDataEntry) => {
      if (selectedForecastType === PlannerForecastType.StatisticalForecast) {
        return row.demandForecast
      }
      if (selectedForecastType === PlannerForecastType.UploadedForecast) {
        return row.uploadedForecast
      }
      throw new Error(`Unknown forecast type ${selectedForecastType}`)
    },
    [selectedForecastType]
  )

  const columns: Column<ProductionSummaryDataEntry>[] = useMemo(
    () => [
      {
        key: 'material',
        label: t('common.material'),
        customTemplate: ({materialId}) => (
          <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            {materialsById?.[materialId] ? (
              <>
                <MaterialTypeIcon materialType={materialsById[materialId].type} />
                <Typography variant="subtitle1">{materialsById[materialId].name}</Typography>
              </>
            ) : (
              <Typography variant="subtitle1">{t('common.loading')}</Typography>
            )}
          </Stack>
        )
      },
      {
        key: 'forecasted',
        label: t('common.forecast'),
        customTemplate: (row) => {
          const value = getValue(row)
          if (value === undefined) return <MaterialDoesNotHaveForecast />

          return <Typography variant="body2">{formatTons(value, language)}</Typography>
        }
      },
      {
        key: 'planned',
        label: t('planning.scheduledInPlanner'),
        customTemplate: ({produced}) => {
          if (produced === undefined) return '-'
          return <Typography variant="body2">{formatTons(produced, language)}</Typography>
        }
      },
      {
        key: 'delta',
        align: 'center',
        label: t('planning.delta2'),
        customTemplate: (row) => {
          const {produced} = row

          const value = getValue(row)
          if (value === undefined || produced === undefined) return '-'

          const delta = calculateDeltaPercentage(value, produced)
          return <ColorCodedPercentChip value={delta} />
        }
      }
    ],
    [materialsById, t, language, getValue]
  )

  const tableData = useMemo<ProductionSummaryDataEntry[]>(() => {
    const rows = Object.entries(data).map(([materialId, entry]) => ({
      ...entry,
      materialId
    }))
    rows.sort((a, b) => {
      /// sort by whether forecast is available and then material name
      if (getValue(a) === undefined && getValue(b) !== undefined) return 1
      if (getValue(a) !== undefined && getValue(b) === undefined) return -1

      return (
        materialsById?.[a.materialId]?.name.localeCompare(materialsById[b.materialId]?.name) ?? 0
      )
    })
    return rows
  }, [data, getValue, materialsById])

  const keyExtractor = useCallback(({materialId}: ProductionSummaryDataEntry) => materialId, [])

  return (
    <PlannerDataTable
      columns={columns}
      data={tableData}
      emptyMessage={t('common.noData')}
      keyExtractor={keyExtractor}
      {...dataTestId('production_summary_table')}
    />
  )
}

import {HistoryStorageWithMaterial, Recipe, OperationModeSnapshot} from '@hconnect/common/types'
import moment from 'moment-timezone'

import {ScheduleItem, ScheduleItemDict, SelectedSilos} from './interfaces/api'
import {getStoragesWithMaterialForOperationMode} from './selectors/storages'

export const iso8601ToNumber = (iso: string): number => {
  const unixMilliTimeStamp = moment.utc(iso).format('x')
  return parseInt(unixMilliTimeStamp)
}

export const doesOverlappingItemExist = (
  scheduleItemDict: ScheduleItemDict,
  scheduleItem: Pick<ScheduleItem, 'assetId' | 'start' | 'end'>,
  id?: string
): boolean => {
  const scheduleItems = Object.values(scheduleItemDict)
  const {assetId, start, end} = scheduleItem

  return !!scheduleItems
    .filter(
      ({id: compareId, assetId: compareAssetId}) => compareId !== id && compareAssetId === assetId
    )
    .find(
      ({start: compareStart, end: compareEnd}) =>
        Math.max(iso8601ToNumber(compareStart), iso8601ToNumber(start)) <
        Math.min(iso8601ToNumber(compareEnd), iso8601ToNumber(end))
    )
}

/**
 * function for geting default selected storages if operation mode changes since BE doesn't track operation mode change to selected silo relation
 */
export const getDefaultSelectedSilos = (
  operationMode: OperationModeSnapshot,
  recipesById: Record<string, Recipe>,
  storagesWithMaterial: HistoryStorageWithMaterial[]
): SelectedSilos => {
  const availableStorages = getStoragesWithMaterialForOperationMode(
    recipesById,
    storagesWithMaterial,
    operationMode
  )
  const availableStoragesIds = availableStorages.map(({id}) => id)
  return {isOrderSpecified: false, ids: availableStoragesIds}
}

import {dataTestId} from '@hconnect/uikit/src/common'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {usePlanningChartStartEnd} from '../../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {PriceLevel} from '../../../enums'
import {toPixel} from '../../../helpers/utils'
import {PricePerHour} from '../../../interfaces/common'
import {usePriceColors} from '../../../usePriceColors'

import {ActualPricesIndicator} from './ActualPricesIndicator'
import {ElectricityPriceTopBarColoredBackground} from './ElectricityPriceTopBarColoredBackground'
import {ElectricityPriceTopBarNumbers} from './ElectricityPriceTopBarNumbers'

export interface ElectricityPriceTopBarProps {
  hoursList: Moment[]
  hourlyPriceHeight: number
  cellWidth: number
  pricePerHour: PricePerHour | undefined
  hoursDisplayed: number
  isTextVisible: boolean
  shouldShowActualPricesIndicator: boolean
  shouldShowExactPrices: boolean
  shouldShowColorCodedPrices: boolean
}

export const _ElectricityPriceTopBar: React.FC<ElectricityPriceTopBarProps> = ({
  hourlyPriceHeight,
  cellWidth,
  pricePerHour,
  isTextVisible,
  shouldShowExactPrices,
  shouldShowActualPricesIndicator,
  shouldShowColorCodedPrices
}) => {
  const {hoursList, visibleHoursList} = usePlanningChartStartEnd()
  const priceColor = usePriceColors()[PriceLevel.unknown]
  return (
    <Box
      sx={{
        display: 'block',
        height: toPixel(hourlyPriceHeight),
        backgroundColor: priceColor
      }}
      {...dataTestId('electricity-price-top-bar')}
    >
      {shouldShowActualPricesIndicator && (
        <ActualPricesIndicator
          cellWidth={cellWidth}
          hoursList={hoursList}
          testId="actual-prices-indicator-header-row"
        />
      )}
      {shouldShowExactPrices && (
        <ElectricityPriceTopBarNumbers
          hoursList={hoursList}
          hourlyPriceHeight={hourlyPriceHeight}
          cellWidth={cellWidth}
          visibleHours={visibleHoursList}
          isTextVisible={isTextVisible}
          pricePerHour={pricePerHour}
        />
      )}
      {shouldShowColorCodedPrices && (
        <ElectricityPriceTopBarColoredBackground
          hoursList={visibleHoursList}
          hourlyPriceHeight={hourlyPriceHeight}
          cellWidth={cellWidth}
          pricePerHour={pricePerHour}
        />
      )}
    </Box>
  )
}

export const ElectricityPriceTopBar = React.memo(_ElectricityPriceTopBar)

export enum PlannerRole {
  ManagingDirector = 'MANAGING_DIRECTOR',
  PlantDirector = 'PLANT_DIRECTOR',
  ProductionSupervisor = 'PRODUCTION_SUPERVISOR',
  EnergyManager = 'ENERGY_MANAGER',
  ControlRoomOperator = 'CONTROL_ROOM_OPERATOR'
}

export enum PlannerFeature {
  Stock = 'stock_screen',
  PriceForecast = 'price_forecast_screen',
  DemandForecast = 'demand_forecast_screen',
  Planning = 'planning_screen',
  ElectricityPurchasing = 'electricity_purchasing',
  ControlOperatorScreen = 'control_room_operator_view',
  PlanningComments = 'planning_comments',
  OperatorViewExactElectricityPrices = 'operator_view_exact_electricity_prices',
  OperatorViewColorCodedElectricityPrices = 'operator_view_color_coded_electricity_prices',
  ShutdownAvailability = 'shutdown_availability',
  CostAvoidance = 'cost_avoidance',
  RawMaterialsPlanning = 'raw_materials_planning',
  ThreeShippingTypes = 'three_shipping_types'
}

// trackable feature for analytics team
export enum PlannerTrackedFeatures {
  RawMaterialsPlanning = 'RMP',
  ProductionPlanning = 'ProductionPlanning',
  Optimizer = 'Optimizer',
  GuidedTour = 'GuidedTour'
}

export enum PriceLevel {
  veryLow = 'veryLow',
  low = 'low',
  mid = 'mid',
  high = 'high',
  veryHigh = 'veryHigh',
  unknown = 'unknown'
}

export enum CommentsCategory {
  Assets = 'Assets',
  AssetOperationTimes = 'AssetOperationTimes',
  MaterialOrders = 'MaterialOrders'
}

export enum ScheduleStatus {
  PENDING = 'pending'
}

export enum CommentStatus {
  NEW = 'New',
  EDITED = 'Edited'
}

export enum RecipeComponentType {
  Input = 'Input',
  Output = 'Output'
}

export enum MaterialSource {
  ProducedInPlant = 'ProducedInPlant',
  BoughtFromVendor = 'BoughtFromVendor'
}

export enum PlannerLSSettingsKeys {
  PlanningSliderSettings = 'planning_slider_settings',
  PlanningSiloSectionSettings = 'planning_silo_section_settings',
  PlanningElectricityChartSettings = 'planning_electricity_chart_settings',
  PlanningCommentsSidebarSettings = 'planning_comments_sidebar_settings',
  PlanningChartCardSettings = 'planning_chart_card_settings',
  PlanningKPITableSettings = 'planning_kpi_table_settings',
  DemandSliderSettings = 'demand_slider_settings',
  ControlOperatorSliderSettings = 'control_operator_slider_settings',
  ControlOperatorElectricityChartSettings = 'control_operator_electricity_chart_settings',
  ControlOperatorCommentsSidebarSettings = 'control_operator_comments_sidebar_settings',
  ControlOperatorChartCardSettings = 'control_operator_chart_card_settings',
  OptimizerSliderSettings = 'optimizer_slider_settings',
  ThemeMode = 'theme_mode'
}

export enum ReachVariant {
  ok = 'ok',
  info = 'info',
  warning = 'warning',
  other = 'other'
}

export enum AssetLinkType {
  Asset = 'Asset',
  Conveyor = 'Conveyor',
  Silo = 'Silo'
}

export enum LinkDirection {
  To = 'to',
  From = 'from'
}

export enum OptimizerCalculationStatus {
  NotStarted = 'not_started',
  Finished = 'finished',
  Failed = 'failed',
  InProgress = 'in_progress',
  NotAvailable = 'not_available'
}

export enum OptimizerCalculationAction {
  Start = 'start',
  Cancel = 'cancel'
}

export enum MaterialOrderStatus {
  Created = 'Created',
  Edited = 'Edited',
  Cancelled = 'Cancelled'
}

export enum IncomingMaterialStatus {
  Overdue = 'Overdue',
  Delivered = 'Delivered',
  Unknown = 'Unknown',
  Planned = 'Planned',
  Cancelled = 'Cancelled'
}

export enum DeliveryStatus {
  Delivered = 'Delivered',
  Overdue = 'Overdue',
  Unknown = 'Unknown'
}

export enum PlanningScope {
  Daily = 'daily',
  Monthly = 'monthly'
}

export enum ShippingType {
  Train = 'train',
  Ship = 'ship',
  Truck = 'truck'
}
export enum AssetOperationTimeSource {
  Manual = 'Manual',
  StandardOperationTimes = 'StandardOperationTimes',
  Optimizer = 'Optimizer'
}

export enum AutofillStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Unknown = 'unknown',
  NotAvailable = 'not_available'
}

export enum PlannerForecastType {
  UploadedForecast = 'uploaded_forecast',
  StatisticalForecast = 'ds_forecast'
}

// used only on FE
export enum ForecastUpdatedVia {
  User = 'user',
  Mail = 'mail'
}

import {type Palette, useTheme, alpha} from '@mui/material'
import {useMemo} from 'react'

interface DataGridColors {
  gridBgFuture: string
  gridDayStart: string
  gridLineY: string
  gridLineX: string
  gridBgPast: string
}

const getDataGridColors = ({grey, mode}: Palette) => {
  const isLightMode = mode === 'light'
  const lightColors: DataGridColors = {
    gridBgFuture: grey.A200,
    gridDayStart: grey[600],
    gridLineY: grey[300],
    gridLineX: grey[400],
    gridBgPast: grey[50]
  }
  const darkColors: DataGridColors = {
    gridBgFuture: grey[900],
    gridDayStart: grey[300],
    gridLineY: grey[700],
    gridLineX: grey[700],
    gridBgPast: alpha(grey[800], 0.3)
  }
  return isLightMode ? lightColors : darkColors
}

export const useDataGridColors = () => {
  const {palette} = useTheme()
  return useMemo(() => getDataGridColors(palette), [palette])
}

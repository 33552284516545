import React from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {useChartStyles} from '../../hooks/useChartStyles'
import {useCurrentTime} from '../../hooks/useCurrentTime'

import {ChartLine} from './ChartLine'

interface ChartNowLineProps {
  xScale: TimeScaleFn
  // if yScale is not defined, then minY and maxY means y coordinates
  // if yScale is defined, then minY and maxY are values and coordinated are calculated via yScale(VALUE)
  yScale?: LinearScaleFn
  timezoneId: string
  maxY: number
  minY: number
  testId?: string
}

export const ChartNowLine: React.FC<ChartNowLineProps> = ({
  xScale,
  yScale,
  timezoneId,
  maxY,
  minY,
  testId = 'now_line'
}) => {
  const currentTime = useCurrentTime({timezoneId})
  const {nowLineColor} = useChartStyles()
  return (
    <ChartLine
      variant="vertical"
      xScale={xScale}
      yScale={yScale}
      yDomain={[minY, maxY]}
      xValue={currentTime}
      testId={testId}
      overrideLineAttributes={{stroke: nowLineColor}}
    />
  )
}

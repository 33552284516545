import {hpTheme} from '@hconnect/uikit/src/lib2'
import {useTheme} from '@mui/material'

import {PriceLevel} from './enums'
import {HCEMPalette} from './pallette'

export const usePriceColors = (): Record<PriceLevel, string> => {
  const {palette} = useTheme()
  const isLightMode = palette.mode === 'light'

  return {
    veryLow: HCEMPalette.evergreen,
    low: HCEMPalette.swamp,
    mid: HCEMPalette.dirtyHoney,
    high: HCEMPalette.pumpkin,
    veryHigh: HCEMPalette.halloween,
    unknown: isLightMode ? hpTheme.palette.grey[500] : hpTheme.palette.grey[800]
  }
}

import {useNotification} from '@hconnect/uikit/src/common'
import {MutateOptions, useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useMemo} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {notifyIfErrorMessage} from '../../../common'
import {PlannerQueryClient} from '../../../components/providers/QueryProvider'
import {CommentsCategory} from '../../../enums'
import {useUrlParam} from '../../../hooks/useUrlParam'
import {ErrorResponse, Comment} from '../../../interfaces/api'

export const useAddMaterialOrderComment = () => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const {t} = useTranslation()
  const raiseError = useErrorHandler()
  const {mutate, isLoading} = useMutation<
    Comment,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addComment>[0]
  >(mutations.addComment, {
    mutationKey: ['addMaterialOrderComment'],
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.addedMaterialOrderComment'))
    },
    onSettled: () => {
      void PlannerQueryClient.invalidateQueries([
        'comments',
        {commentsCategory: CommentsCategory.MaterialOrders}
      ])
    }
  })
  const addMaterialOrderComment = useMemo(() => {
    return (
      params: {comment: string; orderId: number},
      options?: MutateOptions<
        Comment,
        AxiosError<ErrorResponse>,
        Parameters<typeof mutations.addComment>[0]
      >
    ) =>
      mutate(
        {
          commentsCategoryInstanceId: String(params.orderId),
          commentDto: {value: params.comment},
          commentsCategory: CommentsCategory.MaterialOrders,
          plantCode
        },
        options
      )
  }, [mutate, plantCode])

  return {
    addMaterialOrderComment,
    isAddCommentLoading: isLoading
  }
}

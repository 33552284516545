import {dataTestId} from '@hconnect/uikit'
import {Box} from '@mui/system'
import {Moment} from 'moment-timezone'
import React, {SVGAttributes} from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {useChartStyles} from '../../hooks/useChartStyles'

type ChartLineProps = {
  xScale: TimeScaleFn
  // if yScale is not defined, then y values are coordinates
  // if yScale is defined, then y values are values and coordinated are calculated via yScale(VALUE)
  yScale?: LinearScaleFn
  testId?: string
  overrideLineAttributes?: Pick<
    SVGAttributes<SVGLineElement>,
    'stroke' | 'strokeDasharray' | 'strokeWidth' | 'opacity'
  >
} & (
  | {
      variant: 'horizontal'
      xDomain: [min: Moment, max: Moment]
      yDomain?: undefined
      xValue?: undefined
      yValue: number
    }
  | {
      variant: 'vertical'
      xDomain?: undefined
      yDomain: [min: number, max: number]
      xValue: Moment
      yValue?: undefined
    }
)

export const ChartLine: React.FC<ChartLineProps> = ({
  variant,
  xScale,
  yScale,
  xDomain,
  xValue,
  yDomain,
  yValue,
  testId = 'chart_line',
  overrideLineAttributes
}) => {
  const {gridLineColor} = useChartStyles()
  const {stroke, ...otherLineAttributes} = overrideLineAttributes || {}
  const getLineCoordinates = () =>
    variant === 'vertical'
      ? {
          x1: xScale(xValue),
          x2: xScale(xValue),
          y1: yScale ? yScale(yDomain[1]) : yDomain[1],
          y2: yScale ? yScale(yDomain[0]) : yDomain[0]
        }
      : {
          x1: xScale(xDomain[0]),
          x2: xScale(xDomain[1]),
          y1: yScale ? yScale(yValue) : yValue,
          y2: yScale ? yScale(yValue) : yValue
        }

  return (
    <Box
      component="line"
      sx={{
        stroke: stroke ?? gridLineColor,
        shapeRendering: 'geometricPrecision',
        strokeWidth: '1px'
      }}
      {...otherLineAttributes}
      {...getLineCoordinates()}
      {...dataTestId(testId)}
    />
  )
}

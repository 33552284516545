import {dataTestId} from '@hconnect/uikit'
import {ModeCommentOutlined, Close} from '@mui/icons-material'
import {Skeleton, Typography, Stack, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Comment} from '../../../../shared/interfaces/api'

interface CommentSidebarHeaderProps {
  comments: Comment[] | undefined
  onCloseSidebar: () => void
}
export const CommentSidebarHeader: React.FC<CommentSidebarHeaderProps> = ({
  comments,
  onCloseSidebar
}) => {
  const {t} = useTranslation()
  return (
    <Stack
      sx={{
        position: 'sticky',
        bgcolor: 'background.paper',
        clipPath: 'inset(0 0 -5px 0)',
        top: 0,
        zIndex: 1,
        p: 3
      }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      boxShadow={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <ModeCommentOutlined />
        <Typography variant="h3">
          {comments ? (
            t('planning.latestCommentsAmount', {count: comments.length})
          ) : (
            <Skeleton
              variant="text"
              sx={{
                width: ({spacing}) => spacing(25)
              }}
            />
          )}
        </Typography>
      </Stack>
      <IconButton
        onClick={onCloseSidebar}
        color="primary"
        {...dataTestId('close_comment_sidebar_button')}
      >
        <Close />
      </IconButton>
    </Stack>
  )
}

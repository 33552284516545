import {ThemeMode} from '@hconnect/uikit/src/lib2'
import React, {useContext, createContext, useCallback} from 'react'

import {PlannerLSSettingsKeys} from '../../enums'

interface SliderSettings {
  defaultRange: number
}
interface SiloSectionSettings {
  isSiloSectionOpen: boolean
}
interface ElectricityChartSettings {
  isElectricityChartOpen: boolean
}

interface CommentsSidebarSettings {
  isCommentSidebarOpen: boolean
}

interface CollapsibleCardSettings {
  isCardOpen: boolean
}

export type PlannerLocalStorageSettingsSchema = {
  [PlannerLSSettingsKeys.PlanningSliderSettings]: SliderSettings
  [PlannerLSSettingsKeys.PlanningSiloSectionSettings]: SiloSectionSettings
  [PlannerLSSettingsKeys.PlanningElectricityChartSettings]: ElectricityChartSettings
  [PlannerLSSettingsKeys.PlanningCommentsSidebarSettings]: CommentsSidebarSettings
  [PlannerLSSettingsKeys.PlanningChartCardSettings]: CollapsibleCardSettings
  [PlannerLSSettingsKeys.PlanningKPITableSettings]: CollapsibleCardSettings
  [PlannerLSSettingsKeys.DemandSliderSettings]: SliderSettings
  [PlannerLSSettingsKeys.ControlOperatorSliderSettings]: SliderSettings
  [PlannerLSSettingsKeys.ControlOperatorElectricityChartSettings]: ElectricityChartSettings
  [PlannerLSSettingsKeys.ControlOperatorCommentsSidebarSettings]: CommentsSidebarSettings
  [PlannerLSSettingsKeys.ControlOperatorChartCardSettings]: CollapsibleCardSettings
  [PlannerLSSettingsKeys.OptimizerSliderSettings]: SliderSettings
  [PlannerLSSettingsKeys.ThemeMode]: ThemeMode
}

interface PlannerLocalStorageSettingContextState {
  setSetting: <T extends keyof PlannerLocalStorageSettingsSchema>(
    key: T
  ) => (value: PlannerLocalStorageSettingsSchema[T]) => void
  getSetting: <T extends keyof PlannerLocalStorageSettingsSchema>(
    key: T
  ) => () => PlannerLocalStorageSettingsSchema[T] | undefined
}

const PlannerLocalStorageSettingsContext = createContext<
  PlannerLocalStorageSettingContextState | undefined
>(undefined)

export const usePlannerLocalStorageSettings = () => {
  const context = useContext(PlannerLocalStorageSettingsContext)
  if (!context) throw new Error('usePlannerLocalStorageSettings used outside of ContextProvider!')
  return context
}

export const LocalStorageSettingsProvider = ({
  children
}: {
  children: React.ReactNode
  localStorage?: Storage
}) => {
  const localStorage = window.localStorage
  const setSettingToLocalStorage = useCallback(
    (key: PlannerLSSettingsKeys, value: string) => {
      try {
        localStorage.setItem(key as string, value)
      } catch (error) {
        throw new Error('Cannot set item in local storage')
      }
    },
    [localStorage]
  )

  const getSettingFromLocalStorage = useCallback(
    (key: PlannerLSSettingsKeys) => {
      try {
        return localStorage.getItem(key as string)
      } catch (error) {
        throw new Error('cannot get item from local storage')
      }
    },
    [localStorage]
  )

  const getSetting = useCallback(
    <T extends keyof PlannerLocalStorageSettingsSchema>(key: T) =>
      (): PlannerLocalStorageSettingsSchema[T] | undefined => {
        const sliderSettingsRaw = getSettingFromLocalStorage(key)
        if (sliderSettingsRaw) {
          return JSON.parse(sliderSettingsRaw) as PlannerLocalStorageSettingsSchema[T]
        }
      },
    [getSettingFromLocalStorage]
  )

  const setSetting = useCallback(
    <T extends keyof PlannerLocalStorageSettingsSchema>(key: T) =>
      (value: PlannerLocalStorageSettingsSchema[T]) => {
        setSettingToLocalStorage(key, JSON.stringify(value))
      },
    [setSettingToLocalStorage]
  )

  return (
    <PlannerLocalStorageSettingsContext.Provider value={{setSetting, getSetting}}>
      {children}
    </PlannerLocalStorageSettingsContext.Provider>
  )
}

import {dataTestId} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {adjustEnLocale, getDayAndMonthWithTimezone} from '../../selectors/time'

import {ChartLabel} from './ChartLabel'
import {DatetimeValueChartYLabels} from './DatetimeValueChartYLabels'

type DatetimeValueChartBaseProps = {
  xSteps: Moment[]
  ySteps: number[]
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  hideYAxis?: boolean
  yTitle?: string
  yLabelWidth?: number
  yStepsFormatter?: (step: string | number, index: number) => string
  children?: React.ReactNode
}
export type DatetimeValueChartLabelsProps = DatetimeValueChartBaseProps &
  (
    | {timezoneId: string; overrideXLabel?: undefined}
    | {timezoneId?: undefined; overrideXLabel: (step: Moment) => JSX.Element | undefined}
  )

export const DatetimeValueChartLabels = ({
  xSteps,
  ySteps,
  xScale,
  hideYAxis,
  yScale,
  yTitle,
  yLabelWidth,
  timezoneId,
  yStepsFormatter,
  overrideXLabel,
  children
}: DatetimeValueChartLabelsProps) => {
  const {
    i18n: {language}
  } = useTranslation()
  const paddingLeft = 50
  const paddingBottom = 24
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${paddingLeft}px 1fr`,
        gap: 1
      }}
    >
      {!hideYAxis && (
        <DatetimeValueChartYLabels
          ySteps={ySteps}
          yScale={yScale}
          yTitle={yTitle}
          yStepsFormatter={yStepsFormatter}
          width={yLabelWidth}
        />
      )}
      <Box sx={{dispaly: 'flex', flexDirection: 'column'}}>
        {children}
        <Box sx={{position: 'relative', height: paddingBottom}} {...dataTestId('x_labels')}>
          {xSteps.map((datetime, index) => {
            const labelOverride = overrideXLabel?.(datetime)
            if (labelOverride) {
              return (
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                <React.Fragment key={`${datetime}_${index}_x_label`}>
                  {labelOverride}
                </React.Fragment>
              )
            }
            if (index === xSteps.length - 1) {
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              return <React.Fragment key={`${datetime}_${index}_x_label`} />
            }
            return (
              <ChartLabel
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                key={`${datetime}_${index}_x_label`}
                label={
                  timezoneId
                    ? getDayAndMonthWithTimezone(
                        datetime.clone().add(12, 'hours'),
                        timezoneId,
                        adjustEnLocale(language)
                      )
                    : ''
                }
                sx={{
                  left: xScale(datetime.clone().add(12, 'hours')),
                  transform: 'translateX(-50%)'
                }}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

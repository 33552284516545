import {Box, Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {dataTestId as spreadDataTestId, datetimeFormatter} from '../../../common'
import {HPAvatar} from '../avatar'

export interface HPCommentHeaderProps {
  userName: string
  commentDate: Moment
  defaultUserName?: string
  menuComponent?: React.ReactNode
}

export const HPCommentHeader: React.FC<HPCommentHeaderProps> = ({
  userName,
  commentDate,
  menuComponent
}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const formattedDate = datetimeFormatter(commentDate, language, commentDate.utcOffset())
  const bullet = '•'
  return (
    <Box
      sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
      {...spreadDataTestId('comment_item_header')}
    >
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <HPAvatar userName={userName} />
        <Typography variant="subtitle2">{userName}</Typography>
        <Typography variant="subtitle2">{bullet}</Typography>
        <Typography variant="caption">{formattedDate}</Typography>
      </Box>
      {menuComponent}
    </Box>
  )
}

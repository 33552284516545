import {AssetType, OperationModeType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {LockOutlined, BuildOutlined, PowerOutlined} from '@mui/icons-material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import {Stack, StackProps, SvgIconProps} from '@mui/material'
import React from 'react'

import {CementIcon} from '../../../assets/CementIcon'
import {ClinkerIcon} from '../../../assets/ClinkerIcon'
import {usePlannerFeature} from '../../../shared/hooks/usePlannerFeature'
import {PlannerFeature} from '../../../shared/enums'

const iconStyle: SvgIconProps = {
  style: {fontSize: '1rem'}
}

const AssetToIcon: Record<AssetType, JSX.Element> = {
  [AssetType.Crusher]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.Other]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.RotaryKiln]: <ClinkerIcon {...iconStyle} />,
  [AssetType.RawMill]: <CementIcon {...iconStyle} />,
  [AssetType.CementMill]: <CementIcon {...iconStyle} />,
  [AssetType.BaseLoad]: <PowerSettingsNewIcon {...iconStyle} />,
  [AssetType.CoalMill]: <CementIcon {...iconStyle} />
}

interface AomAssetIconProps extends StackProps {
  assetType: AssetType
  aomType: OperationModeType
  isTransition: boolean
  isShutdownAvailable: boolean
  isFixed?: boolean
}

const _AomAssetIcons = React.forwardRef<HTMLDivElement, AomAssetIconProps>(
  ({assetType, aomType, isTransition, isShutdownAvailable, isFixed = false, ...restProps}, ref) => {
    const isShutdownAvailabilityFeatureAvailable = usePlannerFeature(
      PlannerFeature.ShutdownAvailability
    )

    const isMaintenance = aomType === OperationModeType.Maintenance

    const shouldShowShutdownAvailability = isShutdownAvailabilityFeatureAvailable && !isMaintenance

    const hasShutdownAvailability = isShutdownAvailable && shouldShowShutdownAvailability

    const getIcons = () => {
      if (!isMaintenance) {
        const icons = [
          isFixed && <LockOutlined key="is_fixed_icon" {...dataTestId('is_fixed_icon')} />,
          hasShutdownAvailability && (
            <PowerOutlined key="connection_icon" {...dataTestId('connection_icon')} />
          )
        ].filter(Boolean)

        if (icons.length > 0) {
          return icons
        }
      }

      if (isTransition)
        return [
          <SwapHorizIcon
            key="is_transition_icon"
            sx={{fill: ({palette}) => palette.text.primary}}
          />
        ]

      if (isMaintenance)
        return [
          <BuildOutlined
            {...iconStyle}
            key="maintenance_icon"
            {...dataTestId('maintenance_icon')}
          />
        ]

      return [AssetToIcon[assetType]]
    }

    return (
      <Stack
        ref={ref}
        justifyContent="center"
        alignItems="center"
        sx={{p: 1}}
        direction="row"
        spacing={1}
        {...restProps}
      >
        {getIcons()}
      </Stack>
    )
  }
)

export const AomAssetIcons = React.memo(_AomAssetIcons)

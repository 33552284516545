import {alpha} from '@mui/material'

import {AVATAR_HEIGHT_PX, AVATAR_WIDTH_PX} from './avatar.constants'

export const getStringToColor = (string: string) => {
  let hash = 0
  let i: number

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export const getStringAvatar = (name: string) => {
  const color = getStringToColor(name)
  const unknownName = 'Unknown'
  const [firstName, lastName] = name.length
    ? (name.split(' ') as [string, string | undefined])
    : [unknownName]
  return {
    sx: {
      bgcolor: alpha(color, 0.1),
      width: `${AVATAR_WIDTH_PX}px`,
      height: `${AVATAR_HEIGHT_PX}px`,
      color: color,
      fontSize: 10
    },
    children: `${(firstName ?? unknownName)[0].toUpperCase()}${lastName ? lastName[0].toUpperCase() : ''}`
  }
}

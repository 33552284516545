import moment, {Moment} from 'moment-timezone'
import React, {useEffect} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'
import {
  getDateValue,
  DATE_FORMAT_FROM_INPUT,
  getDatePartsFromString,
  isDayValid,
  isMonthValid,
  isYearValid
} from '../../momentDatePicker/helpers'

import {InputStepper} from './InputStepper'

interface DateInputStepperProps {
  value: Moment | null
  validator?: (date: Moment) => boolean
  onChange: (date: Moment) => void
  timezoneId: string
  label: string
}

const parseValidDate = (date: string, timezoneId: string) => {
  const {day, month, year} = getDatePartsFromString(date)
  if (!isDayValid(day) || !isMonthValid(month) || !isYearValid(year)) {
    return undefined
  }
  const parsedDate = moment({
    year: parseInt(year, 10),
    month: parseInt(month) - 1,
    day: parseInt(day)
  }).tz(timezoneId)

  const isValid = parsedDate.isValid()
  return isValid ? parsedDate : undefined
}

export const DateInputStepper: React.FC<DateInputStepperProps> = ({
  value,
  onChange,
  label,
  timezoneId,
  validator
}) => {
  const formattedCurrentValue = value ? value.tz(timezoneId).format(DATE_FORMAT_FROM_INPUT) : ''
  const [inputValue, setInputValue] = React.useState<string>(formattedCurrentValue)

  const onDateChange = (inputValue: string) => {
    const value = getDateValue(inputValue)
    setInputValue(value)

    const validDate = parseValidDate(value, timezoneId)

    // if external validator available, validate first, then call external onChange
    if (validDate && ((validator && validator(validDate)) || !validator)) {
      return onChange(validDate)
    }
  }

  const onDecreaseDate = () => {
    if (value) {
      const newValue = value.clone().subtract(1, 'days')
      onChange(newValue)
      setInputValue(newValue.format(DATE_FORMAT_FROM_INPUT))
    }
  }
  const onIncreaseDate = () => {
    if (value) {
      const newValue = value.clone().add(1, 'days')
      onChange(newValue)
      setInputValue(newValue.format(DATE_FORMAT_FROM_INPUT))
    }
  }
  const isDecreaseDateDisabled = value
    ? !(validator?.(value.clone().subtract(1, 'days')) ?? true)
    : true
  const isIncreaseDateDisabled = value ? !(validator?.(value.clone().add(1, 'days')) ?? true) : true

  useEffect(() => {
    if (formattedCurrentValue) {
      setInputValue(formattedCurrentValue)
    }
  }, [formattedCurrentValue])

  return (
    <InputStepper
      value={inputValue}
      placeholder={DATE_FORMAT_FROM_INPUT}
      label={label}
      onChange={onDateChange}
      onDecrease={onDecreaseDate}
      onIncrease={onIncreaseDate}
      isIncreaseDisabled={isIncreaseDateDisabled}
      isDecreaseDisabled={isDecreaseDateDisabled}
      sx={{maxWidth: ({spacing}) => spacing(24)}}
      {...dataTestId('date_input_stepper')}
    />
  )
}

import {useCallback} from 'react'

import {MaterialDemandByDay} from '../../../interfaces/api'
import {getDemandDataPerMaterial} from '../../../selectors/materialDemand'
import {MomentRange, convertToDate} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialDemandPerMaterialQueryParams {
  timeFrame: MomentRange
  timezoneId: string
  selectedDate?: string
}

/**
 * Hook used for demand table, combines damand data for a range or single date
 **/

export const useMaterialDemandPerMaterialQuery = ({
  timeFrame,
  timezoneId,
  selectedDate
}: MaterialDemandPerMaterialQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const fromDate = convertToDate(timeFrame[0].clone().tz(timezoneId))
  const toDate = convertToDate(timeFrame[1].clone().tz(timezoneId))
  const selector = useCallback(
    (data: MaterialDemandByDay) => {
      // handling the case when selectedDate is not in the data
      const dataFilteredByDate = selectedDate ? {[selectedDate]: data[selectedDate] ?? []} : data
      return getDemandDataPerMaterial(dataFilteredByDate)
    },
    [selectedDate]
  )

  return usePlannerQuery('materialDemand', [plantCode, {fromDate, toDate}], {select: selector})
}

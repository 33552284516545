import {dataTestId} from '@hconnect/uikit'
import {ReactComponent as NoCommentsImage} from '@hconnect/uikit/src/lib2/components/comment/noComments.svg'
import {DeleteOutline} from '@mui/icons-material'
import {Stack, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfirmDialog} from '../../../../shared/hooks/useConfirmDialog'
import {useSelectedAssetId} from '../../../../shared/components/providers/SelectedAssetCommentProvider'
import {useSelectedScheduleItemId} from '../../../../shared/components/providers/SelectedScheduleItemProvider'
import {CommentsCategory} from '../../../../shared/enums'
import {useDeleteComment} from '../../../../shared/hooks/api/comments/useDeleteComment'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {Comment} from '../../../../shared/interfaces/api'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {PlanningCommentItem} from './PlanningCommentItem'

interface CommentSidebarProps {
  comments: Comment[]
  isReadOnly: boolean
}

export const CommentSidebarList: React.FC<CommentSidebarProps> = ({comments, isReadOnly}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
  const {openDialog} = useConfirmDialog()
  const {setScheduleItemId} = useSelectedScheduleItemId()
  const {setAssetId} = useSelectedAssetId()

  const {mutate: deleteComment} = useDeleteComment()

  const handleEdit = useCallback(
    ({commentsCategoryInstanceId, commentsCategory}: Comment) => {
      if (!schedule) {
        return undefined
      }
      const instanceId = commentsCategoryInstanceId
      switch (commentsCategory) {
        case CommentsCategory.Assets:
          return setAssetId(Number(instanceId))
        case CommentsCategory.AssetOperationTimes: {
          const {id} = schedule.schedules[instanceId]
          return setScheduleItemId(id)
        }
        default:
          throw new Error('Unsupported comment category')
      }
    },
    [schedule, setAssetId, setScheduleItemId]
  )

  const handleDelete = useCallback(
    ({commentsCategory, commentsCategoryInstanceId, commentId}: Comment) => {
      openDialog({
        title: t('planning.deleteComment'),
        description: t('planning.deleteCommentConfirmation'),
        mainAction: {
          color: 'error',
          text: t('common.delete'),
          icon: <DeleteOutline />,
          onAction: () =>
            deleteComment({
              plantCode,
              commentsCategory,
              commentsCategoryInstanceId,
              commentId
            })
        }
      })
    },
    [openDialog, t, deleteComment, plantCode]
  )

  const isEmpty = comments.length === 0

  return (
    <Stack
      sx={{
        flex: 1,
        ...(isEmpty && {
          justifyContent: 'center',
          alignItems: 'center'
        })
      }}
      spacing={3}
    >
      {isEmpty ? (
        <>
          <NoCommentsImage {...dataTestId('no_comments_image')} />
          <Typography
            variant="body1"
            sx={{textAlign: 'center'}}
            {...dataTestId('no_comments_info')}
          >
            {t('planning.noCommentsInfo')}
          </Typography>
        </>
      ) : (
        comments.map((comment) => (
          <PlanningCommentItem
            key={comment.commentId}
            handleEdit={isReadOnly ? undefined : handleEdit}
            handleDelete={isReadOnly ? undefined : handleDelete}
            comment={comment}
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
          />
        ))
      )}
    </Stack>
  )
}

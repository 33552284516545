import {HPThemeProvider, ThemeMode, ThemeSettingsProvider} from '@hconnect/uikit/src/lib2'
import {ReactNode} from 'react'

import {PlannerLSSettingsKeys} from '../../enums'
import {usePersistingSettingState} from '../../hooks/usePersistingSettingState'

/**
 * PlannerThemeProvider uses ThemeSettingsProvider and HPThemeProvider to provide the theme for the planner
 * saves the theme mode in local storage
 */
export const PlannerThemeProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [LSThemeMode, updateLSThemeMode] = usePersistingSettingState(
    PlannerLSSettingsKeys.ThemeMode,
    ThemeMode.Device
  )
  return (
    <ThemeSettingsProvider initialThemeMode={LSThemeMode} onThemeModeChange={updateLSThemeMode}>
      <HPThemeProvider>{children}</HPThemeProvider>
    </ThemeSettingsProvider>
  )
}

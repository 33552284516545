import {dataTestId} from '@hconnect/uikit'
import {formatTimeZoneDate} from '@hconnect/uikit/src/common'
import {HPTextField} from '@hconnect/uikit/src/lib2'
import {DeleteOutline} from '@mui/icons-material'
import {Box, IconButton, Tooltip, Typography, InputBaseComponentProps} from '@mui/material'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Comment} from '../../../../shared/interfaces/api'

interface CommentInputProps {
  inputProps: InputBaseComponentProps
  onDelete?: () => void
  isDeleteButtonDisabled?: boolean
  currentCommentValue: string
  timezoneId: string
  testId?: string
  comment?: Comment
  error?: string
  isCommentInputDisabled?: boolean
}

export const CommentInput: FC<CommentInputProps> = ({
  inputProps,
  onDelete,
  isDeleteButtonDisabled = false,
  timezoneId,
  testId = 'comment-input',
  error,
  comment,
  currentCommentValue,
  isCommentInputDisabled = false
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const inputLabel = comment
    ? `${comment.updatedBy || comment.createdBy}, ${formatTimeZoneDate(
        comment.updatedOn || comment.createdOn,
        timezoneId,
        'DD MMM LT',
        language
      )}`
    : t('planning.writeYourComment')

  return (
    <Box sx={{position: 'relative'}}>
      <HPTextField
        {...dataTestId(testId)}
        fullWidth
        multiline
        minRows={6}
        label={inputLabel}
        error={!!error}
        helperText={error}
        inputProps={inputProps}
        disabled={isCommentInputDisabled}
        sx={{
          '& .MuiInput-input': {
            pr: 3
          }
        }}
        InputProps={{
          sx: {alignItems: 'flex-start'},
          endAdornment: onDelete ? (
            <Tooltip
              title={<Typography variant="body2">{t('planning.deleteComment')}</Typography>}
              placement="top"
              enterTouchDelay={0}
            >
              <Box>
                <IconButton
                  sx={{
                    '& .MuiIconButton-root': {
                      border: 'none',
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none'
                      }
                    }
                  }}
                  disabled={!comment || !currentCommentValue || isDeleteButtonDisabled}
                  {...dataTestId('delete_comment_button')}
                  onClick={onDelete}
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
            </Tooltip>
          ) : undefined
        }}
      />
    </Box>
  )
}

import {NotificationProvider} from '@hconnect/uikit/src/common'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {CircularProgress, CssBaseline} from '@mui/material'
import React, {Suspense} from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useNavigate} from 'react-router'

import {App} from '../../../app/App'
import {PlannerErrorBoundary} from '../errorBoundary/PlannerErrorBoundary'
import {HCHelmet} from '../helmets/HCHelmet'
import {MaintenanceSwitch} from '../MaintenanceSwitch'

import {FullScreenProvider} from './FullscreenProvider'
import {LoginProvider} from './LoginProvider'
import {QueryProvider} from './QueryProvider'

export const Provider: React.FC = () => {
  const {palette} = customThemeConstants()
  const navigate = useNavigate()
  const refreshPage = () => navigate(0)

  return (
    <Suspense fallback={<CircularProgress />}>
      <CssBaseline />
      <PlannerErrorBoundary
        fallbackSx={{width: 'auto', maxWidth: ({spacing}) => spacing(60)}}
        fallbackWrapperSx={{
          height: '100vh',
          background: palette.backgroundGradient,
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onRetry={refreshPage}
        fallbackTestId="global_planner_error_fallback"
      >
        <HelmetProvider>
          <HCHelmet />
          <MaintenanceSwitch>
            <NotificationProvider>
              <QueryProvider>
                <FullScreenProvider>
                  <LoginProvider>
                    <App />
                  </LoginProvider>
                </FullScreenProvider>
              </QueryProvider>
            </NotificationProvider>
          </MaintenanceSwitch>
        </HelmetProvider>
      </PlannerErrorBoundary>
    </Suspense>
  )
}

import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {Queries} from '../../../api/queryType'
import {notifyIfErrorMessage} from '../../../common'
import {PlannerQueryClient} from '../../../components/providers/QueryProvider'
import {ErrorResponse} from '../../../interfaces/api'

export const useCreateStockLevelOverwrite = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.createStockLevelOverwrite>[0]
  >(mutations.createStockLevelOverwrite, {
    mutationKey: ['createStockLevelOverwrite'],
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.stockLevelOverwriteCreated'))
    },
    onSettled: (data, error, variables) => {
      void PlannerQueryClient.invalidateQueries({
        predicate: (query) => {
          // invalidating only related queries since stockDevelopment is a slow query
          return (
            query.queryKey[0] === 'stockDevelopment' &&
            (
              query.queryKey[1] as Parameters<Queries['stockDevelopment']>[0]['params'][0]
            ).materialIds.includes(variables.materialId)
          )
        }
      })
      invalidateHcemQuery('materialOrders')
      invalidateHcemQuery('stockOverwrites')
    }
  })
}

import type {OperationModeSnapshot} from '@hconnect/common/types'
import {useMemo} from 'react'

import {getStoragesWithMaterialForOperationMode} from '../../../selectors/storages'
import {MomentRange} from '../../../selectors/time'
import {useMaterialsRecipesById} from '../materials/useMaterialsRecipesById'
import {useHistoryStoragesWithMaterialQuery} from '../materialStorage'

export const useAvailableStoragesForOperationMode = (
  activeOperationMode: OperationModeSnapshot,
  timeFrame: MomentRange
) => {
  const {data: recipesById} = useMaterialsRecipesById()
  if (!recipesById) {
    throw new Error('BUG: recipesById query should be loaded before rendering this hook')
  }
  const {data: storagesWithMaterial} = useHistoryStoragesWithMaterialQuery({timeFrame})
  if (!storagesWithMaterial) {
    throw new Error('BUG: storagesWithMaterial query should be loaded before rendering this hook')
  }
  return useMemo(() => {
    const availableStorages = getStoragesWithMaterialForOperationMode(
      recipesById,
      storagesWithMaterial,
      activeOperationMode
    )
    return availableStorages
  }, [recipesById, activeOperationMode, storagesWithMaterial])
}

import {useTheme, alpha} from '@mui/material'
import {useMemo} from 'react'

interface ChartStyles {
  // the colors of ticks and text for axis
  tickColor: string
  // the color of grid lines
  gridLineColor: string

  gridLineBoldColor: string
  // the background color for weekends on time axis
  weekendBackground: string
  hoverBackground: string
  selectedBackground: string
  nowLineColor: string
}

export const useChartStyles = () => {
  const {palette} = useTheme()
  return useMemo<ChartStyles>(() => {
    const isLightMode = palette.mode === 'light'
    return {
      tickColor: palette.text.secondary,
      gridLineColor: alpha(palette.text.primary, 0.2),
      gridLineBoldColor: alpha(palette.text.primary, 0.5),
      weekendBackground: isLightMode ? palette.grey[200] : palette.grey[800],
      hoverBackground: isLightMode ? alpha(palette.grey[200], 0.5) : alpha(palette.grey[800], 0.3),
      selectedBackground: alpha(palette.primary.light, 0.2),
      nowLineColor: palette.text.primary
    }
  }, [palette])
}

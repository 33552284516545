import {Material} from '@hconnect/common/types'
import {Navigate, Outlet} from 'react-router-dom'

import {ROUTES, getUrl} from '../../../routing'
import {PlannerPageSkeleton} from '../../../shared/components/skeletons/PlannerPageSkeleton'
import {useMaterialsById} from '../../../shared/hooks/api'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

export const MaterialIdGuard: React.FC = () => {
  const materialId = useUrlParam('materialId')

  const {data: materialsById} = useMaterialsById()

  if (materialsById === undefined) {
    return <PlannerPageSkeleton />
  }

  const currentMaterial: Material | undefined = materialsById[materialId]

  if (currentMaterial === undefined) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  return <Outlet />
}

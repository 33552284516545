import {DataTable, DataTableProps} from '@hconnect/uikit/src/lib2'
import {Theme, alpha, SxProps} from '@mui/material'
import {useMemo} from 'react'

interface PlannerDataTableProps<T> extends Omit<DataTableProps<T>, 'rowSx'> {
  rowSx?: (row: T) => SxProps<Theme>
  isRowSelected?: (row: T) => boolean
}

/**
 * wrapper for DataTable component
 * allows for selecting rows and hover effect
 */

export const PlannerDataTable = <T,>({
  rowSx,
  isRowSelected,
  ...dataTableProps
}: PlannerDataTableProps<T>) => {
  const defaultRowSx: SxProps<Theme> = useMemo(
    () => ({
      '&:hover': {
        backgroundColor: ({palette}) => alpha(palette.primary.light, 0.03)
      }
    }),
    []
  )

  return (
    <DataTable
      {...dataTableProps}
      rowSx={(row) => ({
        ...defaultRowSx,
        ...(isRowSelected ? {cursor: 'pointer'} : {}),
        ...(isRowSelected?.(row)
          ? {backgroundColor: ({palette}) => alpha(palette.primary.light, 0.08)}
          : {}),
        ...(rowSx?.(row) ?? {})
      })}
    />
  )
}

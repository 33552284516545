import {dataTestId} from '@hconnect/uikit'
import {Box, Stack, Typography} from '@mui/material'
import React from 'react'

export interface ConfirmDialogContentProps {
  description?: string
  additionalContent?: React.ReactNode
}

export const ConfirmDialogContent: React.FC<ConfirmDialogContentProps> = ({
  description,
  additionalContent
}) => {
  return (
    <Stack spacing={2}>
      {description && (
        <Typography variant="body1" color="textPrimary" align="left">
          {description}
        </Typography>
      )}
      {additionalContent && (
        <Box {...dataTestId('confirm_dialog_additional_content')}>{additionalContent}</Box>
      )}
    </Stack>
  )
}

import {Stack} from '@mui/material'
import React from 'react'

import {dataTestId as spreadDataTestId} from '../../../common/utils/testingUtils'
import {AVATAR_WIDTH_PX} from '../avatar/avatar.constants'

import {HPCommentBody, type HPCommentBodyProps} from './HPCommentBody'
import {HPCommentHeader, type HPCommentHeaderProps} from './HPCommentHeader'

interface HPCommentItemProps
  extends HPCommentHeaderProps,
    Omit<HPCommentBodyProps, 'commentBodySx'> {
  ['data-test-id']?: string
}

export const HPCommentItem: React.FC<HPCommentItemProps> = ({
  userName,
  commentDate,
  title,
  content,
  menuComponent,
  defaultUserName,
  ['data-test-id']: dataTestId
}) => {
  return (
    <Stack spacing={1.5} {...spreadDataTestId(dataTestId ?? 'comment_item')}>
      <HPCommentHeader
        userName={!userName && defaultUserName ? defaultUserName : userName}
        commentDate={commentDate}
        menuComponent={menuComponent}
      />
      <HPCommentBody
        title={title}
        content={content}
        commentBodySx={{pl: `${AVATAR_WIDTH_PX + 8}px`}}
      />
    </Stack>
  )
}

import {LinearProgress, linearProgressClasses} from '@mui/material'
import React from 'react'

import {calcRelativeValueInPercent} from '../../../common'

interface BasicGaugeBarProps {
  value: number
  color: string
  bufferColor?: string
  minValue?: number
  maxValue?: number
  bufferValue?: number
  barHeight?: number
  'data-test-id'?: string
}

const BORDER_RADIUS = 0.25

export const BasicGaugeBar: React.FC<BasicGaugeBarProps> = ({
  value,
  color,
  minValue = 0,
  maxValue = 100,
  bufferValue,
  bufferColor,
  barHeight,
  'data-test-id': dataTestId = 'gauge-bar'
}) => (
  <LinearProgress
    sx={(theme) => ({
      height: barHeight || theme.spacing(2),
      borderRadius: BORDER_RADIUS,
      backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      ...(bufferValue
        ? {
            [`& .${linearProgressClasses.bar1Buffer}`]: {
              borderRadius: BORDER_RADIUS,
              backgroundColor: color
            },
            [`& .${linearProgressClasses.bar2Buffer}`]: {
              borderRadius: BORDER_RADIUS,
              backgroundColor: bufferColor || theme.palette.grey[400]
            },
            [`& .${linearProgressClasses.dashed}`]: {
              display: 'none'
            }
          }
        : {
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: BORDER_RADIUS,
              backgroundColor: color
            }
          })
    })}
    variant={bufferValue ? 'buffer' : 'determinate'}
    value={calcRelativeValueInPercent(value, minValue, maxValue)}
    valueBuffer={
      bufferValue ? calcRelativeValueInPercent(bufferValue, minValue, maxValue) : undefined
    }
    data-test-id={dataTestId}
  />
)

import {AssetType, OperationModeType, OperationModeSnapshot} from '@hconnect/common/types'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {styled, Skeleton, useTheme} from '@mui/material'
import {useIsMutating} from '@tanstack/react-query'
import * as d3 from 'd3'
import {debounce, throttle} from 'lodash'
import moment, {Moment} from 'moment-timezone'
import React, {useRef, useLayoutEffect, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {AmpelPriceLevels, getPriceLevel} from '../../../../../shared/ampel'
import {useSelectedScheduleItemId} from '../../../../../shared/components/providers/SelectedScheduleItemProvider'
import {LinearScaleFn, TimeScaleFn} from '../../../../../shared/helpers/scale'
import {toPixel} from '../../../../../shared/helpers/utils'
import {useCurrentTime} from '../../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import type {Comment, ScheduleItem as ScheduleItemType} from '../../../../../shared/interfaces/api'
import {DatetimeValue} from '../../../../../shared/interfaces/common'
import {getCostsMWhAndTons} from '../../../../../shared/scheduleKPIs'
import {guardProductionMode} from '../../../../../shared/selectors/assets'
import {getDateInsideFrame, isDateInsideFrame} from '../../../../../shared/selectors/time'
import {TimeLabel} from '../../../TimeLabel'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

import {ScheduleItemPopperState} from './popper/ScheduleItemPopperContent'
import {ScheduleItemBackground} from './ScheduleItemBackground'
import {ScheduleItemLabel} from './ScheduleItemLabel'
import {getScheduleItemPositionFn, ScheduleItemPosition} from './scheduleItemPosition'

const SCHEDULE_LABEL_BREAKPOINT_L = 120
const SCHEDULE_LABEL_BREAKPOINT_M = 70
const SCHEDULE_LABEL_BREAKPOINT_S = 30

const SCHEDULE_ITEM_RADIUS = 4

const StyledScheduleItemContainerDiv = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'isReadOnly' && propName !== 'isHistoricalScheduleItem'
})<{
  isReadOnly: boolean
  isHistoricalScheduleItem: boolean
}>(({theme, isReadOnly, isHistoricalScheduleItem}) => ({
  position: 'absolute',
  cursor: isReadOnly ? 'auto' : 'pointer',
  pointerEvents: 'auto',
  borderRadius: SCHEDULE_ITEM_RADIUS,
  whiteSpace: 'nowrap',
  border: '1px solid rgba(6, 50, 79, 0.3)',
  ...theme.typography.body1,
  transition: 'background-color ease-in-out 200ms',
  // Box shadow without overlapping
  '&::before': !isHistoricalScheduleItem
    ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        boxShadow: `0px 0px 1px rgba(0, 0, 0, 0.1), 0px ${theme.spacing(0.5)} ${theme.spacing(
          0.5
        )} rgba(0, 0, 0, 0.5);`
      }
    : undefined
}))

const StyledScheduleItemHandleDiv = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isReadOnly'
})<{isReadOnly: boolean}>(({isReadOnly}) => ({
  position: 'absolute',
  top: 0,
  height: '100%',
  zIndex: 1,
  cursor: 'ew-resize',
  display: isReadOnly ? 'none' : 'initial'
}))

export interface ScheduleItemProps {
  scheduleRowPadding: number
  scheduleRowHeight: number
  prices: DatetimeValue<Moment>[]
  priceLevels: AmpelPriceLevels
  timezoneId: string
  startOfPlan: Moment
  endOfPlan: Moment
  item: ScheduleItemType
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  comment?: Comment
  setScheduleItemPopperOpen: (params?: ScheduleItemPopperState) => void
  // Moved to upper component and passed as props because of the costs related getting these values/functions per each ScheduleItem
  isReadOnly?: boolean
  updateScheduleItem: (scheduleItem: ScheduleItemType) => void
  operationMode: OperationModeSnapshot
  assetType: AssetType
  rowIndex: number
  shouldShowStartEndTimeLabels: boolean
  enableColorCodedElectricityPrices: boolean
  enableExactElectricityPrices: boolean
}

function useGetFontColor(isMaintenance: boolean, isScheduledScheduleItem: boolean) {
  const {palette} = useTheme()
  if (isMaintenance) {
    return palette.background.paper
  }
  if (isScheduledScheduleItem) {
    return palette.text.secondary
  }
  return palette.text.primary
}

const _ScheduleItem: React.FC<ScheduleItemProps> = ({
  timezoneId,
  startOfPlan,
  endOfPlan,
  item,
  xScale,
  yScale,
  scheduleRowPadding,
  scheduleRowHeight: height,
  prices,
  priceLevels,
  comment,
  setScheduleItemPopperOpen,
  updateScheduleItem,
  assetType,
  operationMode,
  isReadOnly = false,
  rowIndex,
  shouldShowStartEndTimeLabels,
  enableColorCodedElectricityPrices,
  enableExactElectricityPrices
}) => {
  const currentTime = useCurrentTime({timezoneId})
  const currentTimeRounded = useMemo(() => roundTo15MinIntervalStart(currentTime), [currentTime])

  const {startOfChart, endOfChart} = usePlanningChartStartEnd()
  const {t} = useTranslation()
  const {currency} = usePlantConfig()
  // Check if current schedule item is mutating by mutationKey
  const mutating = useIsMutating({mutationKey: ['addScheduleItem']})
  const isItemLoading = Boolean(mutating)
  const shouldDisableItem = isReadOnly || isItemLoading

  const isScheduledScheduleItem = moment.utc(item.start).isBefore(currentTimeRounded)
  const isHistoricalScheduleItem = moment.utc(item.end).isSameOrBefore(currentTimeRounded)

  const shouldDisableStartChange = isScheduledScheduleItem
  const shouldDisableEndChange = isHistoricalScheduleItem

  const scheduleItemRef = useRef<HTMLHeadingElement>(null)
  const labelRef = useRef<HTMLHeadingElement>(null)
  const leftHandleRef = useRef<HTMLHeadingElement>(null)
  const rightHandleRef = useRef<HTMLHeadingElement>(null)

  const {setScheduleItemId} = useSelectedScheduleItemId()

  const dateOnPlanFrame = useMemo(
    () => getDateInsideFrame(startOfPlan, endOfPlan),
    [startOfPlan, endOfPlan]
  )
  const dateOnChartFrame = useMemo(
    () => getDateInsideFrame(startOfChart, endOfChart),
    [startOfChart, endOfChart]
  )
  const isDateInPlanFrame = useMemo(
    () => isDateInsideFrame(startOfPlan, endOfPlan),
    [startOfPlan, endOfPlan]
  )

  // smallest adjustable width in pixels
  const cell15minWidth = xScale(startOfPlan.clone().add(15, 'minutes')) - xScale(startOfPlan)

  const getItemPosition = useMemo(() => {
    return getScheduleItemPositionFn({
      cell15minWidth,
      dateOnPlanFrame,
      dateOnChartFrame,
      currentTimeRounded,
      xScale
    })
  }, [cell15minWidth, dateOnPlanFrame, dateOnChartFrame, xScale, currentTimeRounded])

  /**
   * We still use d3 for drag and drop handling, directly manipulating the DOM elements.
   * Reasoning: d3 uses some kind of normalized DnD abstraction; instead of attaching drag events to individual
   * elements, the drag handlers are attached to the window object and uses custom mousedown/mouseup events to mimic
   * dragging. This is superior to HTML5 draggable attribute; additionally when using React DnD we would have the issue
   * of re-rendering the whole chart during drag events (possible hundreds of events per second).
   *
   * Note that upon dragend events, we update our schedule and a re-rendering happens (only once).
   *
   * useLayoutEffect is called synchronously after render(); this is important as all the ref's will have been set and
   * put into our map at this point. We also use it to avoid flickering during the drawing, as our drawing operations
   * will block here (instead of in useEffect which runs async and wouldn't block but cause a lot of repaints)
   */
  useLayoutEffect(() => {
    const scheduleItemBar = scheduleItemRef.current
    const scheduleItemLabel = labelRef.current
    const leftHandle = leftHandleRef.current
    const rightHandle = rightHandleRef.current

    if (!scheduleItemBar || !scheduleItemLabel || !leftHandle || !rightHandle) {
      return
    }

    const scheduleItemD3 = d3.select<Element, unknown>(scheduleItemBar)
    const leftHandleD3 = d3.select<Element, unknown>(leftHandle)
    const rightHandleD3 = d3.select<Element, unknown>(rightHandle)
    const labelD3 = d3.select(scheduleItemLabel)
    // elements inside label
    const labelTextD3 = labelD3.select('#label_text')
    const assetIconD3 = labelD3.select('#label_icon')
    const notificationD3 = labelD3.select('#comment_notification')

    const throttledHideLabel = throttle(() => labelTextD3.style('display', 'none'))
    const throttledHideAssetIcon = throttle(() => assetIconD3.style('display', 'none'))
    const throttledShowLabel = throttle(() => labelTextD3.style('display', 'block'))
    const throttledShowAssetIcon = throttle(() => assetIconD3.style('display', 'flex'))
    const throttledHideCommentNotification = throttle(() => notificationD3.style('display', 'none'))
    const throttledShowCommentNotification = throttle(() =>
      notificationD3.style('display', 'inherit')
    )

    const drawScheduleItem = (position: ScheduleItemPosition): void => {
      const hasComment = Boolean(comment)

      scheduleItemD3
        .style('width', toPixel(position.itemWidth))
        .style('left', toPixel(xScale(position.start)))
      labelD3
        .style('width', toPixel(position.labelWidth))
        .style('left', toPixel(position.labelOffset))

      if (position.labelWidth < SCHEDULE_LABEL_BREAKPOINT_L) {
        throttledHideLabel()
      } else {
        throttledShowLabel()
      }

      if (position.labelWidth < SCHEDULE_LABEL_BREAKPOINT_M && hasComment) {
        throttledHideAssetIcon()
      } else {
        throttledShowAssetIcon()
      }

      if (position.labelWidth < SCHEDULE_LABEL_BREAKPOINT_S) {
        if (hasComment) {
          throttledHideCommentNotification()
        } else {
          throttledHideAssetIcon()
        }
      } else {
        if (hasComment) {
          throttledShowCommentNotification()
        } else {
          throttledShowAssetIcon()
        }
      }
    }

    const position = getItemPosition({
      start: item.start,
      end: item.end
    })
    drawScheduleItem(position)

    // disabling drag and drop handlers if chart is read only
    if (shouldDisableItem) {
      return
    }

    let startX = 0
    let scheduleItemStart = 0
    // Drag and drop handlers for the whole planning item to be moved left and right

    if (!(shouldDisableStartChange || shouldDisableEndChange)) {
      scheduleItemD3.call(
        d3
          .drag()
          .on('start', (event) => {
            event.sourceEvent.stopPropagation()
            startX = event.x
          })
          .on('drag', (event) => {
            event.sourceEvent.stopPropagation()
            const deltaX = event.x - startX

            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX
            })
            drawScheduleItem(position)
          })
          .on('end', (event) => {
            const deltaX = event.x - startX
            if (Math.abs(deltaX) < 3) {
              // do not stop propagation and let this be a click event
              return
            }
            event.sourceEvent.stopPropagation()

            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX
            })

            updateScheduleItem({
              ...item,
              start: position.start.toISOString(),
              end: position.end.toISOString()
            })
          })
      )
    }

    // draw a left selector on the planning item for resizing to the left
    if (!shouldDisableStartChange && isDateInPlanFrame(item.start)) {
      leftHandleD3.call(
        d3
          .drag()
          .on('start', (event) => {
            event.sourceEvent.stopPropagation()
            startX = event.x
            scheduleItemStart = scheduleItemBar.getBoundingClientRect().left
          })
          .on('drag', (event) => {
            event.sourceEvent.stopPropagation()
            const scheduleItemNewStart = leftHandle.getBoundingClientRect().left
            const newDifference = scheduleItemNewStart - scheduleItemStart
            const deltaX = event.x - startX + newDifference
            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX,
              fixed: 'end'
            })
            drawScheduleItem(position)
          })
          .on('end', (event) => {
            event.sourceEvent.stopPropagation()
            const scheduleItemNewStart = leftHandle.getBoundingClientRect().left
            const newDifference = scheduleItemNewStart - scheduleItemStart
            const deltaX = event.x - startX + newDifference

            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX,
              fixed: 'end'
            })

            updateScheduleItem({...item, start: position.start.toISOString()})
          })
      )
    }

    // draw a right selector on the planning item for resizing to the right
    if (
      !shouldDisableEndChange &&
      isDateInPlanFrame(item.end) &&
      !moment.utc(item.end).isSame(startOfPlan)
    ) {
      rightHandleD3.call(
        d3
          .drag()
          .on('start', (event) => {
            event.sourceEvent.stopPropagation()
            startX = event.x
          })
          .on('drag', (event) => {
            event.sourceEvent.stopPropagation()
            const deltaX = event.x - startX
            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX,
              fixed: 'start'
            })
            drawScheduleItem(position)
          })
          .on('end', (event) => {
            const deltaX = event.x - startX
            if (deltaX === 0) {
              // letting this be a click event
              return
            }
            event.sourceEvent.stopPropagation()
            const position = getItemPosition({
              start: item.start,
              end: item.end,
              deltaX,
              fixed: 'start'
            })

            updateScheduleItem({...item, end: position.end.toISOString()})
          })
      )
    }
  }, [
    shouldDisableEndChange,
    shouldDisableStartChange,
    updateScheduleItem,
    isDateInPlanFrame,
    dateOnChartFrame,
    getItemPosition,
    dateOnPlanFrame,
    cell15minWidth,
    startOfPlan,
    shouldDisableItem,
    endOfPlan,
    xScale,
    comment,
    item,
    t
  ])

  const {
    powerConsumption,
    throughput,
    type: operationModeType,
    name: operationModeName
  } = operationMode
  const isMaintenance: boolean = operationModeType === OperationModeType.Maintenance

  const scheduleItemStart = moment.utc(item.start).tz(timezoneId)
  const scheduleItemEnd = moment.utc(item.end).tz(timezoneId)

  const pricesForScheduleItem = useMemo(
    () =>
      prices.filter(
        (price) =>
          price.datetime.isSameOrAfter(scheduleItemStart) &&
          price.datetime.isSameOrBefore(scheduleItemEnd)
      ),
    [prices, scheduleItemStart, scheduleItemEnd]
  )

  const {
    cumulatedCosts: scheduleItemCosts,
    cumulatedMWh: scheduleItemMWh,
    cumulatedTons: scheduleItemTons
  } = useMemo(
    () =>
      guardProductionMode(operationMode)
        ? getCostsMWhAndTons({
            prices: pricesForScheduleItem,
            powerConsumption: operationMode.powerConsumption,
            throughput
          })
        : {cumulatedCosts: 0, cumulatedMWh: 0, cumulatedTons: 0},
    [operationMode, throughput, pricesForScheduleItem]
  )

  const shouldShowColorCodedPrice =
    enableColorCodedElectricityPrices && !isHistoricalScheduleItem && !isMaintenance

  const shouldShowExactPrice =
    enableExactElectricityPrices && !isHistoricalScheduleItem && !isMaintenance

  const costPerMWh = scheduleItemMWh !== 0 ? scheduleItemCosts / scheduleItemMWh : 0
  const priceLevel = getPriceLevel(costPerMWh, priceLevels)

  const showStartTime = shouldShowStartEndTimeLabels && scheduleItemStart.isAfter(startOfPlan)
  const showEndTime = shouldShowStartEndTimeLabels && scheduleItemEnd.isAfter(startOfPlan)

  const fontColor = useGetFontColor(isMaintenance, isHistoricalScheduleItem)

  const debouncedSetPopperOpen = useMemo(
    () => debounce((params?: ScheduleItemPopperState) => setScheduleItemPopperOpen(params), 1000),
    [setScheduleItemPopperOpen]
  )

  const onMouseOver = useCallback(() => {
    debouncedSetPopperOpen({
      anchorEl: labelRef.current,
      scheduleItem: item,
      comment,
      costPerMWh,
      produced: scheduleItemTons,
      consumption: powerConsumption,
      priceLevel
    })
  }, [
    debouncedSetPopperOpen,
    item,
    comment,
    costPerMWh,
    scheduleItemTons,
    priceLevel,
    powerConsumption
  ])

  const onMouseOut = useCallback(() => {
    debouncedSetPopperOpen.cancel()
    setScheduleItemPopperOpen(undefined)
  }, [setScheduleItemPopperOpen, debouncedSetPopperOpen])

  // attaching event listeners to schedule item for popper
  useLayoutEffect(() => {
    const scheduleItemBar = scheduleItemRef.current

    if (!scheduleItemBar) return

    scheduleItemBar.addEventListener('mouseover', onMouseOver)
    scheduleItemBar.addEventListener('mouseout', onMouseOut)

    return () => {
      scheduleItemBar.removeEventListener('mouseover', onMouseOver)
      scheduleItemBar.removeEventListener('mouseout', onMouseOut)
    }
  }, [scheduleItemRef, onMouseOver, onMouseOut])

  const scheduleItemTestId = `schedule-item-${item.id}`

  const handleScheduleItemClick = () => {
    if (!shouldDisableItem) {
      setScheduleItemId(item.id)
    }
  }

  return (
    <StyledScheduleItemContainerDiv
      isReadOnly={shouldDisableItem}
      isHistoricalScheduleItem={isHistoricalScheduleItem}
      ref={scheduleItemRef}
      style={{
        top: scheduleRowPadding + yScale(rowIndex) + 1,
        color: fontColor,
        height
      }}
      onClick={handleScheduleItemClick}
      {...dataTestId(scheduleItemTestId)}
    >
      {/* Left time label */}
      {showStartTime && (
        <div style={{position: 'absolute', left: -1, bottom: 15}}>
          <TimeLabel variant="start" formattedTime={scheduleItemStart.format('HH:mm')} />
        </div>
      )}
      {/* Left Handle */}
      <StyledScheduleItemHandleDiv
        isReadOnly={shouldDisableItem || shouldDisableStartChange}
        ref={leftHandleRef}
        style={{
          width: cell15minWidth,
          left: 0
        }}
      />
      {/* Label */}
      <ScheduleItemLabel
        ref={labelRef}
        currency={currency}
        operationModeType={operationModeType}
        operationModeName={operationModeName}
        shouldShowExactPrice={shouldShowExactPrice}
        costPerMWh={costPerMWh}
        priceLevel={priceLevel}
        item={item}
        comment={comment}
        assetType={assetType}
      />
      {/* Right time label */}
      {showEndTime && (
        <div style={{position: 'absolute', right: -1, top: 17}}>
          <TimeLabel variant="end" formattedTime={scheduleItemEnd.format('HH:mm')} />
        </div>
      )}

      {/* Right Handle */}
      <StyledScheduleItemHandleDiv
        isReadOnly={shouldDisableItem || shouldDisableEndChange}
        ref={rightHandleRef}
        style={{
          width: cell15minWidth,
          right: 0
        }}
      />
      {/* Skeleton loader */}
      {isItemLoading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: 1}}
        />
      )}
      <ScheduleItemBackground
        isMaintenance={isMaintenance}
        shouldDisplayColorCodedPrice={shouldShowColorCodedPrice}
        priceLevel={priceLevel}
      />
    </StyledScheduleItemContainerDiv>
  )
}
export const ScheduleItem = React.memo(_ScheduleItem)

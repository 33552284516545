import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import {Stack, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerSwitch} from '../../../../../../shared/components/formComponents/PlannerSwitch'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface ScheduleItemTransitionTimeSwitchProps {
  scheduleItem: ScheduleItem
  handleTransitionTimeChange: (isTransitionTime: boolean, shouldSplit: boolean) => void
}
export const ScheduleItemTransitionTimeSwitch: React.FC<ScheduleItemTransitionTimeSwitchProps> = ({
  scheduleItem,
  handleTransitionTimeChange
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})
  const isTransitionTimeChangeDisabled = moment.utc(scheduleItem.end).isBefore(nowRounded)
  const shouldDisplaySplitWarning = moment.utc(scheduleItem.start).isBefore(nowRounded)

  const onTransitionTimeChange = (isChecked: boolean) =>
    handleTransitionTimeChange(isChecked, shouldDisplaySplitWarning)

  return (
    <PlannerSwitch
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <SwapHorizIcon />
          <Typography>{t('planning.transitionTime')}</Typography>
        </Stack>
      }
      value={scheduleItem.isTransitionTime}
      onChange={onTransitionTimeChange}
      disabled={isTransitionTimeChangeDisabled}
      {...dataTestId('transition_time_toggle')}
    />
  )
}

import type {HistoryStorageWithMaterial, Recipe, AssetSnapshot} from '@hconnect/common/types'

import {getOptimizedMaterialIds} from '../selectors/materials'

import {filterMaterialsStoredInStorages} from './storages'

/**
 * function to calculate minimum target levels for multiple storages
 */
export const calculateMinTargetLevelForStorages = (storages: HistoryStorageWithMaterial[]) => {
  const minTargetLevels = {minTargetLevel: 0, minTargetLevelWeekend: 0}
  return storages.reduce((acc, storage) => {
    acc.minTargetLevel = acc.minTargetLevel + storage.minimumStockLevel
    acc.minTargetLevelWeekend = acc.minTargetLevelWeekend + storage.minimumWeekendStockLevel
    return acc
  }, minTargetLevels)
}
/**
 * function to calculate dead stock level for multiple storages
 */
export const calculateDeadStockLevelForStorages = (storages: HistoryStorageWithMaterial[]) => {
  return storages.reduce((deadStockLevel, storage) => {
    return deadStockLevel + storage.deadStock
  }, 0)
}

/**
 * function to calculate capacity for multiple storages
 */
export const calculateCapacityForStorages = (storages: HistoryStorageWithMaterial[]) => {
  return storages.reduce((acc, storage) => acc + storage.capacity, 0)
}

interface GetCementMaterialIdsWithStorageParams {
  assets: AssetSnapshot[]
  recipesById: Record<string, Recipe>
  storagesWithMaterial: HistoryStorageWithMaterial[]
}
/**
 * function to get cement ids which are present in storages
 */
export const getOptimizedMaterialIdsWithStorages = ({
  assets,
  recipesById,
  storagesWithMaterial
}: GetCementMaterialIdsWithStorageParams) => {
  const optimizedMaterialIds = getOptimizedMaterialIds({assets, recipesById})
  // filtering cements with storages
  return filterMaterialsStoredInStorages(storagesWithMaterial, optimizedMaterialIds)
}

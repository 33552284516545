import {Stack} from '@mui/material'
import {Moment} from 'moment'
import React from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {DateInputStepper} from './DateInputStepper'
import {TimeInputStepper} from './TimeInputStepper'

interface DateTimeStepperProps {
  dateLabel: string
  timeLabel: string
  value: Moment | null
  onChange: (date: Moment) => void
  timezoneId: string
  validator?: (date: Moment) => boolean
  timeRoundingFn?: (date: Moment) => Moment
  'data-test-id'?: string
}

export const DateTimeStepper: React.FC<DateTimeStepperProps> = ({
  dateLabel,
  timeLabel,
  value,
  onChange,
  timezoneId,
  validator,
  timeRoundingFn,
  ['data-test-id']: testId
}) => {
  return (
    <Stack direction="row" spacing={1.5} {...dataTestId(testId ?? 'date_time_stepper')}>
      <DateInputStepper
        label={dateLabel}
        value={value}
        onChange={onChange}
        timezoneId={timezoneId}
        validator={validator}
      />
      <TimeInputStepper
        label={timeLabel}
        value={value}
        roundingFn={timeRoundingFn}
        onChange={onChange}
        timezoneId={timezoneId}
        validator={validator}
      />
    </Stack>
  )
}

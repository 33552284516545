import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Box, useTheme} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback} from 'react'

import {GANTT_CHART_ROW_HEIGHT} from '../../constants'
import {useHistoryAssetsByIdQuery} from '../../hooks/api'
import {useAddScheduleItem} from '../../hooks/api/schedules/useAddScheduleItem'

interface GanttChartAddItemProps {
  selectedGridItem: Moment
  startOfPlan: Moment
  endOfPlan: Moment
  stepWidth: number
  assetId: number
}

export const GanttChartAddItem = React.memo(
  React.forwardRef<HTMLDivElement, GanttChartAddItemProps>(
    ({startOfPlan, endOfPlan, stepWidth, assetId, selectedGridItem}, ref) => {
      const theme = useTheme()
      const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})
      const addScheduleItem = useAddScheduleItem({
        startOfPlan,
        endOfPlan
      })

      const firstAssetOperationModeId: number | undefined =
        assetsById && assetId !== undefined
          ? assetsById[assetId]?.operationModes.filter(
              (operationMode) => operationMode.status !== Status.Deleted
            )?.[0]?.id
          : undefined

      const onAddScheduleItem = useCallback(
        (start: Moment) => {
          firstAssetOperationModeId !== undefined &&
            addScheduleItem({
              start: start.toISOString(),
              end: moment.min(start.clone().add(3, 'hours'), endOfPlan).toISOString(),
              assetOperationModeId: firstAssetOperationModeId,
              assetId,
              isTransitionTime: false
            })
        },
        [endOfPlan, firstAssetOperationModeId, assetId, addScheduleItem]
      )

      return (
        <Box
          ref={ref}
          sx={{
            ...theme.typography.h2,
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible',
            whiteSpace: 'nowrap',
            color: ({palette}) => palette.text.primary,
            height: `${GANTT_CHART_ROW_HEIGHT}px`,
            width: `${stepWidth}px`,
            cursor: 'pointer',
            pointerEvents: 'auto',
            position: 'absolute',
            left: 0,
            top: 0
          }}
          onClick={() => onAddScheduleItem(selectedGridItem)}
          {...dataTestId('gantt_chart_click_button')}
        >
          +
        </Box>
      )
    }
  )
)

import {styled} from '@mui/material'
import {lighten, darken, alpha} from '@mui/system'
import React, {
  MouseEventHandler,
  TouchEventHandler,
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
  useRef,
  HTMLAttributes,
  MutableRefObject
} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {useDomRect} from './hooks/useDomRect'
import {useWindowListener, WindowEventListener} from './hooks/useWindowListener'
import type {NumberRange} from './utils'

const StyledActiveTrack = styled('div')(({theme}) => {
  const isLightMode = theme.palette.mode === 'light'
  const backgroundColor = isLightMode ? theme.palette.primary.light : theme.palette.primary.main
  return {
    position: 'absolute',
    top: 1,
    height: 46,
    display: 'grid',
    placeContent: 'center',
    justifyContent: 'stretch',
    background: backgroundColor,
    boxShadow: theme.shadows[8],
    transition: 'background ease-in-out 500ms',
    '&:hover': {
      background: lighten(backgroundColor, 0.2)
    },
    '&:active': {
      background: darken(backgroundColor, 0.2)
    },
    borderRadius: 6
  }
})

const StyledTrack = styled('div')(({theme}) => ({
  height: theme.spacing(6),
  background: alpha(theme.palette.common.black, 0.04),
  width: '100%',
  cursor: 'pointer'
}))

interface TrackProps extends HTMLAttributes<HTMLDivElement> {
  isDraggable: boolean
  onTrackDrag: (event: MouseEvent) => void
  onTrackMouseDown: (event: SyntheticMouseEvent | SyntheticTouchEvent, position: number) => boolean
  percentages: NumberRange
  trackLabel?: React.ReactNode
  children?: React.ReactNode
}
export const Track = React.forwardRef<HTMLDivElement, TrackProps>(
  ({isDraggable, trackLabel, onTrackDrag, onTrackMouseDown, percentages, children}, trackRef) => {
    const dragEvent = useRef<SyntheticMouseEvent | null>(null)

    const withTrackRect = useDomRect(trackRef as MutableRefObject<HTMLDivElement | null>)

    // Global event handlers for movement
    const handleMouseMove: WindowEventListener<'mousemove'> = (event) => {
      if (!isDraggable) return
      if (dragEvent.current) onTrackDrag(event)
    }
    const [addMouseMove, removeMouseMove] = useWindowListener('mousemove', handleMouseMove)

    const handleMouseUp: WindowEventListener<'mouseup'> = () => {
      if (!isDraggable) return
      removeMouseMove()
      dragEvent.current = null
    }
    const [addMouseUp] = useWindowListener('mouseup', handleMouseUp)

    // Synthetic Handlers for the react element
    const handleMouseOrTouchDown = (
      clientX: number,
      event: SyntheticMouseEvent<HTMLDivElement> | SyntheticTouchEvent<HTMLDivElement>
    ) => {
      withTrackRect((clientRect) => {
        const position = clientX - clientRect.left
        const shouldDrag = onTrackMouseDown(event, position)
        if (isDraggable && shouldDrag) {
          addMouseMove()
          addMouseUp({once: true})
        }
      })
    }
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
      dragEvent.current = event
      handleMouseOrTouchDown(event.clientX, event)
    }

    const handleTouchStart: TouchEventHandler<HTMLDivElement> = (event) => {
      handleMouseOrTouchDown(event.touches[0].clientX, event)
    }

    const [min, max] = percentages

    const activeTrackStyle: React.CSSProperties = {
      width: `${(max - min) * 100}%`,
      left: `${min * 100}%`
    }

    return (
      <StyledTrack
        ref={trackRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        {...dataTestId('track')}
      >
        <StyledActiveTrack style={activeTrackStyle} {...dataTestId('slider-thumb')}>
          {trackLabel}
        </StyledActiveTrack>
        {children}
      </StyledTrack>
    )
  }
)

Track.displayName = 'Track'

import {ThemeProvider, useMediaQuery} from '@mui/material'

import {hpTheme, hpDarkTheme} from './themeConfig'
import {ThemeMode} from './themeSettings.types'
import {useThemeSettings} from './ThemeSettingsProvider'

export const HPThemeProvider = ({children}) => {
  const {themeMode: theme} = useThemeSettings()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const selectedMode =
    theme === ThemeMode.Device ? (prefersDarkMode ? ThemeMode.Dark : ThemeMode.Light) : theme

  const selectedTheme = selectedMode === ThemeMode.Dark ? hpDarkTheme : hpTheme

  return <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
}

/*
This is a palette object specifically for the HCEM project that allows the use of custom colours in our charts which are not applicable to the larger HDigital suite of products.
This should be used sparingly and by and large we should be using the overall MUI theme in our components.
 */
/**
 * @deprecated we should use hpTheme palette instead
 */
export const HCEMPalette = {
  swamp: '#8a9e00',
  evergreen: '#128945',
  dirtyHoney: '#cf970e',
  pumpkin: '#cf7329',
  halloween: '#cf4a0e',
  notificationRed: '#DA0901',
  brightPink: '#BA3CAE'
}

import {AssetSnapshot, Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {ModeCommentOutlined} from '@mui/icons-material'
import {Stack, Box, IconButton, useTheme} from '@mui/material'
import {FC} from 'react'

import {GanttChartLabel} from '../../../../shared/components/ganttChart/rowLabels/GanttChartLabel'
import {useSelectedAssetId} from '../../../../shared/components/providers/SelectedAssetCommentProvider'
import {CommentsCategory} from '../../../../shared/enums'
import {useCommentsPermissions} from '../../../../shared/hooks/permissions/useCommentsPermissions'
import {Comment} from '../../../../shared/interfaces/api'
import {CommentNotificationWithTooltip} from '../comments/CommentNotification'

const LABEL_RIGHT_PADDING = 3
const COMMENT_ICON_WIDTH = 4

const AddAssetCommentButton: FC<{assetId: number; isDisabled: boolean}> = ({
  assetId,
  isDisabled
}) => {
  const {setAssetId} = useSelectedAssetId()
  return (
    <IconButton
      sx={{
        border: 'none',
        boxShadow: 'none',
        '&.Mui-disabled': {
          color: 'secondary.main'
        },
        width: ({spacing}) => spacing(COMMENT_ICON_WIDTH),
        height: ({spacing}) => spacing(COMMENT_ICON_WIDTH)
      }}
      color="primary"
      onClick={() => setAssetId(assetId)}
      disabled={isDisabled}
    >
      <ModeCommentOutlined fontSize="small" />
    </IconButton>
  )
}

interface AssetLabelProps {
  asset: AssetSnapshot
  width: number
  isReadOnly: boolean
  shouldShowComment: boolean
  comment?: Comment
}

export const AssetLabel: FC<AssetLabelProps> = ({
  asset,
  comment,
  width,
  isReadOnly,
  shouldShowComment
}) => {
  const {palette, spacing} = useTheme()
  const {canEditComments, canAddComments} = useCommentsPermissions(CommentsCategory.Assets)

  const shouldShowCommentIcon = !!comment || !isReadOnly
  const {id: assetId, name, status} = asset
  // we need this check in order to avoid creating comments for the deleted assets
  // because comment service doesn't validate if the asset is deleted
  const isDeleted = status === Status.Deleted
  const isCommentButtonIsDisabled = isReadOnly || !(canEditComments && canAddComments) || isDeleted
  const commentIconWidth = shouldShowComment ? parseInt(spacing(COMMENT_ICON_WIDTH)) : 0
  const labelTextWidth = width - commentIconWidth - parseInt(spacing(LABEL_RIGHT_PADDING))
  return (
    <Stack
      direction="row"
      sx={{pr: LABEL_RIGHT_PADDING, alignItems: 'center', justifyContent: 'flex-end'}}
      {...dataTestId(`asset_${assetId}_label`)}
    >
      <GanttChartLabel
        titleVariant="caption"
        title={name}
        sx={{width: labelTextWidth}}
        isTooltipEnabled
      />
      {shouldShowComment && (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            minWidth: commentIconWidth,
            position: 'relative'
          }}
          {...dataTestId('asset_label_comment')}
        >
          {comment && (
            <CommentNotificationWithTooltip
              comment={comment}
              styles={{
                notification: {
                  transform: 'translate(25%, -50%)',
                  border: '2px solid',
                  borderColor: palette.common.white
                }
              }}
              tooltipPlacement="top-start"
            />
          )}
          {shouldShowCommentIcon && (
            <AddAssetCommentButton isDisabled={isCommentButtonIsDisabled} assetId={assetId} />
          )}
        </Box>
      )}
    </Stack>
  )
}

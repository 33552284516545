import type {Env} from '@hconnect/common/hproduce'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {CommonHeader, useThemeSettings} from '@hconnect/uikit/src/lib2'
import type {User, NavItem} from '@hconnect/uikit/src/lib2'
import {CommonHeaderGuidedTourProps} from '@hconnect/uikit/src/lib2/shell/header/CommonHeader'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, matchPath} from 'react-router-dom'

import {subPageDynamicImportMap} from '../../dynamicImports'
import {mainRoutes, MainRoutes, getUrl, PLANT_ROUTES} from '../../routing'
import {usePermissionsQuery} from '../hooks/api'
import {usePageAvailability} from '../hooks/usePageAvailability'

import {useLoginDetails} from './providers/LoginProvider'

type PlannerHeaderProps = {
  guidedTours?: CommonHeaderGuidedTourProps
}

export const PlannerHeader: FC<PlannerHeaderProps> = ({guidedTours}) => {
  const {t, i18n} = useTranslation()
  const {loginDetails, logout} = useLoginDetails()
  const {data: permissions} = usePermissionsQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const match = matchPath(`${PLANT_ROUTES.path}/*`, pathName)
  const {plantCode} = match?.params ?? {}

  const pageAvailability = usePageAvailability(plantCode)

  const user: User = {
    email: loginDetails?.email,
    name: loginDetails?.name ?? undefined
  }

  const language = i18n.language
  const languagesRaw = i18n.options?.supportedLngs || [language]
  const languages = languagesRaw.filter((code) => code !== 'cimode')

  const navItems: NavItem[] = useMemo(
    () =>
      pageAvailability && plantCode
        ? pageAvailability
            .filter((page) => page.hasPermission)
            .map((page) => ({
              label: t(`navItems.${page.routeName}`),
              dataTestId: `nav_item_${page.routeName}`,
              url: getUrl(page.path, {plantCode}),
              isDisabled: !page.isAvailable
            }))
        : [],
    [pageAvailability, plantCode, t]
  )

  const {themeMode} = useThemeSettings()

  if (!loginDetails || !permissions || !plantCode) return null

  return (
    <CommonHeader
      appName="Planner"
      plant={{
        plantName: getPlantName(plantCode),
        plantId: plantCode
      }}
      guidedTours={guidedTours}
      env={process.env.REACT_APP_STAGE as Env}
      permissions={permissions}
      navItems={navItems}
      navigate={navigate}
      locationPath={pathName}
      supportedLanguages={languages}
      themeMode={themeMode}
      user={user}
      onLogout={() => void logout()}
      onNavItemHover={(url) => {
        const matchedRouteName = Object.entries(mainRoutes).find(([, path]) => {
          const match = matchPath(path, url)
          return Boolean(match)
        })?.[0] as keyof MainRoutes | undefined

        if (matchedRouteName) {
          void subPageDynamicImportMap[matchedRouteName]()
        }
      }}
    />
  )
}

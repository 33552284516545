import {dataTestId} from '@hconnect/uikit'
import {HPButton} from '@hconnect/uikit/src/lib2'
import {Today} from '@mui/icons-material'
import {Stack, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {
  Timeframe,
  useSelectedTimeframe
} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {calculateRangeWithinBoundary} from '../../../shared/helpers/utils'
import {useCurrentTime} from '../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {getLeftAlignedHourListFromRange} from '../../../shared/selectors'
import {MonthRangeSelect} from '../shared/MonthRangeSelect'
import {PlanningScopeSelect} from '../shared/PlanningScopeSelect'

import {usePlanningChartStartEnd} from './PlanningChartStartEndProvider'

export const DailyProductionPlanningActions = () => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const {selectedTimeframe, setSelectedTimeframe} = useSelectedTimeframe()
  const now = useCurrentTime({timezoneId})
  const {hoursRange, setHoursRange, startOfChart, boundary, stepsPerHour} =
    usePlanningChartStartEnd()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }
  const startOfToday = now.clone().startOf('day')

  const hoursDiff = hoursRange[1] - hoursRange[0]

  const handleTodayClick = () => {
    setSelectedTimeframe([
      startOfToday,
      startOfToday.clone().add(selectedTimeframe[1].diff(selectedTimeframe[0]))
    ])
    setHoursRange([0, hoursDiff])
  }
  const handleMonthChange = (range: Timeframe) => {
    setSelectedTimeframe(range)
    const newHoursList = getLeftAlignedHourListFromRange(range[0], range[1])
    // when switching from 31 day month with a selection in the end of range to shorter month
    // we need to make sure it's a valid range
    if (newHoursList.length < hoursRange[1]) {
      setHoursRange(
        calculateRangeWithinBoundary(hoursRange, [0, newHoursList.length * stepsPerHour])
      )
    }
  }
  const shouldDisableTodayButton = startOfChart.isSame(startOfToday, 'day')

  return (
    <Box>
      <Stack direction="row" spacing={1.5}>
        <HPButton
          {...dataTestId('today_button')}
          backgroundMode="medium"
          startIcon={<Today />}
          onClick={handleTodayClick}
          disabled={shouldDisableTodayButton}
        >
          {t('common.today')}
        </HPButton>
        <MonthRangeSelect
          selectedRange={selectedTimeframe}
          setSelectedRange={handleMonthChange}
          timezoneId={timezoneId}
          // we need to adjust the interval from (] to () to exclude new year
          validDateRange={[boundary[0], boundary[1].clone().subtract(1, 'day').endOf('year')]}
        />
        <PlanningScopeSelect />
      </Stack>
    </Box>
  )
}

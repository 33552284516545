import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {DeleteOutline} from '@mui/icons-material'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useDialog} from '../../../../../shared/components/providers/DialogProvider'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'
import {AutofillRemovalDialogContent} from '../autofillRemoval/AutofillRemovalRangeDialogContent'

export const RemoveAutofillItemsButton: React.FC = () => {
  const {t} = useTranslation()
  const {openDialog} = useDialog()
  const {
    boundary: [, endOfPlanning]
  } = usePlanningChartStartEnd()

  const openSelectRangeDialog = () => {
    openDialog({
      header: {
        title: t('planning.removeAutofillItems')
      },
      content: <AutofillRemovalDialogContent endOfPlanning={endOfPlanning} />
    })
  }

  return (
    <Button
      variant="text"
      color="error"
      onClick={openSelectRangeDialog}
      {...dataTestId('open_remove_autofill_items_dialog_btn')}
    >
      <DeleteOutline sx={{mr: 0.5}} /> {t('common.remove')}
    </Button>
  )
}

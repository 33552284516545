import {forwardRef, useLayoutEffect, CanvasHTMLAttributes, DetailedHTMLProps, useRef} from 'react'

type Canvas2DProps = {
  width: number
  height: number
  renderCanvas: (ctx: CanvasRenderingContext2D) => void
  style?: DetailedHTMLProps<CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>['style']
} & Omit<DetailedHTMLProps<CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>, 'ref'>

export const Canvas2D = forwardRef<HTMLCanvasElement, Canvas2DProps>(
  ({style, width, height, renderCanvas, ...rest}, outerRef) => {
    const localRef = useRef<HTMLCanvasElement>(null)

    const ref = outerRef || localRef
    useLayoutEffect(() => {
      const canvas = outerRef && 'current' in outerRef ? outerRef.current : localRef.current
      if (canvas) {
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        if (ctx) {
          renderCanvas(ctx)
        }
      }
    }, [height, width, renderCanvas, outerRef])

    return (
      <canvas
        {...rest}
        ref={ref}
        style={{
          ...style,
          height: `${height}px`,
          width: `${width}px`
        }}
      />
    )
  }
)

import {AssetType} from '@hconnect/common/types'
import {useTranslation} from '@hconnect/uikit'
import {Column} from '@hconnect/uikit/src/lib2'
import {Typography, useTheme} from '@mui/material'
import moment, {Moment} from 'moment'
import React, {useCallback, useMemo} from 'react'

import {KPI} from '../../../../shared/components/KPI'
import {PlannerDataTable} from '../../../../shared/components/PlannerDataTable'
import {useCostAvoidanceForRangeQuery} from '../../../../shared/hooks/api'
import {CostAvoidanceAssetValuesForRange} from '../../../../shared/interfaces/api'

export const allAssets = [
  AssetType.CementMill,
  AssetType.CoalMill,
  AssetType.Crusher,
  AssetType.RawMill,
  AssetType.RotaryKiln,
  AssetType.Other
]

type CostAvoidanceAssetValuesForRangeWithAsset = {
  asset: string
  key: string
  marketCosts?: number
  costs: number
  producedTons: number
  mwhConsumed: number
  costsPerTon: number
  costsPerMWh: number
  baseLoad?: number
}

const mapDataToRow = (
  key: string,
  data: CostAvoidanceAssetValuesForRange,
  {asset, marketCosts, baseLoad}: {asset: string; marketCosts?: number; baseLoad?: number}
): CostAvoidanceAssetValuesForRangeWithAsset => {
  const costs = data?.electricityCost ?? 0
  const producedTons = data?.producedTonnes ?? 0
  const mwhConsumed = data?.electricityConsumed ?? 0
  const costsPerTon = producedTons ? costs / producedTons : 0
  const costsPerMWh = mwhConsumed ? costs / mwhConsumed : 0

  return {
    asset,
    marketCosts,
    costs,
    producedTons,
    mwhConsumed,
    costsPerTon,
    costsPerMWh,
    baseLoad,
    key
  }
}

export const KPISectionTable: React.FC<{
  scheduleId: number
  isExpanded: boolean
  from: Moment
  to: Moment
}> = ({scheduleId, from, to, isExpanded}) => {
  const {t} = useTranslation()

  const {data: KPIs} = useCostAvoidanceForRangeQuery({
    scheduleId,
    from: moment.utc(from).toISOString(),
    to: moment.utc(to).toISOString()
  })

  const {spacing} = useTheme()

  const marketCosts = KPIs?.electricityAverageMarketPrice

  const tableColumns: Column<CostAvoidanceAssetValuesForRangeWithAsset>[] = [
    {
      key: 'asset',
      label: t('planning.kpiTable.asset'),
      width: spacing(20),
      customTemplate: ({asset}) => <Typography variant="body1">{asset}</Typography>
    },
    {
      key: 'costs',
      label: t('common.expectedCosts'),
      width: spacing(20),
      customTemplate: ({costs}) => <KPI variant="body1" amount={costs} unit="currency" />
    },
    {
      key: 'producedTons',
      label: t('common.producedTons'),
      width: spacing(16),
      customTemplate: ({producedTons}) => <KPI variant="body1" amount={producedTons} unit="tons" />
    },
    {
      key: 'mwhConsumed',
      label: t('electricity.mwhConsumed'),
      width: spacing(18),
      customTemplate: ({mwhConsumed}) => <KPI variant="body1" amount={mwhConsumed} unit="mwh" />
    },
    {
      key: 'costsPerTon',
      label: t('electricity.costsPerTon'),
      width: spacing(18),
      customTemplate: ({costsPerTon}) => (
        <KPI variant="body1" amount={costsPerTon} unit="currency" digits={2} />
      )
    },
    {
      key: 'costsPerMWh',
      label: t('electricity.costsPerMWh'),
      width: spacing(18),
      customTemplate: ({costsPerMWh}) => (
        <KPI variant="body1" amount={costsPerMWh} unit="currency" digits={2} />
      )
    },
    {
      key: 'baseLoad',
      label: t('electricity.baseLoad'),
      width: spacing(18),
      customTemplate: ({baseLoad}) => <KPI variant="body1" amount={baseLoad} unit="mw" />
    },
    {
      key: 'marketCosts',
      label: t('electricity.marketCostsPerMWh'),
      width: spacing(18),
      customTemplate: ({marketCosts}) => (
        <KPI variant="body1" amount={marketCosts} unit="currency" digits={2} />
      )
    }
  ]

  const rowMapper = useCallback(
    (key: string, data: CostAvoidanceAssetValuesForRange, asset: string) =>
      mapDataToRow(key, data, {asset, marketCosts, baseLoad: KPIs?.baseload}),
    [KPIs?.baseload, marketCosts]
  )

  const tableData: CostAvoidanceAssetValuesForRangeWithAsset[] = useMemo(
    () => [
      ...(KPIs?.total ? [rowMapper('total', KPIs.total || {}, t('common.total'))] : []),
      ...(isExpanded && KPIs?.assets
        ? allAssets.map((asset) =>
            rowMapper(asset, KPIs.assets[asset] || {}, t(`planning.assetTypes.${asset}`))
          )
        : [])
    ],
    [KPIs?.assets, KPIs?.total, isExpanded, rowMapper, t]
  )

  const keyExtractor = useCallback(({key}: CostAvoidanceAssetValuesForRangeWithAsset) => key, [])

  return (
    <>
      <PlannerDataTable
        columns={tableColumns}
        data={tableData}
        id="kpi-table"
        emptyMessage={t('electricity.costAvoidance.noData')}
        keyExtractor={keyExtractor}
      />
    </>
  )
}

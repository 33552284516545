import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {formatTimeZoneDate} from '@hconnect/uikit/src/common'
import {MarkdownText, KebabMenu, type MenuAction} from '@hconnect/uikit/src/lib2'
import {HPCommentItem} from '@hconnect/uikit/src/lib2/components/comment'
import {DeleteOutlined, EditOutlined} from '@mui/icons-material'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CommentsCategory} from '../../../../shared/enums'
import {useHistoryAssetsByIdQuery} from '../../../../shared/hooks/api'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useAssetHistoryDataByScheduleItemId} from '../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {useCommentsPermissions} from '../../../../shared/hooks/permissions'
import {useCurrentTimeRounded} from '../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {Comment, ScheduleItem} from '../../../../shared/interfaces/api'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'

interface CommentContentProps {
  handleDelete: undefined | ((comment: Comment) => void)
  handleEdit: undefined | ((comment: Comment) => void)
  comment: Comment
  startOfPlan: Moment
  endOfPlan: Moment
}

export const PlanningCommentItem: React.FC<CommentContentProps> = ({
  handleDelete,
  handleEdit,
  startOfPlan,
  endOfPlan,
  comment
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {timezone_id: timezoneId} = usePlantConfig()

  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  const {isOptimized} = usePlanningChartScheduleData()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized})
  if (!schedule) {
    throw new Error('BUG: schedule should be loaded before rendering comments')
  }
  const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule
  })
  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})
  if (!assetsById || !assetDataByScheduleItemId) {
    throw new Error(
      'BUG: assetsById and assetDataByScheduleItemId should be loaded before rendering comments'
    )
  }
  const scheduleDict = schedule.schedules

  const {canEditComments, canDeleteComments} = useCommentsPermissions(
    CommentsCategory.AssetOperationTimes
  )

  const getAssetName = (assetId: string | number) => assetsById[assetId].name
  const getStartAndEndTimes = ({start, end}: ScheduleItem) => {
    const formatTime = (time: string) => formatTimeZoneDate(time, timezoneId, 'HH:mm', language)
    return `(${formatTime(start)} - ${formatTime(end)})`
  }

  const {commentsCategoryInstanceId: instanceId, commentsCategory} = comment

  const isScheduleItemComment = commentsCategory === CommentsCategory.AssetOperationTimes

  const getCommentTitle = () => {
    switch (commentsCategory) {
      case CommentsCategory.Assets:
        return getAssetName(instanceId)
      case CommentsCategory.AssetOperationTimes: {
        const scheduleItem = scheduleDict[instanceId]
        const operationMode = assetDataByScheduleItemId[scheduleItem.id].operationMode
        return `${getAssetName(scheduleItem.assetId)} / ${operationMode.name} ${getStartAndEndTimes(
          scheduleItem
        )}`
      }
      default:
        throw new Error('Unsupported comment category')
    }
  }

  const userName = comment.updatedBy ?? comment.createdBy
  const commentDate = moment.utc(comment.updatedOn ?? comment.createdOn).tz(timezoneId)

  const isHistoryComment =
    isScheduleItemComment && moment.utc(scheduleDict[instanceId].end).isBefore(nowRounded)

  const isEditDisabled = !canEditComments || isHistoryComment
  const isDeleteDisabled = !canDeleteComments || isHistoryComment

  const menuActions = useMemo(
    (): MenuAction[] => [
      ...(handleEdit
        ? [
            {
              title: t('common.edit'),
              testId: 'edit_button',
              icon: <EditOutlined fontSize="small" />,
              onClick: () => handleEdit(comment),
              isDisabled: isEditDisabled
            }
          ]
        : []),
      ...(handleDelete
        ? [
            {
              title: t('common.delete'),
              testId: 'delete_button',
              icon: <DeleteOutlined fontSize="small" />,
              onClick: () => handleDelete(comment),
              isDisabled: isDeleteDisabled
            }
          ]
        : [])
    ],
    [t, handleEdit, handleDelete, comment, isEditDisabled, isDeleteDisabled]
  )

  return (
    <HPCommentItem
      key={comment.commentId}
      userName={userName}
      commentDate={commentDate}
      title={getCommentTitle()}
      content={<MarkdownText>{comment.value}</MarkdownText>}
      menuComponent={<KebabMenu actions={menuActions} />}
      {...dataTestId('planning_comment')}
    />
  )
}

import {dataTestId, formatTons} from '@hconnect/uikit'
import {Column} from '@hconnect/uikit/src/lib2'
import {BuildOutlined} from '@mui/icons-material'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {MaterialTypeIcon} from '../../../shared/components/MaterialTypeIcon'
import {PlannerDataTable} from '../../../shared/components/PlannerDataTable'
import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {useHistoryMaterialsById} from '../../../shared/hooks/api/materials/useHistoryMaterialsById'
import {AssetCapacity} from '../../../shared/interfaces/api/kpi'

import {getPercentageInDecimal} from './assetCapacity.helpers'

interface MaterialCapacity {
  materialId: number
  runtimeInHours: number
  producedTonnes: number
}

interface MaintenanceCapacity {
  materialId: null
  runtimeInHours: number
}

type AssetCapacityTableEntry = MaterialCapacity | MaintenanceCapacity

const mapAssetCapacityToTableData = ({
  materials,
  maintenance
}: AssetCapacity): AssetCapacityTableEntry[] => {
  return [
    ...Object.entries(materials).map(([materialId, material]) => ({
      materialId: Number(materialId),
      runtimeInHours: material.runtimeInHours,
      producedTonnes: material.producedTonnes
    })),
    {
      materialId: null,
      runtimeInHours: maintenance.runtimeInHours
    }
  ]
}

interface AssetCapacityTableProps {
  assetCapacity: AssetCapacity
}

export const AssetCapacityTable: React.FC<AssetCapacityTableProps> = ({assetCapacity}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }

  const {data: materialsById} = useHistoryMaterialsById({timeFrame: selectedTimeframe})

  const hoursInTimeframe = selectedTimeframe[1].diff(selectedTimeframe[0], 'hours')

  const tableColumns: Column<AssetCapacityTableEntry>[] = [
    {
      key: 'material',
      label: t('planning.producedMaterial'),
      customTemplate: ({materialId}) => (
        <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
          {materialId ? (
            <>
              {materialsById && <MaterialTypeIcon materialType={materialsById[materialId].type} />}
              <Typography variant="subtitle1"> {materialsById?.[materialId].name} </Typography>
            </>
          ) : (
            <>
              <BuildOutlined sx={{fontSize: ({spacing}) => spacing(1.5)}} />
              <Typography variant="subtitle1"> {t('planning.maintenance')} </Typography>
            </>
          )}
        </Stack>
      )
    },
    {
      key: 'runtimeInHours',
      label: t('planning.timeOnAsset'),
      customTemplate: ({runtimeInHours}) => (
        <Typography variant="subtitle1">
          {runtimeInHours}h ({getPercentageInDecimal(runtimeInHours, hoursInTimeframe)})
        </Typography>
      )
    },
    {
      key: 'producedTonnes',
      label: t('planning.producedAmount'),
      customTemplate: (entry) =>
        entry.materialId ? (
          <Typography variant="subtitle1">{formatTons(entry.producedTonnes, language)}</Typography>
        ) : null
    }
  ]

  const tableData = mapAssetCapacityToTableData(assetCapacity)

  return (
    <PlannerDataTable
      columns={tableColumns}
      data={tableData}
      keyExtractor={(entry) => String(entry.materialId) ?? 'maintenance'}
      {...dataTestId('asset_capacity_table')}
    />
  )
}

import {dataTestId} from '@hconnect/uikit'
import {ArrowForward, Close, DeleteOutline} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import {FC} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useSelectedAssetId} from '../../../shared/components/providers/SelectedAssetCommentProvider'
import {CommentsCategory} from '../../../shared/enums'
import {useAddAssetComment} from '../../../shared/hooks/api/comments/useAddAssetComment'
import {useDeleteComment} from '../../../shared/hooks/api/comments/useDeleteComment'
import {useUpdateAssetComment} from '../../../shared/hooks/api/comments/useUpdateAssetComment'
import {useCommentsPermissions} from '../../../shared/hooks/permissions/useCommentsPermissions'
import {useConfirmDialog} from '../../../shared/hooks/useConfirmDialog'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {Comment} from '../../../shared/interfaces/api'

import {CommentInput} from './comments/CommentInput'

interface AssetCommentFormProps {
  timezoneId: string
  assetId: number
  comment?: Comment
}

export const AssetCommentForm: FC<AssetCommentFormProps> = ({comment, timezoneId, assetId}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {setAssetId} = useSelectedAssetId()
  const addAssetComment = useAddAssetComment()
  const updateAssetComment = useUpdateAssetComment()
  const {mutate: deleteComment} = useDeleteComment()
  const {openDialog} = useConfirmDialog()
  const commentInitialState: Pick<Comment, 'value'> = {value: comment?.value ?? ''}
  const {register, formState, reset, handleSubmit, watch} = useForm<typeof commentInitialState>({
    defaultValues: commentInitialState
  })
  const {canDeleteComments} = useCommentsPermissions(CommentsCategory.Assets)
  const isSubmitting = formState.isSubmitting
  const currentCommentValue = watch('value')

  const handleCommentSubmit = async (data: typeof commentInitialState) => {
    if (comment) {
      await updateAssetComment({
        plantCode,
        commentsCategoryInstanceId: assetId.toString(),
        commentId: comment.commentId,
        commentDto: data
      })
    } else {
      await addAssetComment({
        plantCode,
        commentsCategoryInstanceId: assetId.toString(),
        commentDto: data
      })
    }
  }

  const saveComment = async () => {
    if (currentCommentValue) {
      await handleSubmit(handleCommentSubmit)()
    }
    setAssetId(undefined)
  }

  const onDeleteComment = () => {
    if (!comment) throw new Error('Comment is missing')
    openDialog({
      title: t('planning.deleteComment'),
      description: t('planning.deleteCommentConfirmation'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () => {
          deleteComment({
            plantCode,
            commentsCategoryInstanceId: assetId.toString(),
            commentId: comment.commentId,
            commentsCategory: comment.commentsCategory
          })
          setAssetId(undefined)
        }
      }
    })
  }

  const isSaveButtonDisabled = isSubmitting || !currentCommentValue

  return (
    <Box mb={2}>
      <CommentInput
        testId="asset_comment_input"
        inputProps={{
          ...register('value')
        }}
        comment={comment}
        currentCommentValue={currentCommentValue}
        timezoneId={timezoneId}
        onDelete={() => reset({value: ''})}
      />
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button
          {...dataTestId('asset_comment_delete')}
          color="secondary"
          startIcon={<DeleteOutline color="error" />}
          onClick={onDeleteComment}
          disabled={!canDeleteComments || !comment}
        >
          {t('common.delete')}
        </Button>
        <Box display="flex" alignItems="center">
          <Button
            {...dataTestId('asset_comment_cancel')}
            color="secondary"
            startIcon={<Close />}
            onClick={() => setAssetId(undefined)}
          >
            {t('common.cancel')}
          </Button>
          <Box ml={2}>
            <Button
              {...dataTestId('asset_comment_save')}
              color="primary"
              variant="contained"
              startIcon={<ArrowForward />}
              onClick={() => void saveComment()}
              disabled={isSaveButtonDisabled}
            >
              {t('common.save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import 'chartjs-adapter-moment'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {formatFloat, dataTestId} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {useTheme, alpha, Stack} from '@mui/material'
import {
  BarElement,
  CategoryScale,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Plugin,
  Legend,
  Chart as ChartJS,
  ChartData
} from 'chart.js/auto'
import annotationPlugin, {AnnotationOptions} from 'chartjs-plugin-annotation'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {Chart} from 'react-chartjs-2'
import {useTranslation} from 'react-i18next'

import {coreChartConfig} from '../../../../shared/chartJScofigs/chartConfigs'
import {useChartStyles} from '../../../../shared/hooks/useChartStyles'
import {useCurrentTime} from '../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {PowerConsumption} from '../../../../shared/interfaces/api/electricity'
import {PeakLoadWindow} from '../../../../shared/selectors'
import {PowerDiffItem} from '../../../../shared/selectors/electricity'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {
  getShiftBarPositionPlugin,
  getTooltipOptions,
  getXScaleOptions,
  getYScaleOptions
} from './electricityChartConfig'

const VISIBLE_CELL_TEXT_BREAKPOINT_PER_CHARACTER = 8

const charMap = {
  positive: '+',
  negative: '-',
  neutral: '•'
} as const

const get15MinIntervalList = (start: Moment, end: Moment) => {
  const list: Moment[] = []
  const intervalMin = 15
  const current = moment.utc(start).clone()
  while (current.isBefore(end)) {
    current.add(intervalMin, 'minutes')
    list.push(current.clone())
  }
  return list
}

const generateEnergyChartData = (
  data: PowerConsumption[] | undefined,
  startOfRange: Moment
): Record<string, number | null> => {
  if (!data || data.length === 0) {
    return {}
  }
  // empty placeholde to ensure tooltipdata is correct in index mode
  const emptyPlaceHolder = get15MinIntervalList(startOfRange, data[0].dateTimeUTC).map((item) => [
    item.toISOString(),
    null
  ])
  return Object.fromEntries([
    ...emptyPlaceHolder,
    ...data.map((item) => [item.dateTimeIso, item.power])
  ])
}

const getDiffChar = (diff: number): string => {
  if (diff === 0) {
    return charMap.neutral
  }
  return diff > 0 ? charMap.positive : charMap.negative
}
const getDiffText = (diff: number, language: string): string => {
  if (diff === 0) {
    return charMap.neutral
  }
  return `${diff > 0 ? charMap.positive : ''}${formatFloat(diff, 1, language)}`
}

//  Register the chart components to enable tree shaking
ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface ElectricityChartProps {
  hoursList: Moment[]
  visibleChartWidth: number
  cellWidth: number
  yAxisWidth: number
  height: number
  maxValue: number
  minValue: number
  powerQuarterly: PowerConsumption[] | undefined
  energyPurchased: PowerConsumption[] | undefined
  energyUpForPurchase: PowerConsumption[] | undefined
  peakLoadWindows?: PeakLoadWindow[]
  hourlyDiff?: PowerDiffItem[]
  actualPricesEnd: Moment | undefined
  isMinimized: boolean
}

const _ElectricityChart: React.FC<ElectricityChartProps> = ({
  hoursList,
  cellWidth,
  height: fullChartHeight,
  maxValue: dataMaxYValue,
  minValue: dataMinYValue,
  powerQuarterly,
  energyPurchased,
  energyUpForPurchase,
  peakLoadWindows,
  yAxisWidth,
  actualPricesEnd,
  visibleChartWidth,
  hourlyDiff,
  isMinimized
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {palette, spacing} = useTheme()
  const {gridLineColor, tickColor, gridLineBoldColor, nowLineColor, hoverBackground} =
    useChartStyles()

  const {timezone_id: timezoneId} = usePlantConfig()
  const now = useCurrentTime({timezoneId})
  const nowRounded = roundTo15MinIntervalStart(now)
  const maxYValue = isMinimized ? 0 : dataMaxYValue
  const minYValue = isMinimized ? 0 : Math.min(0, dataMinYValue)
  const {visibleHoursList} = usePlanningChartStartEnd()
  const minXValue = visibleHoursList[0].clone()
  const maxXValue = visibleHoursList[visibleHoursList.length - 1].clone().add(1, 'hour')
  // setting step size for y axis approximately 20% of max value
  const amountOfHours = visibleHoursList.length
  const yRange = maxYValue - minYValue
  const yValueStep = Math.round(Math.max(Math.abs(yRange * 0.2), 1))
  // setting minYValue to place delta values below x axis
  const minVisibleYValue = Math.round(Math.min(0, dataMinYValue)) - yValueStep
  const maxVisibleYValue = Math.round(maxYValue) + yValueStep

  const height = isMinimized ? parseInt(spacing(4)) : fullChartHeight

  const xScaleOptions = useMemo(() => {
    return getXScaleOptions({
      minXValue,
      maxXValue,
      timezoneId,
      isMinimized,
      gridColor: gridLineColor,
      gridStartOfDayColor: gridLineBoldColor,
      tickColor
    })
  }, [minXValue, maxXValue, timezoneId, isMinimized, gridLineColor, gridLineBoldColor, tickColor])

  const yScaleOptions = useMemo(() => {
    return getYScaleOptions({
      t,
      language,
      isMinimized,
      maxYValue: maxVisibleYValue,
      minYValue: minVisibleYValue,
      yValueStep,
      yAxisWidth,
      gridColor: gridLineColor,
      tickColor
    })
  }, [
    t,
    language,
    isMinimized,
    maxVisibleYValue,
    minVisibleYValue,
    gridLineColor,
    yValueStep,
    yAxisWidth,
    tickColor
  ])

  const shiftBarPosition: Plugin = useMemo(
    () => getShiftBarPositionPlugin(amountOfHours),
    [amountOfHours]
  )
  const plugins: Plugin[] = useMemo(() => [shiftBarPosition], [shiftBarPosition])

  const shouldDisplayNowLine = nowRounded.isBetween(minXValue, maxXValue)

  const longestTextCharLength = useMemo(() => {
    return hoursList.reduce((acc, hour, index) => {
      const mwDiff = hourlyDiff?.[index].mwDiff ?? undefined
      const text = mwDiff !== undefined ? getDiffText(mwDiff, language) : ''
      return Math.max(acc, text.length)
    }, 1)
  }, [hoursList, hourlyDiff, language])

  const isTextVisible =
    cellWidth > longestTextCharLength * VISIBLE_CELL_TEXT_BREAKPOINT_PER_CHARACTER

  const annotations: AnnotationOptions[] = useMemo(
    () => [
      // now line
      ...(shouldDisplayNowLine
        ? [
            {
              type: 'line' as const,
              yMin: minVisibleYValue,
              xMin: nowRounded.valueOf(),
              xMax: nowRounded.valueOf(),
              borderColor: nowLineColor,
              borderWidth: 2,
              z: 10
            }
          ]
        : []),
      // peak load windows
      ...(peakLoadWindows && !isMinimized
        ? peakLoadWindows.map((peakLoadWindow) => ({
            type: 'box' as const,
            xMin: peakLoadWindow.start.valueOf(),
            xMax: peakLoadWindow.end.valueOf(),
            yMin: peakLoadWindow.maxPower,
            backgroundColor: alpha(palette.error.main, 0.2),
            borderColor: palette.error.main,
            borderWidth: 1
          }))
        : []),
      // actual prices indicator
      ...(actualPricesEnd
        ? [
            {
              type: 'box' as const,
              xMin: hoursList[0].valueOf(),
              xMax: actualPricesEnd.clone().add(15, 'minutes').valueOf(),
              yMin: minVisibleYValue,
              backgroundColor: hoverBackground,
              borderWidth: 0
            },
            {
              type: 'line' as const,
              xMin: actualPricesEnd.clone().add(15, 'minutes').valueOf(),
              xMax: actualPricesEnd.clone().add(15, 'minutes').valueOf(),
              borderWidth: 1,
              borderColor: gridLineBoldColor
            }
          ]
        : []),
      // deltas for each hour
      ...hoursList.map((hour, index) => {
        const mwDiff = hourlyDiff?.[index].mwDiff ?? undefined
        const labelConfig =
          mwDiff !== undefined
            ? {
                content: isTextVisible ? getDiffText(mwDiff, language) : getDiffChar(mwDiff),
                display: true,
                font: {
                  size: 14,
                  family: hpTheme.typography.fontFamily,
                  weight: 600
                },
                color:
                  mwDiff === 0
                    ? palette.text.primary
                    : mwDiff > 0
                      ? palette.error.main
                      : palette.warning.main,
                padding: 0
              }
            : undefined

        return {
          type: 'box' as const,
          xMin: hour.valueOf(),
          xMax: hour.clone().add(1, 'hour').valueOf(),
          yMin: minVisibleYValue,
          yMax: minYValue,
          borderWidth: 0,
          label: labelConfig,
          backgroundColor: alpha(palette.grey[400], 0)
        }
      })
    ],
    [
      nowRounded,
      palette,
      peakLoadWindows,
      isMinimized,
      actualPricesEnd,
      hoursList,
      minVisibleYValue,
      hourlyDiff,
      isTextVisible,
      language,
      minYValue,
      shouldDisplayNowLine,
      nowLineColor,
      gridLineBoldColor,
      hoverBackground
    ]
  )

  const options: ChartOptions = useMemo(
    () => ({
      ...coreChartConfig,
      interaction: {
        mode: 'index'
      },
      animation: false,
      scales: {
        x: xScaleOptions,
        y: yScaleOptions
      },
      plugins: {
        tooltip: getTooltipOptions(t, language),
        legend: {
          display: false
        },
        annotation: {annotations},
        shiftBarPosition
      }
    }),
    [t, language, xScaleOptions, yScaleOptions, annotations, shiftBarPosition]
  )

  const startOfRange = hoursList[0]

  const powerQuarterlyData = useMemo(
    () => generateEnergyChartData(powerQuarterly, startOfRange),
    [powerQuarterly, startOfRange]
  )

  const energyPurchasedData = useMemo(
    () => generateEnergyChartData(energyPurchased, startOfRange),
    [energyPurchased, startOfRange]
  )

  const energyUpForPurchaseData = useMemo(
    () => generateEnergyChartData(energyUpForPurchase, startOfRange),
    [energyUpForPurchase, startOfRange]
  )

  const data: ChartData<'bar' | 'line', Record<string, number | null>> = useMemo(
    () => ({
      labels: hoursList,
      datasets: isMinimized
        ? []
        : [
            {
              type: 'line' as const,
              data: powerQuarterlyData,
              borderColor: palette.primary.light,
              stepped: true,
              fill: false,
              pointRadius: 0,
              pointHitRadius: 50
            },
            {
              type: 'bar' as const,
              data: energyPurchasedData,
              backgroundColor: alpha(palette.grey[600], 0.5),
              barPercentage: 1,
              categoryPercentage: 1
            },
            {
              type: 'bar' as const,
              data: energyUpForPurchaseData,
              backgroundColor: alpha(palette.primary.light, 0.5),
              barPercentage: 1,
              categoryPercentage: 1
            }
          ]
    }),
    [
      hoursList,
      powerQuarterlyData,
      energyPurchasedData,
      energyUpForPurchaseData,
      palette,
      isMinimized
    ]
  )

  // forcing update on cellWidth, height, visible hours list change to display chart correctly
  const chartKey = `electricity-${height}-${cellWidth}-${amountOfHours}`

  return (
    <Stack
      {...dataTestId(`electricity_chart_content_${isMinimized ? 'minimized' : 'full'}`)}
      id="electricity-consumption-chart"
      direction="row"
      sx={{overflow: 'hidden'}}
    >
      <Chart
        key={chartKey}
        type="bar"
        data={data}
        options={options}
        width={visibleChartWidth + yAxisWidth}
        height={height}
        plugins={plugins}
      />
    </Stack>
  )
}

export const ElectricityChart = React.memo(_ElectricityChart)

import {
  MaterialsHistory,
  Recipe,
  RecipeComponent,
  RecipeComponentType,
  AssetSnapshot,
  MaterialWithRecipes
} from '@hconnect/common/types'
import {Moment} from 'moment'

import {guardProducingMode} from './assets'
import {getRelevantHistoryEntry} from './history'

interface GetOptimizedMaterialIds {
  assets: AssetSnapshot[]
  recipesById: Record<string, Recipe>
}
/** function to get unique materialIds for all optimized assets */
export const getOptimizedMaterialIds = ({
  assets,
  recipesById
}: GetOptimizedMaterialIds): number[] => {
  const optimizedAssets = assets.filter((asset) => asset.isOptimized)
  const optimizedProductionModes = optimizedAssets
    .flatMap((asset) => asset.operationModes)
    .filter(guardProducingMode)

  const uniqueMaterialIdsInAllRecipeComponents = new Set(
    optimizedProductionModes
      // filtering out operation modes without current recipes, in case of data corruption
      .filter((operationMode) => recipesById[operationMode.recipeId] !== undefined)
      .flatMap(({recipeId}) => recipesById[recipeId].components)
      // filtering outputs
      .filter((component) => component.type === RecipeComponentType.Output)
      .map((component) => component.material.id)
  )
  return Array.from(uniqueMaterialIdsInAllRecipeComponents.values())
}

export const getRecipeComponentsWithMaterial = (
  materialId: number,
  recipe: Recipe,
  componentTypes: RecipeComponentType[]
): RecipeComponent[] => {
  return recipe.components
    .filter((component) => componentTypes.includes(component.type))
    .filter((component) => component.material.id === materialId)
}

export const getMaterialsFromHistoryByDate = (materialsHistory: MaterialsHistory, to: Moment) => {
  return Object.values(materialsHistory)
    .map((assetHistory) => getRelevantHistoryEntry(assetHistory, to))
    .filter((entry): entry is MaterialWithRecipes => Boolean(entry))
}

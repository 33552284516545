import {PlannerQueryClient} from '../components/providers/QueryProvider'

import {Queries, QueryReturn, QueryHelper} from './queryType'

type PartialParameters<T> = T extends [...infer Params]
  ? {[K in keyof Params]?: Partial<Params[K]>}
  : []

type QueryHelperPartial = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: PartialParameters<Parameters<T[K]>[0]['params']> | []
) => void

export const invalidateHcemQuery: QueryHelperPartial = (queryKey, ...params) =>
  void PlannerQueryClient.invalidateQueries({
    queryKey: [queryKey, ...params],
    refetchType: 'all'
  })

export const removeHcemQuery: QueryHelperPartial = (queryKey, ...params) =>
  PlannerQueryClient.removeQueries({queryKey: [queryKey, ...params]})

export const refetchHcemQuery: QueryHelper = (queryKey, ...params) =>
  PlannerQueryClient.refetchQueries({queryKey: [queryKey, ...params]})

export const getHcemQueryData = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  params: Parameters<T[K]>[0]['params']
) => PlannerQueryClient.getQueryData<QueryReturn<K>>([queryKey, ...params])

export const setHcemQueryData = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  params: Parameters<T[K]>[0]['params'],
  data: QueryReturn<K>
) => PlannerQueryClient.setQueryData([queryKey, ...params], data)

import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'

import {ForecastUpdatedVia, ShippingType} from '../enums'
import {
  MaterialDemandByDay,
  MaterialDemandById,
  MaterialDemandEntry,
  CombinedDemandData,
  DemandDataPerMaterial,
  DemandForecastPerType,
  AggregatedDemandData,
  CombinedDemandKeys,
  ExtendedLastUpdate
} from '../interfaces/api'

/**
 * Helper function to filter demand daily entries by materialIds
 * @param materialDemandByDay
 * @param materialIds
 * @returns list of demand entries for selected materials
 */
export const getSelectedMaterialDemandEntries = (
  materialDemandById: MaterialDemandById,
  materialIds?: number[]
) => {
  if (materialIds === undefined) {
    return Object.values(materialDemandById)
  }
  return Object.entries(materialDemandById)
    .filter(([id]) => {
      return materialIds.includes(Number(id))
    })
    .map(([, demand]) => demand)
}

export const getTotalValueForShippingTypes = (demandPerType: Partial<DemandForecastPerType>) => {
  return isEmpty(demandPerType)
    ? null
    : Object.values(demandPerType).reduce((acc, value) => acc + value, 0)
}

const addNotNullValue = (acc: number | null, value: number | null) => {
  if (value === null) {
    return acc
  }
  return (acc ?? 0) + value
}

/**
 * helper function to get combined demand data for materials daily
 * @param materialDemandByDay
 * @param materialIds
 * @returns combined demand data for materials daily
 */

export const combineDemandDataForMaterials = (materialDemand: MaterialDemandEntry[]) => {
  return materialDemand.reduce<Record<CombinedDemandKeys, number | null>>(
    (acc, entry) => {
      const combinedPredictions = getTotalValueForShippingTypes(entry.predictions)
      acc.predictions = addNotNullValue(acc.predictions, combinedPredictions)
      const combinedMerged = getTotalValueForShippingTypes(entry.merged)
      acc.merged = addNotNullValue(acc.merged, combinedMerged)
      const combinedActuals = getTotalValueForShippingTypes(entry.actuals)
      acc.actuals = addNotNullValue(acc.actuals, combinedActuals)
      const combinedPreOrders = getTotalValueForShippingTypes(entry.preOrders)
      acc.preOrders = addNotNullValue(acc.preOrders, combinedPreOrders)
      const combinedSalesForecast = getTotalValueForShippingTypes(entry.salesForecast)
      acc.salesForecast = addNotNullValue(acc.salesForecast, combinedSalesForecast)
      const combinedUserOverrides = getTotalValueForShippingTypes(entry.userOverrides)
      acc.userOverrides = addNotNullValue(acc.userOverrides, combinedUserOverrides)
      const combinedSchedules = getTotalValueForShippingTypes(entry.schedules)
      acc.schedules = addNotNullValue(acc.schedules, combinedSchedules)

      return acc
    },
    {
      predictions: null,
      merged: null,
      actuals: null,
      preOrders: null,
      salesForecast: null,
      schedules: null,
      userOverrides: null
    }
  )
}

/**
 * Function used for demand chart, combines all selected materials data
 * returns combined demand data for materials daily
 **/

export const getCombinedDemandDataDaily = (
  materialDemandByDay: MaterialDemandByDay,
  materialIds?: number[]
) => {
  const demandData = Object.entries(materialDemandByDay).reduce<CombinedDemandData>(
    (acc, [day, materialDemandById]) => {
      const selectedMaterialDemandEntries = getSelectedMaterialDemandEntries(
        materialDemandById,
        materialIds
      )
      const data = combineDemandDataForMaterials(selectedMaterialDemandEntries)
      // we need to filter null values to distinguish between no data (null) and 0
      if (data.predictions !== null) {
        acc.predictions[day] = data.predictions
      }
      if (data.merged !== null) {
        acc.merged[day] = data.merged
      }
      if (data.actuals !== null) {
        acc.actuals[day] = data.actuals
      }
      if (data.preOrders !== null) {
        acc.preOrders[day] = data.preOrders
      }
      if (data.salesForecast !== null) {
        acc.salesForecast[day] = data.salesForecast
      }
      if (data.userOverrides !== null) {
        acc.userOverrides[day] = data.userOverrides
      }
      if (data.schedules !== null) {
        acc.schedules[day] = data.schedules
      }

      return acc
    },
    {
      predictions: {},
      merged: {},
      actuals: {},
      preOrders: {},
      salesForecast: {},
      userOverrides: {},
      schedules: {}
    }
  )
  return demandData
}

const getUpdatedVia = (
  userValue: number | undefined,
  mailValue: number | undefined
): ForecastUpdatedVia | undefined => {
  // user overrides have priority, if there are user and mail overrides,
  // BE should return last updated data for user
  if (userValue !== undefined) {
    return ForecastUpdatedVia.User
  }
  if (mailValue !== undefined) {
    return ForecastUpdatedVia.Mail
  }
  return undefined
}

/**
 * function to select last updated date for demand data array
 */
export function mergeUpdatedFields(
  acc: AggregatedDemandData['lastUpdates'],
  current: Pick<MaterialDemandEntry, 'userOverrides' | 'schedules' | 'lastUpdates'>
) {
  Object.entries(current.lastUpdates).forEach(([shippingType, currentLastUpdate]) => {
    const type = shippingType as ShippingType
    const existingLastUpdate = acc[type]

    const userValue = current.userOverrides[type]
    const mailValue = current.schedules[type]
    const updatedVia = getUpdatedVia(userValue, mailValue)
    if (!updatedVia) {
      throw new Error(
        'BUG: if there are overrides for a shipping type, there should be an updatedVia'
      )
    }
    const lastUpdateData: ExtendedLastUpdate = {...currentLastUpdate, updatedVia}

    if (!existingLastUpdate) {
      acc[shippingType] = lastUpdateData
      return
    }
    if (moment.utc(existingLastUpdate.updatedOn).isBefore(currentLastUpdate.updatedOn)) {
      acc[shippingType] = lastUpdateData
    }
  })
}

//  Function used for demand table, combines damand data for a range
export const getDemandDataPerMaterial = (
  materialDemandByDay: MaterialDemandByDay
): DemandDataPerMaterial => {
  const demandEntriesByMaterialId = Object.values(materialDemandByDay).reduce<
    Record<string, MaterialDemandEntry[]>
  >((acc, materialDemandById) => {
    Object.entries(materialDemandById).forEach(([materialId, demand]) => {
      if (!acc[materialId]) {
        acc[materialId] = []
      }
      acc[materialId].push(demand)
    })
    return acc
  }, {})

  const aggregatedDemandByMaterialId = Object.fromEntries(
    Object.entries(demandEntriesByMaterialId).map(([materialId, entries]) => {
      return [materialId, getAggregatedDemandData(entries)]
    })
  )

  return aggregatedDemandByMaterialId
}

export const incrementDemandValues = (
  acc: Partial<DemandForecastPerType>,
  values: Partial<DemandForecastPerType>
) => {
  Object.entries(values).forEach(([shippingType, value]) => {
    acc[shippingType] = (acc[shippingType] ?? 0) + value
  })
}

export const getAggregatedDemandData = (materialDemand: MaterialDemandEntry[]) => {
  return materialDemand.reduce<AggregatedDemandData>(
    (acc, entry) => {
      incrementDemandValues(acc.actuals, entry.actuals)
      incrementDemandValues(acc.preOrders, entry.preOrders)
      incrementDemandValues(acc.merged, entry.merged)
      incrementDemandValues(acc.predictions, entry.predictions)
      incrementDemandValues(acc.salesForecast, entry.salesForecast)
      incrementDemandValues(acc.userOverrides, entry.userOverrides)
      incrementDemandValues(acc.schedules, entry.schedules)
      mergeUpdatedFields(acc.lastUpdates, entry)
      return acc
    },
    {
      actuals: {},
      preOrders: {},
      merged: {},
      userOverrides: {},
      schedules: {},
      predictions: {},
      salesForecast: {},
      lastUpdates: {}
    }
  )
}

import {Moment} from 'moment-timezone'
import React, {useEffect} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'
import {
  getTimeValue,
  TIME_FORMAT_FROM_INPUT,
  isMinuteValid,
  isHourValid,
  getTimePartsFromString
} from '../../momentDatePicker/helpers'

import {InputStepper} from './InputStepper'

interface TimeInputStepperProps {
  value: Moment | null
  validator?: (date: Moment) => boolean
  roundingFn?: (date: Moment) => Moment
  onChange: (date: Moment) => void
  timezoneId: string
  label: string
}

const parseValidTime = ({
  time,
  baseDate,
  timezoneId
}: {
  time: string
  baseDate: Moment | null
  timezoneId: string
}) => {
  const {minutes, hours} = getTimePartsFromString(time)
  if (!isMinuteValid(minutes) || !isHourValid(hours) || !baseDate) {
    return undefined
  }
  const parsedDatetime = baseDate
    .clone()
    .tz(timezoneId)
    .minutes(parseInt(minutes, 10))
    .hours(parseInt(hours, 10))

  const isValid = parsedDatetime.isValid()
  return isValid ? parsedDatetime : undefined
}

export const TimeInputStepper: React.FC<TimeInputStepperProps> = ({
  value,
  onChange,
  label,
  timezoneId,
  validator,
  roundingFn
}) => {
  const formattedCurrentValue = value
    ? (roundingFn?.(value) ?? value).format(TIME_FORMAT_FROM_INPUT)
    : ''
  const [inputValue, setInputValue] = React.useState<string>(formattedCurrentValue)

  const onTimeChange = (inputValue: string) => {
    // if value is not set, we cannot validate datetime correctly
    if (!value) {
      return
    }

    const timeValue = getTimeValue(inputValue)
    const validDatetime = parseValidTime({time: timeValue, baseDate: value, timezoneId})

    if (!validDatetime) {
      // leaving the input value as is if the time is invalid
      return setInputValue(timeValue)
    }

    const transformedDatetime = roundingFn?.(validDatetime) ?? validDatetime
    const transformedInputValue = transformedDatetime.format(TIME_FORMAT_FROM_INPUT)
    setInputValue(transformedInputValue)

    // if external validator available, validate first, then call external onChange
    if ((validator && validator(transformedDatetime)) || !validator) {
      return onChange(transformedDatetime)
    }
  }

  const onDecreaseTime = () => {
    if (value) {
      const newValue = value.clone().subtract(1, 'hour')
      setInputValue(newValue.format(TIME_FORMAT_FROM_INPUT))
      onChange(newValue)
    }
  }

  const onIncreaseTime = () => {
    if (value) {
      const newValue = value.clone().add(1, 'hour')
      setInputValue(newValue.format(TIME_FORMAT_FROM_INPUT))
      onChange(newValue)
    }
  }
  const isDecreaseTimeDisabled = value
    ? !(validator?.(value.clone().subtract(1, 'hour')) ?? true)
    : true
  const isIncreaseTimeDisabled = value ? !(validator?.(value.clone().add(1, 'hour')) ?? true) : true

  useEffect(() => {
    if (formattedCurrentValue) {
      setInputValue(formattedCurrentValue)
    }
  }, [formattedCurrentValue])

  return (
    <InputStepper
      value={inputValue}
      placeholder={TIME_FORMAT_FROM_INPUT}
      label={label}
      onChange={onTimeChange}
      onIncrease={onIncreaseTime}
      onDecrease={onDecreaseTime}
      isIncreaseDisabled={isIncreaseTimeDisabled}
      isDecreaseDisabled={isDecreaseTimeDisabled}
      sx={{maxWidth: ({spacing}) => spacing(18)}}
      {...dataTestId('time_input_stepper')}
    />
  )
}

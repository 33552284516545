import {useParams} from 'react-router-dom'

import {UrlParams} from '../../../../routing'
import {usePlannerQuery} from '../usePlannerQuery'

export const usePlantConfigQuery = (plantCode?: string) => {
  const {plantCode: plantCodeParam} = useParams<UrlParams>()
  // at least one of plantCode or plantCodeParam should be defined
  return usePlannerQuery('plantConfig', [plantCode ?? plantCodeParam!], {
    enabled: Boolean(plantCode || plantCodeParam)
  })
}
